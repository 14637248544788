import { useEffect, useState } from 'react'
import { Country, CountryID } from '../lib/domain'

export function useCountrySelect(countries: Country[]): [CountryID | null, (country: CountryID) => void] {
  const [selectedCountry, setSelectedCountry] = useState<CountryID | null>(null)

  useEffect(() => {
    if (selectedCountry === null && countries.length > 0) {
      setSelectedCountry(countries[0]?.id || null)
    }
  }, [countries, selectedCountry])

  return [selectedCountry, setSelectedCountry]
}
