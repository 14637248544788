import React from 'react'
import { formatMonth, parseISODate } from './lib/date'
import { SettingsState } from './lib/reducers/settings'
import { QuotaLimitedAbsenceSummary } from './lib/domain'
import _ from 'lodash'

import styles from './css/Absences.module.css'
import classNames from 'classnames'

type AbsencesProps = {
  locale: SettingsState['locale']
  quotaLimitedAbsenceSummary: QuotaLimitedAbsenceSummary
}

const Absences: React.FC<AbsencesProps> = ({ locale, quotaLimitedAbsenceSummary }) => {
  const shouldShow = quotaLimitedAbsenceSummary.yearSummaries.length > 0

  return (
    <div data-test='usedAbsences' className={classNames(styles.absenceCard, styles.used)}>
      <h2 className={styles.heading}>{locale.texts.absences.unplannedAbsences}</h2>

      {!shouldShow ? (
        <div className={styles.noSickLeaves}>{locale.texts.absences.noSickLeavesRecorded}</div>
      ) : (
        <section className={styles.outer}>
          <div />
          <div>{locale.texts.hours}</div>
          <div>{locale.texts.total}</div>
          <div>{locale.texts.absences.quota}</div>
          {quotaLimitedAbsenceSummary.yearSummaries.map((yearSummary) => {
            const year = parseISODate(yearSummary.checkpointDay).getFullYear()
            const quota = yearSummary.checkpointCountHours
            const months = yearSummary.usedHoursPerMonth
            return (
              <section data-test='usedAbsences-year' key={year} className={styles.year}>
                <div className={styles.yearNumber}>{year}</div>
                {months.map((monthSummary) => {
                  const quotaOverage = monthSummary.cumulativeAbsenceHours - quota
                  const yearMonth = new Date(year, monthSummary.month - 1, 15)
                  return (
                    <section key={`${year}-${monthSummary.month}`} className={styles.month}>
                      <div>{_.capitalize(formatMonth(locale)(yearMonth))}</div>
                      <div>{monthSummary.absenceHours}</div>
                      <div>
                        <span
                          data-alert={quotaOverage > 0 ? true : undefined}
                          data-test='usedAbsences-cumulative'
                          className={quotaOverage > 0 ? styles.red : ''}
                        >
                          {monthSummary.cumulativeAbsenceHours}
                        </span>
                      </div>
                      <div>{quotaOverage > 0 && <span>{quotaOverage}</span>}</div>
                    </section>
                  )
                })}
              </section>
            )
          })}
        </section>
      )}
    </div>
  )
}

export default Absences
