import React from 'react'
import { InvoiceTaskInfo, WorkDay, WorkEntry, EditContext } from '../../lib/domain'
import { EditModeEntry } from '../Entry'
import { UIState } from '../../lib/reducers/ui'
import EditModeWorkingDay from './EditModeWorkingDay'
import { WorkingDayCommonProps } from '../common'

interface EditWorkingDayProps extends WorkingDayCommonProps {
  dayData: WorkDay
  startEndShown: boolean
  hourCodes: InvoiceTaskInfo[]
  registerFocus: (entryIndex: number, focusFn: () => void) => void
  modifyEntry: (editContext: EditContext, updateDiff: Partial<WorkEntry>) => void
  missingWorkingHours: number
  ui: UIState
}

const EditWorkingDay = ({
  dayData,
  startEndShown,
  isWorkDay,
  holiday,
  missingWorkingHours,
  hourCodes,
  locale,
  modifyEntry,
  copyPreviousDay,
  registerFocus,
  ui,
}: EditWorkingDayProps) => {
  const { entries, validation, day, total } = dayData
  const transientEntry = { hours: '', hourCode: '', note: '', startTime: '', endTime: '' }
  const entriesToRender = entries.concat([transientEntry])

  // If no totals data is available from the server,
  // then we temporarily show an estimate to avoid jittery rerenders.
  const hasDayTotal = total !== undefined

  const dayTotal = hasDayTotal
    ? total
    : entries.reduce((p, c) => {
        return p + (parseFloat(c.hours) || 0)
      }, 0)

  const entryElements = entriesToRender.map((entry, index) => {
    return (
      <EditModeEntry
        key={`${day}-${index}`}
        {...{
          entry,
          hourCodes,
          day,
          locale,
          startEndShown,
          modifyEntry: (diff: Partial<WorkEntry>) => modifyEntry({ day, indexInDay: index }, diff),
          validation: entry.validation,
          isLastEntry: index === entriesToRender.length - 1,
          index,
          registerFocus: (focus: () => void) => registerFocus(index, focus),
          ui,
        }}
      />
    )
  })

  return (
    <EditModeWorkingDay
      {...{
        day,
        dayTotal,
        locale,
        validation,
        holiday,
        isWorkDay,
        missingWorkingHours: hasDayTotal ? missingWorkingHours : 0,
        copyPreviousDay,
        modifyEntry,
      }}
    >
      {entryElements}
    </EditModeWorkingDay>
  )
}

export default EditWorkingDay
