import React, { SyntheticEvent, useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import { IS_TOUCH_DEVICE } from './lib/config'
import { fuzzy } from './lib/fuzzy'

import NonOverwritingInput from './NonOverwritingInput'

import styles from './css/CodeInput.module.css'
import { InvoiceTaskInfo, WorkEntry } from './lib/domain'
import { SettingsState } from './lib/reducers/settings'

import HelpIcon from './HelpIcon'

const MAX_CODES_RENDERED_INITIALLY = 20
const CODE_OPTION_HEIGHT_IN_PX = window.matchMedia('(hover: hover) and (pointer: fine)').matches ? 22 : 44

type Code = {
  original: InvoiceTaskInfo
  policyGroup?: string
  highlights: { value: string; isHighlighted: boolean }[]
}

export type CodeOptionProps = {
  code: Code
  onClick: (hourCode: string) => void
  isSelected: boolean
  isHovered: boolean
  setHovered: (hovered: boolean) => void
  isTippy: boolean
}

const CodeOption = ({ code, onClick, isSelected, isHovered, setHovered, isTippy }: CodeOptionProps) => {
  const [mobileTooltipVisible, setMobileTooltipVisible] = useState(false)
  return (
    <div
      data-test='codeInput-hourCodes-codeOption'
      onMouseDown={(e) => {
        e.preventDefault()
        onClick(code.original.name)
      }}
      style={{ height: `${CODE_OPTION_HEIGHT_IN_PX}px` }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classnames(styles.hourCode, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [styles.active!]: isSelected,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [styles.inactive!]: !isSelected,
      })}
    >
      {code.highlights.map(({ value, isHighlighted }, index) => {
        const valueHighLightedIndex = `${value}${isHighlighted}${index}`
        return isHighlighted ? (
          <strong key={valueHighLightedIndex}> {value} </strong>
        ) : (
          <React.Fragment key={valueHighLightedIndex}>{value}</React.Fragment>
        )
      })}

      {code.original.description && (
        <Tippy
          visible={isTippy && (isSelected || isHovered)}
          content={code.original.description}
          placement='right'
          className={classnames(styles.tooltip)}
        >
          <span
            onClick={() => setMobileTooltipVisible(!mobileTooltipVisible)}
            data-test='codeInput-hourCodes-codeOption-helpIcon'
            className={classnames(styles.helpIcon, {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [styles.hourCodeActive!]: isSelected,
            })}
          >
            <HelpIcon />
          </span>
        </Tippy>
      )}
    </div>
  )
}

type CodeInputProps = {
  inputRef: React.MutableRefObject<NonOverwritingInput>
  entry: WorkEntry
  hourCodes: InvoiceTaskInfo[]
  modifyEntry: (entry: Partial<WorkEntry>) => void
  onFocus: (event: SyntheticEvent, ref: React.MutableRefObject<NonOverwritingInput>) => void
  className: string
  locale: SettingsState['locale']
  validationError: string
}

export default function CodeInput(props: CodeInputProps) {
  const [codeSuggestions, setCodeSuggestions] = useState<Code[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [maxCodes, setMaxCodes] = useState(MAX_CODES_RENDERED_INITIALLY)
  const [showCodeSuggestions, setShowCodeSuggestions] = useState(false)

  const inputRef = props.inputRef ?? useRef<NonOverwritingInput>()
  const suggestionsContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    updateCodeSuggestions()
    updateSuggestionsContainerScrollPosition()
  }, [showCodeSuggestions, selectedIndex])

  const updateSuggestionsContainerScrollPosition = () => {
    if (!suggestionsContainerRef.current) return

    // makes sure that the suggestions container div is
    // always scrolled so that the selected item is visible
    suggestionsContainerRef.current.scrollTop = Math.max(
      Math.min(suggestionsContainerRef.current.scrollTop, CODE_OPTION_HEIGHT_IN_PX * selectedIndex),
      Math.max(0, CODE_OPTION_HEIGHT_IN_PX * (selectedIndex + 1) - suggestionsContainerRef.current.clientHeight)
    )
  }

  const updateCodeSuggestions = (hourCode: string | null = null, options = {}) => {
    const { entry, hourCodes } = props
    setCodeSuggestions(fuzzy(hourCodes, hourCode || entry.hourCode, Object.assign(options, { keys: ['name'] })))
  }

  const updateCode = (newHourCode: string) => {
    const { entry, modifyEntry } = props
    if (newHourCode !== entry.hourCode) {
      modifyEntry({ hourCode: newHourCode })
      inputRef.current?.overwrite(newHourCode)
    }
  }

  const updateCodeBasedOnSelectedSuggestion = () => {
    const newHourCode = codeSuggestions[selectedIndex] && codeSuggestions[selectedIndex]?.original
    if (newHourCode) {
      // needed to update NonOverWritingInput
      inputRef.current?.overwrite(newHourCode.name)
      updateCode(newHourCode.name)
    }

    setSelectedIndex(-1)
    setShowCodeSuggestions(false)
  }

  const autoCompeleteHourCode = () => {
    return _.chain(codeSuggestions)
      .map((cs) => cs.original.name)
      .filter((code) => code.toLowerCase().startsWith(props.entry.hourCode.toLowerCase()))
      .reduce(function findOverlap(a, b): string {
        if (b.length === 0) return a
        if (a.startsWith(b)) return b
        return findOverlap(a, b.substring(0, b.length - 1))
      })
      .tap((suggestedHourCode) => {
        // needed to update NonOverWritingInput (manages own state)
        if (!suggestedHourCode) return
        inputRef.current?.overwrite(suggestedHourCode)
        updateCodeSuggestions(suggestedHourCode, {
          caseSensitive: false,
          threshold: 0,
          location: 0,
        })
      })
      .value()
  }

  const onChange = (event: SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value.trim()
    updateCode(value)
    updateCodeSuggestions(value)

    // make the first suggestion to be automatically selected if
    // using on non-touch device and the hour code is not empty
    const newSelectedIndex = value.length > 0 && !IS_TOUCH_DEVICE ? 0 : -1

    setShowCodeSuggestions(true)
    setSelectedIndex(newSelectedIndex)
  }

  const onFocus = (e: SyntheticEvent) => {
    props.onFocus && props.onFocus(e, inputRef)
    setShowCodeSuggestions(true)
    setSelectedIndex(-1)
  }

  const onBlur = () => {
    updateCodeBasedOnSelectedSuggestion()
  }

  const onKeyDown = (event: KeyboardEvent) => {
    const { entry } = props

    if (!showCodeSuggestions) return
    if (event.key === 'Enter' && (entry.hourCode || selectedIndex > -1)) {
      event.stopPropagation()
      updateCodeBasedOnSelectedSuggestion()
    } else if (event.key === 'ArrowDown') {
      if (selectedIndex === maxCodes - 1) {
        setSelectedIndex(selectedIndex + 1)
        setMaxCodes(maxCodes + MAX_CODES_RENDERED_INITIALLY)
      } else {
        setSelectedIndex(selectedIndex + 1)
      }
    } else if (event.key === 'ArrowUp') {
      if (selectedIndex !== -1) setSelectedIndex(selectedIndex - 1)
      event.preventDefault()
    } else if ((event.ctrlKey || event.altKey) && event.key === ' ' && entry.hourCode.length >= 2) {
      autoCompeleteHourCode()
      event.preventDefault()
    }
  }

  const onCodeOptionClicked = (hourCode: string) => {
    updateCode(hourCode)
    setSelectedIndex(-1)
    setShowCodeSuggestions(false)
  }

  const { className, locale, validationError, entry } = props
  const displayedCodeSuggestions = _.take(codeSuggestions, maxCodes)
  const showLoadMore = codeSuggestions.length > maxCodes
  const [hoverIndex, setHoverIndex] = useState(-1)
  const isHovering = hoverIndex !== -1

  return (
    <div data-test='codeInput' className={classnames(className, styles.codeInput)}>
      <div className={styles.inputContainer}>
        <NonOverwritingInput
          data-test='codeInput-input'
          className={classnames(styles.input, {
            [styles.smallText ?? '']: entry.hourCode.length > 8,
            validationError,
          })}
          value={entry.hourCode}
          type='text'
          placeholder={locale.texts.hourCode}
          autoComplete='off'
          spellCheck='false'
          autoCorrect='off'
          autoCapitalize='off'
          onChange={onChange}
          onClick={onFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          ref={inputRef}
        />
        <div
          className={classnames(styles.clearSelection, {
            [styles.hidden ?? '']: entry.hourCode.length === 0,
          })}
          onClick={() => updateCode('')}
        >
          <svg className={styles.svg}>
            <path className={styles.path} d='M 5,5 L 15,15 M 15,5 L 5,15' />
          </svg>
        </div>
      </div>
      {showCodeSuggestions &&
        (displayedCodeSuggestions.length ? (
          <div data-test='codeInput-hourCodes' className={styles.hourCodes} ref={suggestionsContainerRef}>
            {displayedCodeSuggestions.map((code, index) => {
              const hoveringOverOtherThanSelectedIndex = hoverIndex === index || index !== selectedIndex
              const hoveredIndexHasDescription = displayedCodeSuggestions[hoverIndex]?.original?.description
              return (
                <CodeOption
                  key={`code-${code.original.name}`}
                  onClick={onCodeOptionClicked}
                  isSelected={index === selectedIndex}
                  code={code}
                  isHovered={hoverIndex === index}
                  setHovered={(hovered) => setHoverIndex(hovered ? index : -1)}
                  isTippy={isHovering ? hoveringOverOtherThanSelectedIndex || !hoveredIndexHasDescription : true}
                />
              )
            })}
            {showLoadMore && (
              <div
                onMouseDown={(e) => {
                  e.preventDefault()
                  setMaxCodes(maxCodes + MAX_CODES_RENDERED_INITIALLY)
                }}
                style={{ height: `${CODE_OPTION_HEIGHT_IN_PX}px` }}
                className={classnames(styles.hourCode, {
                  [styles.active ?? '']: selectedIndex === displayedCodeSuggestions.length,
                  [styles.inactive ?? '']: selectedIndex !== displayedCodeSuggestions.length,
                })}
              >
                {locale.texts.showMore}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.noResultsPlaceholder}> {locale.texts.codeInputNoResults} </div>
        ))}
    </div>
  )
}
