import React from 'react'
import classnames from 'classnames'

import { autofilledCodes } from './lib/autofills'
import { formatQuarterTime, quarterTimeNow } from './lib/time'

import NonOverwritingInput from './NonOverwritingInput'

import styles from './css/TimeRangeInput.module.css'

const quarterTimeNowAutofillHandler = (e, ref) => {
  if ((e.ctrlKey || e.altKey) && e.key === 'n') {
    ref.current.overwrite(quarterTimeNow())
    ref.current.triggerOnChange()
    e.preventDefault()
  }
}

const TimeRangeInput = ({
  locale,
  entryHasErrors,
  entry,
  className,
  modifyEntry,
  startRef,
  endRef,
  onFocus,
  invalidStartTime,
  invalidEndTime,
}) => {
  // If the selected hourCode has autofill, disable these inputs.
  const disabled = entry.hourCode in autofilledCodes

  return (
    <div className={classnames(className, styles.timeRangeInput)}>
      <NonOverwritingInput
        ref={startRef}
        name='start'
        type='text'
        value={formatQuarterTime(entry.startTime)}
        placeholder={locale.texts.timeRangeStart}
        autoComplete='off'
        onChange={(e) => modifyEntry({ startTime: e.target.value })}
        onFocus={(e) => onFocus && onFocus(e, startRef)}
        onKeyDown={(e) => quarterTimeNowAutofillHandler(e, startRef)}
        disabled={disabled}
        data-test='timeRangeInput'
        className={classnames([styles.time, { validationError: invalidStartTime }])}
      />
      <div className={styles.separator}>-</div>
      <NonOverwritingInput
        ref={endRef}
        name='end'
        type='text'
        value={formatQuarterTime(entry.endTime)}
        placeholder={locale.texts.timeRangeEnd}
        autoComplete='off'
        onChange={(e) => modifyEntry({ endTime: e.target.value })}
        onFocus={(e) => onFocus(e, endRef)}
        onKeyDown={(e) => quarterTimeNowAutofillHandler(e, endRef)}
        disabled={disabled}
        data-test='timeRangeInput'
        className={classnames([styles.time, { validationError: invalidEndTime }])}
      />
    </div>
  )
}

export default TimeRangeInput
