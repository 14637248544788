import React from 'react'
import styles from '../css/NavBar.module.css'
import headerStyles from '../css/Header.module.css'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

export interface NavItem {
  title: string
  uri: string
}

interface NavBarProps {
  username: string
  navItems: NavItem[]
}

export const NavBar: React.FC<NavBarProps> = ({ username, navItems }) => {
  const location = useLocation()

  return (
    <nav className={classNames(styles.navBar)}>
      <div className={styles.navBar__items}>
        <div>
          <a href={window.location.origin}>
            <img className={headerStyles.logo} alt='Hours logo' src='/reaktor_r.svg' />
          </a>
        </div>
        <div className={styles.navBar__navLinks}>
          {navItems.map((item) => (
            <Link
              className={classNames(
                styles.navBar__navLink,
                item.uri === location.pathname ? styles.navBar__navLink_selected : ''
              )}
              key={item.uri}
              to={item.uri}
            >
              {item.title}
            </Link>
          ))}
        </div>
        <div
          className={styles.navBar__user}
          style={{
            backgroundImage: `url(https://cdn.rex.reaktor.cloud/people/400x500/${encodeURIComponent(username)}.jpg)`,
          }}
        />
      </div>
    </nav>
  )
}
