import ReconnectingWebSocket from 'reconnecting-websocket'
import _ from 'lodash'

import { getWebsocketUrl } from './hoursApi'
import { formatISOMonth } from './date'
import { PersonalHoursClientState } from './reducers/personal-hours-client'
import { WorkMonth } from './domain'

export type WebsocketConnection = {
  close: () => void
}

export function openWebsocket(
  initiallySuppliedId: number,
  onServerDayUpdatesReceived: (
    serverDaysToFlush: PersonalHoursClientState['serverDays'],
    monthVersionsToFlush: PersonalHoursClientState['versionConflictState']
  ) => void,
  onServerMonthUpdateReceived: (updatedMonth: Pick<WorkMonth, 'month' | 'locked'>) => void
): WebsocketConnection {
  let lastReceivedEventId: number
  const websocket = new ReconnectingWebSocket(() => getWebsocketUrl(lastReceivedEventId || initiallySuppliedId))
  websocket.onerror = (...args) => console.error('Websocket error', args)
  websocket.onopen = () => console.info('Websocket opened')
  websocket.onclose = (event) => {
    console.info(`Websocket closed: ${event.code} "${event.reason}"`)
    const AUTHENTICATION_FAILED = 4401
    const TOO_MANY_EVENTS_CODE = 4999
    if (event && (event.code === AUTHENTICATION_FAILED || event.code === TOO_MANY_EVENTS_CODE)) {
      window.location.reload()
    }
  }

  const MESSAGE_DEBOUNCE_TIMEOUT = 50
  const PING_INTERVAL = 20 * 1000
  const PONG_WAIT_TIMEOUT = 2 * 1000

  // this is debounced because in some cases there can be A LOT of
  // messages in a very short period of time, for example when
  // computer is turned off after work day and back on the next day etc
  let serverDaysToFlush = {}
  let monthVersionsToFlush = {}
  const updateServerDays = _.debounce(() => {
    onServerDayUpdatesReceived && onServerDayUpdatesReceived(serverDaysToFlush, monthVersionsToFlush)
    serverDaysToFlush = {}
    monthVersionsToFlush = {}
  }, MESSAGE_DEBOUNCE_TIMEOUT)

  let didGetPong = false
  setInterval(() => {
    if (websocket.readyState !== WebSocket.OPEN) {
      return
    }

    // no reason to attempt ping/reconnect if network has no connection.
    // note: navigator.onLine can return false positives,
    // but it shouldnt return false negatives
    if (navigator.onLine === false) {
      return
    }

    didGetPong = false
    websocket.send('ping')
    setTimeout(() => {
      if (!didGetPong) {
        websocket.reconnect(4998) // 4998 = custom code, server doesnt probably need to react to this though
      }
    }, PONG_WAIT_TIMEOUT)
  }, PING_INTERVAL)

  websocket.onmessage = (event) => {
    const data = event.data && JSON.parse(event.data)
    if (!data) {
      console.warn("Websocket message didn't have data")
      return
    }

    lastReceivedEventId = data.id
    switch (data.type) {
      case 'workdays': {
        const { workdays, monthVersion } = data
        if (workdays.length > 0) {
          serverDaysToFlush = { ...serverDaysToFlush, ..._.keyBy(workdays, 'day') }
          monthVersionsToFlush = { ...monthVersionsToFlush, [formatISOMonth(workdays[0].day)]: monthVersion }
          updateServerDays()
        }

        break
      }
      case 'workmonth': {
        const { month, locked } = data
        if (_.isString(month) && month.length > 0 && _.isBoolean(locked)) {
          onServerMonthUpdateReceived({ month, locked })
        } else {
          console.warn(`Websocket work month message had invalid data '${data}'`)
        }
        break
      }
      case 'pong': {
        didGetPong = true
        break
      }

      default: {
        console.warn(`Websocket message had unrecognized data type '${data.type}'`)
      }
    }
  }

  return {
    close() {
      websocket.close()
    },
  }
}
