import React, { useEffect, useMemo, useState } from 'react'
import styles from '../css/LeaveEditor.module.css'
import adminClientStyles from '../css/AdminClient.module.css'
import { EmployeeName, SpecialLeaveCheckpoint } from '../lib/domain'
import classnames from 'classnames'
import { Locale, SettingsState } from '../lib/reducers/settings'
import { formatDayMonthYear, isAfter, isBefore, parseISODate } from '../lib/date'
import { fetchUnpaidLeaveCheckpointOnDay, updateSpecialLeaveCheckpoint } from '../lib/hoursApi'

type UnpaidLeaveEditorProps = {
  employee?: EmployeeName
  startDate?: string
  endDate?: string
  colSpan: number
  settings: SettingsState
  closeEditor: (updated: boolean) => void
}

const formatSeason = (locale: Locale, seasonStart: Date, contractEnd: Date | undefined) => {
  const formatDate = formatDayMonthYear(locale)
  const dayBeforeNextSeasonStart = new Date(seasonStart.getFullYear() + 1, 2, 31)
  const seasonEnd =
    contractEnd && isAfter(dayBeforeNextSeasonStart, contractEnd) ? contractEnd : dayBeforeNextSeasonStart
  return `${formatDate(seasonStart)}–${formatDate(seasonEnd)}`
}

const vacationSeasonStart = (year: number, contractStart: Date | undefined): Date => {
  const defaultDate = new Date(year, 3, 1)
  if (contractStart && isAfter(contractStart, defaultDate)) {
    return contractStart
  }
  return defaultDate
}

export const UnpaidLeaveEditor: React.FC<UnpaidLeaveEditorProps> = ({
  employee,
  startDate,
  endDate,
  colSpan,
  settings: { locale },
  closeEditor,
}) => {
  const [currentSeasonCheckpoint, setCurrentSeasonCheckpoint] = useState<SpecialLeaveCheckpoint | undefined>(undefined)
  const [nextSeasonCheckpoint, setNextSeasonCheckpoint] = useState<SpecialLeaveCheckpoint | undefined>(undefined)
  const [newCurrentSeasonCheckpointCount, setNewCurrentSeasonCheckpointCount] = useState<number | undefined>(undefined)
  const [newNextSeasonCheckpointCount, setNewNextSeasonCheckpointCount] = useState<number | undefined>(undefined)
  const [saveError, setSaveError] = useState<string | undefined>(undefined)

  const contractStart = useMemo(() => (startDate ? parseISODate(startDate) : undefined), [startDate])
  const contractEnd = useMemo(() => (endDate ? parseISODate(endDate) : undefined), [endDate])

  const thisYear = new Date().getFullYear()
  const thisSeasonStart = vacationSeasonStart(thisYear, contractStart)
  const nextSeasonStart = vacationSeasonStart(thisYear + 1, contractStart)
  const isPresentNextSeason = !contractEnd || isBefore(nextSeasonStart, contractEnd)

  useEffect(() => {
    if (!employee) return

    const username = employee.username
    const fetchCheckpoints = async () => {
      try {
        setCurrentSeasonCheckpoint(await fetchUnpaidLeaveCheckpointOnDay(username, new Date()))
        if (isPresentNextSeason) {
          setNextSeasonCheckpoint(
            await fetchUnpaidLeaveCheckpointOnDay(username, vacationSeasonStart(thisYear + 1, contractStart))
          )
        }
      } catch (e) {
        console.error(`Could not load checkpoints for employee ${username}`, e)
      }
    }

    void fetchCheckpoints()
  }, [employee, thisYear, contractStart, isPresentNextSeason])

  if (!employee) {
    return null
  }

  const currentSeasonHasChanges =
    newCurrentSeasonCheckpointCount !== undefined && newCurrentSeasonCheckpointCount !== currentSeasonCheckpoint?.count
  const nextSeasonHasChanges =
    newNextSeasonCheckpointCount !== undefined && newNextSeasonCheckpointCount !== nextSeasonCheckpoint?.count
  const hasChanges = currentSeasonHasChanges || nextSeasonHasChanges

  const saveChanges = async () => {
    try {
      if (newCurrentSeasonCheckpointCount && currentSeasonCheckpoint) {
        await updateSpecialLeaveCheckpoint(
          employee.username,
          {
            ...currentSeasonCheckpoint,
            count: newCurrentSeasonCheckpointCount,
          },
          'unpaid'
        )
      }
      if (newNextSeasonCheckpointCount && nextSeasonCheckpoint) {
        await updateSpecialLeaveCheckpoint(
          employee.username,
          {
            ...nextSeasonCheckpoint,
            count: newNextSeasonCheckpointCount,
          },
          'unpaid'
        )
      }

      closeEditor(hasChanges)
    } catch (e) {
      setSaveError(`${locale.texts.admin.leaveEditor.saveError}`)
    }
  }

  return (
    <>
      <td className={styles.leaveEditor} colSpan={2} />
      <td className={styles.leaveEditor} colSpan={colSpan - 4}>
        <div>
          <span className={styles.editorTitle}>{locale.texts.admin.unpaidLeaves.availableUnpaidDays}</span>
          {currentSeasonCheckpoint ? (
            <>
              <label htmlFor={`${employee?.username}-current-season`}>{`${
                locale.texts.admin.unpaidLeaves.thisSeason
              } (${formatSeason(locale, thisSeasonStart, contractEnd)})`}</label>
              <input
                id={`${employee?.username}-current-season`}
                type='number'
                defaultValue={currentSeasonCheckpoint.count}
                onChange={(e) => {
                  const newCount = parseInt(e.target.value, 10)
                  setNewCurrentSeasonCheckpointCount(newCount)
                }}
              />
            </>
          ) : null}
          {nextSeasonCheckpoint ? (
            <>
              <label htmlFor={`${employee?.username}-next-season`}>{`${
                locale.texts.admin.unpaidLeaves.nextSeason
              } (${formatSeason(locale, nextSeasonStart, contractEnd)})`}</label>
              <input
                id={`${employee?.username}-next-season`}
                type='number'
                defaultValue={nextSeasonCheckpoint.count}
                onChange={(e) => {
                  const newCount = parseInt(e.target.value, 10)
                  setNewNextSeasonCheckpointCount(newCount)
                }}
              />
            </>
          ) : null}
        </div>
      </td>
      <td className={classnames(styles.leaveEditor, styles.controlsColumn)} colSpan={2}>
        {saveError ? (
          <span className={classnames(adminClientStyles.errorText, adminClientStyles.saveError)}>{saveError}</span>
        ) : null}
        <span className={styles.controls}>
          <button
            className={classnames(adminClientStyles.button, adminClientStyles.button_secondary)}
            onClick={() => closeEditor(false)}
          >
            {locale.texts.admin.leaveEditor.cancel}
          </button>
          <button
            className={classnames(adminClientStyles.button, adminClientStyles.button_primary)}
            disabled={!hasChanges}
            onClick={saveChanges}
          >
            {locale.texts.admin.leaveEditor.save}
          </button>
        </span>
      </td>
    </>
  )
}
