// todo remove
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { startOfWorkWeek, today, formatISO, formatISOMonth } from './date'
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  getDate,
  getISOWeek,
  isEqual,
  startOfMonth,
} from 'date-fns'
import _ from 'lodash'
import { CountryID, ExpectedDailyHours, HoursContractSeason, WorkDay } from './domain'

export const createDefaultDay = (day: string) => ({ day, entries: [], total: undefined })

export const partialWeekStart = /partTime_\d\d_/

export const isPartialWeek = (contractType: HoursContractSeason['type']) => partialWeekStart.test(contractType)

export const calculateDiffForDay = (serverDay: WorkDay, userExpectedHoursForDay?: ExpectedDailyHours) => {
  const actualHoursForDay = serverDay?.total ?? 0
  const expectedHoursForDay = (userExpectedHoursForDay && userExpectedHoursForDay.hours) || 0

  return actualHoursForDay - expectedHoursForDay
}

const padArrayStart = <T>(arr: T[], length: number) => [...new Array(Math.max(0, length - arr.length)), ...arr]
const padArrayEnd = <T>(arr: T[], length: number) => [...arr, ...new Array(Math.max(0, length - arr.length))]

type DisplayWeek = {
  days: string[]
  isoWeek: string
}
type DisplayMonth = {
  month: string
  weeks: DisplayWeek[]
}

export const generateDisplayedMonths = (startDate: string, endDate: string, country?: CountryID): DisplayMonth[] => {
  const weekDayOffsetFromISO = Math.abs(
    differenceInDays(startOfWorkWeek(today(), country), startOfWorkWeek(today(), 'FI'))
  )

  const firstMonth = startOfMonth(startDate)
  const lastMonth = endOfMonth(endDate)

  const totalMonths = differenceInCalendarMonths(lastMonth, firstMonth) + 1
  return _.range(0, totalMonths).map((monthOffset) => {
    const month = addMonths(firstMonth, monthOffset)
    const daysInMonth = eachDayOfInterval({
      start: isEqual(month, firstMonth) ? startDate : startOfMonth(month),
      end: endOfMonth(month),
    })

    const weeksMap: Record<number, string[]> = {}
    for (const day of daysInMonth) {
      const isoWeek = getISOWeek(addDays(day, weekDayOffsetFromISO))
      weeksMap[isoWeek] = weeksMap[isoWeek] || []
      weeksMap[isoWeek]?.push(formatISO(day))
    }

    const weeks = _.orderBy(_.toPairs(weeksMap), ([, days]) => days[0]).map(([isoWeek, days]) => {
      if (!days[0]) {
        throw new Error(`No days for week ${isoWeek}`)
      }

      const paddedDays = getDate(days[0]) === 1 ? padArrayStart(days, 7) : padArrayEnd(days, 7)
      return { days: paddedDays, isoWeek }
    })

    return {
      month: formatISOMonth(month),
      weeks,
    }
  })
}

const injectTotalHoursAndFlexToWeeks =
  (serverDays: Record<string, WorkDay>, userExpectedHours: Record<string, ExpectedDailyHours>) =>
  (week: DisplayWeek) => ({
    ...week,
    totalHours: _.sum(week.days.map((isoDay) => (serverDays[isoDay] && serverDays[isoDay]!.total) || 0)),
    flex: _.sum(
      week.days.map((isoDay) =>
        isoDay > today() ? 0 : calculateDiffForDay(serverDays[isoDay]!, userExpectedHours[isoDay])
      )
    ),
  })

export const injectTotalHoursAndFlexToMonths = (
  displayMonths: DisplayMonth[],
  serverDays: Record<string, WorkDay>,
  userExpectedHours: Record<string, ExpectedDailyHours>
) => {
  return displayMonths.map((month) => {
    const weeksWithTotalHoursAndFlex = month.weeks.map(injectTotalHoursAndFlexToWeeks(serverDays, userExpectedHours))
    return {
      ...month,
      weeks: weeksWithTotalHoursAndFlex,
      totalHours: _.sumBy(weeksWithTotalHoursAndFlex, ({ totalHours }) => totalHours),
      flex: _.sumBy(weeksWithTotalHoursAndFlex, ({ flex }) => flex),
    }
  })
}

const vacationCodes = new Set(['poissa-vuosiloma', 'poissa-palkaton', 'absent-vacation', 'poissa-lomarahavapaa'])
export const isVacationCode = (code: string) => vacationCodes.has(code)

const flexitimeCodes = new Set(['absent-flexitime', 'poissa-liukumavapaa'])
export const isFlexitimeCode = (code: string) => flexitimeCodes.has(code)

const floatingHolidayCodes = new Set(['poissa-liukuva-pyhäpäivä', 'absent-float-holiday'])
export const isFloatingHolidayCode = (code: string) => floatingHolidayCodes.has(code)
