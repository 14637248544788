import React from 'react'
import { formatDayAndMonth, formatDayMonthYear, formatDayOfWeek } from '../lib/date'
import { Locale } from '../lib/reducers/settings'

interface WorkingDateColumnProps {
  day: string
  locale: Locale
  isToday: boolean
  isCurrentYear: boolean
  holiday?: string
}

const WorkingDateColumn = ({ day, isToday, locale, isCurrentYear, holiday }: WorkingDateColumnProps) => {
  return (
    <>
      {isToday && <div className='workingDay__today'>{locale.texts.today}</div>}
      <div className='workingDay__date'>
        {formatDayOfWeek(locale)(day)}
        &nbsp;
        {isCurrentYear ? formatDayAndMonth(locale)(day) : formatDayMonthYear(locale)(day)}
        {holiday && <>&nbsp;{holiday}</>}
      </div>
    </>
  )
}

export default WorkingDateColumn
