import React from 'react'
import classnames from 'classnames'
import { WorkDayValidation, WorkEntry, EditContext } from '../../lib/domain'
import { currentYear, today, yearOfISODay } from '../../lib/date'
import WorkingDateColumn from '../WorkingDateColumn'
import { ValidationWorkingDay } from '../ValidationMessages'
import { WorkingDayCommonProps } from '../common'
import EditModeWorkingDayAncillaries from './EditModeWorkingDayAncillaries'

interface EditModeWorkingDayProps extends WorkingDayCommonProps {
  day: string
  dayTotal: number
  children: JSX.Element[]
  validation?: WorkDayValidation
  modifyEntry: (editContext: EditContext, updateDiff: Partial<WorkEntry>) => void
  missingWorkingHours: number
}

const EditModeWorkingDay = ({
  day,
  dayTotal,
  locale,
  children,
  validation,
  holiday,
  copyPreviousDay,
  isWorkDay,
  missingWorkingHours,
  modifyEntry,
}: EditModeWorkingDayProps) => {
  const isToday = day === today()
  const isCurrentYear = yearOfISODay(day) === currentYear()

  return (
    <div
      className={classnames('workingDay', `workingDay-${day}`, 'workingDay--editing', {
        'workingDay--today': isToday,
        'workingDay--nonWorkingDay': !isToday && !isWorkDay,
        'workingDay--nonWorkingDay--today': isToday && !isWorkDay,
      })}
      data-test={isToday ? 'today-edit' : `day-edit-${day}`}
    >
      <WorkingDateColumn day={day} isToday={isToday} isCurrentYear={isCurrentYear} locale={locale} holiday={holiday} />
      <div className='workingDay__entries' onClick={(e) => e.stopPropagation()}>
        {children}
        <ValidationWorkingDay
          {...{
            day,
            locale,
            modifyEntry,
            validation,
          }}
        >
          {children}
        </ValidationWorkingDay>
      </div>
      <EditModeWorkingDayAncillaries
        {...{
          copyPreviousDay,
          dayTotal,
          missingWorkingHours,
          locale,
        }}
      >
        {children}
      </EditModeWorkingDayAncillaries>
    </div>
  )
}

export default EditModeWorkingDay
