import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../lib/hooks'
import { useDispatch } from 'react-redux'
import { ReportingResponse, UsernameReportWorkEntry } from '../lib/domain'
import { fetchTasksBySearch } from '../lib/hoursApi'

import SortableTable, { SortableTableHeader, sortItemsByPath, TableHeading, useSortingByPath } from './SortableTable'
import { RootState } from '../lib/store'
import { SettingsState } from '../lib/reducers/settings'
import _ from 'lodash'
import classnames from 'classnames'

import styles from '../css/ProjectAndTaskSearchTable.module.css'
import adminClientStyles from '../css/AdminClient.module.css'
import { formatISOMonth, parseISODate, subMonths } from '../lib/date'
import { isAfter, isValid } from 'date-fns'
import { EMPTY_RESPONSE } from '../lib/reducers/admin'

interface ReportWorkEntriesSummaryProps {
  reportWorkEntriesResponse: ReportingResponse<UsernameReportWorkEntry>
  locale: SettingsState['locale']
}
interface ReportWorkEntriesTableProps extends ReportWorkEntriesSummaryProps {
  search: string
}

interface ProjectAndTaskSearchTableProps {
  settings: RootState['settings']
  title: string
}

const ResultSummary: React.FC<ReportWorkEntriesSummaryProps> = ({ locale, reportWorkEntriesResponse }) => {
  const text = locale.texts.admin.projectAndTaskSearch
  const isLimited = reportWorkEntriesResponse.resultsLimit === reportWorkEntriesResponse.results.length

  return (
    <div className={styles.resultSummary} data-test='resultSummary'>
      {isLimited && <div>{text.responseIsLimited}</div>}
      <div>
        {locale.texts.admin.projectAndTaskSearch.hoursSummed + ' '}
        {_.sum(reportWorkEntriesResponse.results.map((e) => parseInt(e.hours, 10)).filter((e) => !_.isNaN(e)))}h
      </div>
      <div>
        {locale.texts.admin.projectAndTaskSearch.entries + ' '} {reportWorkEntriesResponse.results.length}
      </div>
      <div>
        {locale.texts.admin.projectAndTaskSearch.uniqueUsers + ' '}
        {_.uniqBy(reportWorkEntriesResponse.results, 'username').length}
      </div>
    </div>
  )
}

const Highlight: React.FC<{ highlight: string; text: string }> = ({ highlight, text }) => {
  const start = text.toLowerCase().indexOf(highlight.toLowerCase())
  const end = highlight.length
  const startToEnd = start + end
  if (start >= 0)
    return (
      <span className={styles.highlightContainer}>
        {text.substring(0, start)}
        <span className={styles.highlight}>{text.substring(start, startToEnd)}</span>
        {text.substring(startToEnd)}
      </span>
    )
  else return <span className={styles.highlightContainer}>{text}</span>
}

const ReportWorkEntriesTable: React.FC<ReportWorkEntriesTableProps> = ({
  locale,
  reportWorkEntriesResponse,
  search,
}) => {
  const headings: TableHeading[] = [
    { title: locale.texts.admin.projectAndTaskSearch.username, sortPath: 'username' },
    { title: locale.texts.admin.projectAndTaskSearch.day, sortPath: 'day' },
    { title: locale.texts.admin.projectAndTaskSearch.hours, sortPath: 'hours' },
    { title: locale.texts.admin.projectAndTaskSearch.hourCode, sortPath: 'hourCode' },
    { title: locale.texts.admin.projectAndTaskSearch.note, sortPath: 'note' },
  ]
  const [sorting, toggleSortByPath] = useSortingByPath()
  const { results } = reportWorkEntriesResponse
  const sortedEntries = sortItemsByPath(sorting, results)

  return (
    <div data-test='reportWorkEntriesTable'>
      <SortableTable className={styles.searchResultsTable}>
        <SortableTableHeader headings={headings} sortingState={sorting} toggleSortByPath={toggleSortByPath} />
        <tbody>
          {sortedEntries.map(({ username, day, hours, hourCode, note }, index) => (
            <tr key={`r-${index}`}>
              <td>{username}</td>
              <td>{day}</td>
              <td>{hours}</td>
              <td>
                <Highlight highlight={search} text={hourCode} />
              </td>
              <td>{note}</td>
            </tr>
          ))}
        </tbody>
      </SortableTable>
    </div>
  )
}

const ProjectAndTaskSearchTable: React.FC<ProjectAndTaskSearchTableProps> = ({ settings: { locale }, title }) => {
  const dispatch = useDispatch()

  const [startMonth, setStartMonth] = useState(formatISOMonth(subMonths(new Date(), 2)))
  const [endMonth, setEndMonth] = useState(formatISOMonth(new Date()))
  const [searchString, setSearchString] = useState('travel')
  const [searched, setSearched] = useState('')
  const [error, setError] = useState('')

  const validateSearch = () => {
    const tryParse = (d: string) => {
      try {
        const parsed = parseISODate(d + '-01')
        return isValid(parsed) && parsed
      } catch (e) {
        return false
      }
    }
    const start = tryParse(startMonth)
    const end = tryParse(endMonth)
    const validation = []
    if (!searchString || searchString === '') validation.push(locale.texts.admin.projectAndTaskSearch.hourCode)
    if (!start) validation.push(locale.texts.admin.projectAndTaskSearch.startTime)
    if (!end) validation.push(locale.texts.admin.projectAndTaskSearch.endTime)
    if (start && end && isAfter(start, end)) {
      validation.push(locale.texts.admin.projectAndTaskSearch.startTime)
      validation.push(locale.texts.admin.projectAndTaskSearch.endTime)
    }

    return _.uniq(validation).join(', ')
  }

  const search = () => {
    const errors = validateSearch()
    if (errors.length > 0) {
      dispatch({ type: 'UPDATE_REPORT_WORKENTRIES', reportWorkEntriesResponse: EMPTY_RESPONSE })
      setError(locale.texts.admin.projectAndTaskSearch.errorDuringSearch(errors))
    } else {
      fetchTasksBySearch({ search: searchString, startMonth, endMonth })
        .then((reportWorkEntriesResponse) => dispatch({ type: 'UPDATE_REPORT_WORKENTRIES', reportWorkEntriesResponse }))
        // eslint-disable-next-line promise/always-return
        .then(() => {
          setSearched(searchString)
          setError('')
        })
        .catch((e) => {
          setError(locale.texts.admin.projectAndTaskSearch.errorDuringSearch(validateSearch()))
          console.error(e)
        })
    }
  }

  useEffect(() => search(), [])

  const reportWorkEntriesResponse = useAppSelector((state) => state.data.adminClient.reportWorkEntriesResponse)
  const onKeyPress = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      search()
    }
  }

  return (
    <div className={classnames(styles.table, adminClientStyles.container)}>
      <h1>{title}</h1>
      <div className={styles.controls}>
        <p>{locale.texts.admin.projectAndTaskSearch.hourCode}</p>
        <input
          type='text'
          className={styles.searchTermInput}
          onKeyPress={onKeyPress}
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />
        <p>{locale.texts.admin.projectAndTaskSearch.startTime}</p>
        <input
          type='text'
          className={styles.searchTermInput}
          placeholder='yyyy-mm'
          onKeyPress={onKeyPress}
          onChange={(e) => setStartMonth(e.target.value)}
          value={startMonth}
        />
        <p>{locale.texts.admin.projectAndTaskSearch.endTime}</p>
        <input
          type='text'
          className={styles.searchTermInput}
          placeholder='yyyy-mm'
          onKeyPress={onKeyPress}
          onChange={(e) => setEndMonth(e.target.value)}
          value={endMonth}
        />
      </div>
      {error !== '' && <h2 className={styles.error}>{error}</h2>}
      <ResultSummary reportWorkEntriesResponse={reportWorkEntriesResponse} locale={locale} />
      <ReportWorkEntriesTable reportWorkEntriesResponse={reportWorkEntriesResponse} locale={locale} search={searched} />
    </div>
  )
}

export default ProjectAndTaskSearchTable
