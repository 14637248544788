import React from 'react'
import classnames from 'classnames/bind'
import styles from '../../css/Weekend.module.css'
import sharedStyles from '../../css/DayList.module.css'
import { Locale } from '../../lib/reducers/settings'
import { formatDayAndMonth, otherWeekendDay } from '../../lib/date'
import { CountryID } from '../../lib/domain'
import PlusIcon from '../../PlusIcon'

const css = classnames.bind(styles)

interface WeekendProps {
  day: string
  locked: boolean
  onClick: () => void
  locale: Locale
  country?: CountryID
}

const Weekend = ({ day, locked, onClick, locale, country }: WeekendProps) => {
  const formattedFirst = formatDayAndMonth(locale)(day)
  const formattedSecond = formatDayAndMonth(locale)(otherWeekendDay(day, country))

  return (
    <div className={css(sharedStyles.item)}>
      <div
        className={css('weekend', {
          locked,
        })}
        onClick={onClick}
      >
        {locale.texts.weekendTitle}
        {` ${formattedFirst} — ${formattedSecond}`}
        {!locked && <PlusIcon />}
      </div>
    </div>
  )
}

export default Weekend
