import React from 'react'
import { connect } from 'react-redux'
import { SettingsState } from '../lib/reducers/settings'
import { LoggedInUser } from '../lib/reducers/user'
import { RootState } from '../lib/store'
import AccessForbiddenView from './AccessForbiddenView'
import ProjectAndTaskSearchTable from './ProjectAndTaskSearchTable'

export const userHasRights = (loggedInUser: LoggedInUser) => loggedInUser.isAdmin || loggedInUser.isCountryManager

interface ProjectAndTaskSearchViewProps {
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}

const ProjectAndTaskSearchView: React.FC<ProjectAndTaskSearchViewProps> = ({ loggedInUser, settings }) => {
  const { locale } = settings
  if (!userHasRights(loggedInUser)) {
    return <AccessForbiddenView locale={locale} />
  } else return <ProjectAndTaskSearchTable settings={settings} title={locale.texts.admin.projectAndTaskSearch.title} />
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(ProjectAndTaskSearchView)
