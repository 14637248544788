export const formatRange = (start, end) => (start || end ? formatTime(start) + '-' + formatTime(end) : '')

export const formatTime = (time) => (time ? time : '')
export const formatQuarterTime = (time) => {
  if (!time || !isValidTime(time)) {
    return time
  }

  const quarterTime = parseQuarterTime(time)
  if (quarterTime === null) {
    return time
  }

  const quarterTimeStr = quarterTime.toString()
  if (quarterTimeStr.length === 4 || quarterTimeStr.length === 3) {
    const minutesStartIndex = quarterTimeStr.length - 2
    return `${quarterTimeStr.substring(0, minutesStartIndex)}:${quarterTimeStr.substring(minutesStartIndex)}`
  } else if (quarterTimeStr === '0') {
    return '0:00'
  }

  return time.startsWith('0') ? `0:${quarterTimeStr}` : quarterTimeStr
}

export const quarterTimeNow = () => formatQuarterTime(`${new Date().getHours()}:${new Date().getMinutes()}`)

export const hourDifference = (start, end) => {
  const startTime = parseQuarterTime(start)
  const endTime = parseQuarterTime(end)

  if (startTime !== null && endTime !== null && (startTime <= endTime || endTime === 0)) {
    const h = decimalTime(endTime) - decimalTime(startTime)
    return h < 0 ? h + 24 : h
  } else {
    return NaN
  }
}

const parseQuarterTime = (text) => roundToQuarter(parseTimeBasedOnLength(text))

const roundToQuarter = (time) => {
  if (time == null) return null
  const quarterMinutes = Math.floor((minutes(time) + 7) / 15) * 15
  return withMinutes(time, quarterMinutes)
}

const decimalTime = (time) => hours(time) + minutes(time) / 60

const hours = (time) => Math.floor(time / 100) % 24
const minutes = (time) => time % 100

const withMinutes = (time, mins) => addTimes(toTime(hours(time), 0), minutesToTime(mins))

const addTimes = (timeA, timeB) => {
  const minuteSumTime = minutesToTime(minutes(timeA) + minutes(timeB))
  const hoursSum = (hours(timeA) + hours(timeB) + hours(minuteSumTime)) % 24
  return toTime(hoursSum, minutes(minuteSumTime))
}

const toTime = (hs, mins) => hs * 100 + mins

const toValidTime = (time) => {
  const minutesAsTime = minutesToTime(minutes(time))
  const hs = (hours(time) + hours(minutesAsTime)) % 24
  return toTime(hs, minutes(minutesAsTime))
}

const minutesToTime = (mins) => {
  const h = Math.floor(mins / 60)
  const m = mins % 60
  return toTime(h, m)
}

const parseTimeBasedOnLength = (text) => {
  if (!text) return null
  switch (text.length) {
    case 0:
      return null
    case 1:
    case 2:
      return parseTime(text + '00')
    case 3:
      return parseTime(text)
    case 4:
    default:
      return parseTime(text.replace(/[:.]/, ''))
  }
}

const parseTime = (text) => {
  const digits = /\d+/
  return digits.test(text) ? toValidTime(parseInt(text, 10)) : null
}

// 9:30 is valid, 09:30 is valid, 09.30 is valid, 0930 is valid etc
// 93 is not valid, 9:3 is not valid, 09,30 is not valid, 32:10 is not valid, 12:61 is not valid etc
const isValidTime = (str) => /^(2[0-3]|[01]?[0-9])([:.]?([0-5][0-9]))?$/.test(str)
