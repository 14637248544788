import React from 'react'

import styles from './css/Spinner.module.css'

const Spinner = () => (
  <div className={styles.spinner}>
    <img width='100px' src='/reaktor_loader.gif' />
  </div>
)

export default Spinner
