import Tippy from '@tippyjs/react'
import React, { ReactNode, forwardRef } from 'react'
import styles from './HolidayTable.module.css'
import { SettingsState } from '../lib/reducers/settings'
import { CountryID, UnpaidVacationSummary } from '../lib/domain'
import { formatDayMonthYear } from '../lib/date'
import { getWorkdayLength } from '../lib/config'

interface HolidayTableProps {
  header: ReactNode[]
  footer?: JSX.Element | null
  'data-test'?: string
}

// Skeleton for different holiday tables
export const HTable: React.FC<HolidayTableProps> = ({ header, children, footer, 'data-test': dataTest }) => {
  return (
    <section data-test={dataTest} className={styles.holidayTable}>
      <table>
        <tbody>
          <tr className={styles.tableHeaders}>
            {header.map((node) => (
              <th key={`htable-header-${node}`}>{node}</th>
            ))}
          </tr>
          {children}
        </tbody>
      </table>
      {footer && <span>{footer}</span>}
    </section>
  )
}

// The element needs to be refable for Tippy to function
const RefableInfoIcon = forwardRef<HTMLImageElement, { size?: HTMLImageElement['width'] }>(({ size }, ref) => (
  <img src='/info.svg' ref={ref} className={styles.infoIcon} width={size} height={size} />
))

// Needed due to forwardRef
RefableInfoIcon.displayName = 'InfoIcon'

interface InfoIconProps {
  tooltip?: ReactNode
  size?: HTMLImageElement['width']
}

export const InfoIcon = ({ tooltip, size }: InfoIconProps) => {
  const icon = <RefableInfoIcon size={size} />

  if (!tooltip) {
    return icon
  }

  return (
    <Tippy
      interactive
      placement='bottom'
      content={tooltip}
      className={styles.infoButtonTooltip}
      appendTo={document.body}
    >
      {icon}
    </Tippy>
  )
}

export function inDaysAndHours(vacationHours: number, country: CountryID) {
  const workDayLength = getWorkdayLength(country)
  const days = Math.floor(vacationHours / workDayLength)
  const hours = vacationHours % workDayLength
  return {
    days,
    hours,
  }
}

export function formatDaysAndHours(locale: SettingsState['locale'], vacationHours: number, country?: CountryID) {
  const absoluteHours = Math.abs(vacationHours)
  let formattedInDaysAndHours: string

  if (!country) {
    formattedInDaysAndHours = locale.texts.vacations.unpaidVacationFormatHours(
      new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(absoluteHours)
    )
  } else {
    const { days, hours } = inDaysAndHours(absoluteHours, country)
    formattedInDaysAndHours =
      hours === 0
        ? `${days}`
        : locale.texts.vacations.unpaidVacationFormatDaysAndHours(
            days,
            new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(hours)
          )
  }

  return vacationHours < 0 ? `-${formattedInDaysAndHours}` : formattedInDaysAndHours
}

export const UnpaidVacationTable: React.FC<{
  unpaidVacationSummary: UnpaidVacationSummary
  locale: SettingsState['locale']
  country: CountryID
}> = ({ unpaidVacationSummary, locale, country }) => {
  return (
    <HTable
      data-test='unpaid-vacation-table'
      header={[locale.texts.vacations.unpaidVacations, locale.texts.vacations.days]}
    >
      <tr>
        <td>
          <span>{`${locale.texts.vacations.available} ${formatDayMonthYear(locale)(
            unpaidVacationSummary.checkpointDay
          )}`}</span>
        </td>
        <td>{formatDaysAndHours(locale, unpaidVacationSummary.checkpointCountHours, country)}</td>
      </tr>
      <tr>
        <td>{locale.texts.vacations.used}</td>
        <td>{formatDaysAndHours(locale, unpaidVacationSummary.usedHours, country)}</td>
      </tr>
      <tr>
        <td>{locale.texts.vacations.planned}</td>
        <td>{formatDaysAndHours(locale, unpaidVacationSummary.plannedHours, country)}</td>
      </tr>
      <tr>
        <td>{locale.texts.vacations.remaining}</td>
        <td>{formatDaysAndHours(locale, unpaidVacationSummary.total, country)}</td>
      </tr>
    </HTable>
  )
}
