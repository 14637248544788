import React from 'react'
import { LoggedInUser } from '../lib/reducers/user'
import { RootState } from '../lib/store'
import EmployeeTable, { EmployeeTableItem } from './EmployeeTable'
import { connect } from 'react-redux'
import { SettingsState } from '../lib/reducers/settings'
import { formatDaysAndHours } from '../HolidayTable/common'
import { formatDayMonthYear } from '../lib/date'
import { UnpaidLeaveEditor } from './UnpaidLeaveEditor'
import { CountryID } from '../lib/domain'
import { TableHeading } from './SortableTable'

export const userHasRights = (loggedInUser: LoggedInUser) =>
  loggedInUser.isAdmin || (loggedInUser.isCountryManager && loggedInUser.managedCountries.some(({ id }) => id === 'FI'))

type UnpaidLeaveStatusViewProps = {
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}

const UnpaidLeaveStatusView: React.FC<UnpaidLeaveStatusViewProps> = ({ loggedInUser, settings }) => {
  const { locale } = settings
  const { managedCountries } = loggedInUser
  const selectableCountries = managedCountries.filter(({ id }) => ['FI', 'NL'].includes(id))
  const renderEditor = (employee: EmployeeTableItem, closeEditor: (updated: boolean) => void) => {
    return (
      <UnpaidLeaveEditor
        employee={employee.employee}
        startDate={employee.startDate}
        endDate={employee.endDate}
        colSpan={6}
        settings={settings}
        closeEditor={closeEditor}
      />
    )
  }

  return (
    <EmployeeTable
      title={locale.texts.admin.unpaidLeaves.title}
      selectableCountries={selectableCountries}
      userHasRights={userHasRights}
      tableHeadingsForCountry={tableHeadings}
      toDisplayRow={fieldsForEmployee}
      renderEditor={renderEditor}
      editable
    />
  )
}

const tableHeadings = (country: CountryID, locale: SettingsState['locale']): TableHeading[] => {
  return [
    { title: locale.texts.admin.vacations.date, sortPath: 'unpaidLeaves.checkpointDay' },
    { title: locale.texts.admin.vacations.available, sortPath: 'unpaidLeaves.checkpointCountHours' },
    { title: locale.texts.admin.vacations.used, sortPath: 'unpaidLeaves.usedHours' },
    { title: locale.texts.admin.vacations.planned, sortPath: 'unpaidLeaves.plannedHours' },
    { title: locale.texts.admin.vacations.remaining, sortPath: 'unpaidLeaves.total' },
  ]
}

const fieldsForEmployee = (employeeData: EmployeeTableItem, locale: SettingsState['locale']): string[] => {
  if (!employeeData.unpaidLeaves) {
    return ['', '', '', '', '']
  }
  const country = employeeData.currentContract?.company.country
  const { checkpointDay, checkpointCountHours, usedHours, plannedHours, total } = employeeData.unpaidLeaves
  return [
    formatDayMonthYear(locale)(checkpointDay),
    formatDaysAndHours(locale, checkpointCountHours, country),
    formatDaysAndHours(locale, usedHours, country),
    formatDaysAndHours(locale, plannedHours, country),
    formatDaysAndHours(locale, total, country),
  ]
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(UnpaidLeaveStatusView)
