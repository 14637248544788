import React from 'react'

import styles from './css/CalendarIcon.module.css'

const CalendarIcon = ({ props }) => (
  <>
    <img className={styles.mobile} {...props} src='/calendar.svg' width='13' height='13' alt='Calendar' />
    <img className={styles.desktop} {...props} src='/calendar.svg' width='15' height='15' alt='Calendar' />
  </>
)

export default CalendarIcon
