import React from 'react'
import { hasValidationErrors as hasErrors } from '../../lib/validation'
import { ViewModeEntry } from '../Entry'
import { WorkDay } from '../../lib/domain'
import ViewModeWorkingDay from './ViewModeWorkingDay'
import { WorkingDayCommonProps } from '../common'

interface ViewWorkingDayProps extends WorkingDayCommonProps {
  isWorkDay: boolean
  dayData: WorkDay
  startEndShown: boolean
  onDayItemClick: (index: number, name: string) => void
  locked?: boolean
}

const ViewWorkingDay = ({
  dayData,
  startEndShown,
  isWorkDay,
  holiday,
  locale,
  copyPreviousDay,
  onDayItemClick,
  locked,
}: ViewWorkingDayProps) => {
  const { entries, day, total: dayTotal } = dayData

  const entriesHaveNotes = entries.some((entry) => entry.note?.length)

  return (
    <ViewModeWorkingDay
      {...{
        day,
        dayTotal,
        holiday,
        isWorkDay,
        locale,
        hasValidationErrors: hasErrors(dayData),
        copyPreviousDay,
        locked,
      }}
    >
      {entries.map((entry, index) => (
        <ViewModeEntry
          key={`${day}-${index}`}
          entry={entry}
          startEndShown={startEndShown}
          onItemClick={(name: string) => onDayItemClick(index, name)}
          locked={locked}
          entriesHaveNotes={entriesHaveNotes}
        />
      ))}
    </ViewModeWorkingDay>
  )
}

// we dont want to rerender all viewmode working days every
// time DayList is rendered. render only when the props change
export default React.memo(ViewWorkingDay)
