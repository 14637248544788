// See Hours server for 1-to-1 Scala versions
export type CountryID = 'FI' | 'US' | 'JP' | 'NL' | 'SE' | 'PT'

export type Country = {
  id: CountryID
  name: string
}

export type ContractSession = {
  start: string
  hours: string
  fullHoursOfDay: number
}

export type Contract = {
  company: Company
  seasons: ContractSession[]
}

export type Company = {
  name: string
  country: CountryID
  shortName: string
  countryDefault: boolean
  subContractor?: boolean
}

export type WorkEntryValidation = {
  invalidStartTime?: string
  invalidEndTime?: string
  invalidHours?: string
  hoursLongerThanPeriod?: string
  wrongLengthFullDayAbsence?: string
  invalidFlextime?: string
  absenceLongerThanWorkDay?: string
  absenceIncreasingFlextime?: string
  unknownProject?: string
  outsideProjectDuration?: string
  unknownTask?: string
  outsideTaskDuration?: string
  absenceOnNotWorkDay?: string
  missingRequiredNote?: string
  alertOverlappingStandby?: string
}

export interface WorkEntryPolicyMetadata {
  policy: string
}

export type WorkEntry = {
  id?: number
  startTime: string
  endTime: string
  hours: string
  hourCode: string
  note?: string
  validation?: WorkEntryValidation
  forceKohoSync?: boolean
  policyMetadata?: WorkEntryPolicyMetadata
}

export type ReportWorkEntry = {
  day: string
  startTime: string
  endTime: string
  hours: string
  hourCode: string
  note: string
}

export type UsernameReportWorkEntry = ReportWorkEntry & {
  id?: number
  username: string
}

export type WorkDayValidation = {
  suspiciouslyFewHours?: string
  suspiciouslyManyHours?: string
  moreAbsenceThanWorkDayLength?: string
}

export type WorkDay = {
  day: string
  entries: WorkEntry[]
  total?: number
  difference?: number
  validation?: WorkDayValidation
}

export type WorkMonth = {
  month: string
  version: number
  days: WorkDay[]
  lastEventId?: number
  locked: boolean
  lockRequired: boolean
}

export type ExpectedDailyHours = {
  day: string
  hours: number
}

export type PolicyGroupType = string

export type InvoiceTaskInfo = {
  name: string
  description?: string
  valid?: boolean
  hasSalaryEffect?: boolean
  isInUse?: boolean
  start: string
  end?: string
  noteRequired?: boolean
  consumesAbsenceQuota?: boolean
  policyGroup?: PolicyGroupType
}

export type RexProject = {
  id: number
  name: string
}

export type InvoiceInfo = {
  name: string
  description?: string
  isInUse?: boolean
  start?: string
  end?: string
  status?: string
  client?: string
  info?: string
  responsible?: string
  businessUnit?: string
  billable: boolean
  invoicingCompany?: string
  noteRequired?: boolean
  tasks: InvoiceTaskInfo[]
  linkedRexProjects?: RexProject[]
}

export type HolidayUse = {
  base: number
  earned: number
  used: number
  planned: number
  agreed: number
  totalToEarnThisYear: number
  total: number
}

export type EmployeeHolidayStatus = {
  country: CountryID
  baseDay: string
  workdays: HolidayUse
  withSats: HolidayUse
}

export type EmployeeHolidayStatusFI = Omit<EmployeeHolidayStatus, 'workdays'> & {
  workdays: EmployeeHolidayStatus['workdays'] & { agreed: number }
}

export type EmployeeHolidayStatusNL = Omit<EmployeeHolidayStatus, 'workdays'> & {
  workdays: EmployeeHolidayStatus['workdays'] & { total: number; availableByEndOfYear: number }
}

export type EmployeeHolidayStatusUS = Omit<EmployeeHolidayStatus, 'workdays'> & {
  workdays: EmployeeHolidayStatus['workdays'] & { totalEarned: number; availableByEndOfYear: number }
}

export type HolidayStatus =
  | EmployeeHolidayStatus
  | EmployeeHolidayStatusFI
  | EmployeeHolidayStatusUS
  | EmployeeHolidayStatusNL

export type UnpaidVacationSummary = {
  checkpointDay: string
  checkpointCountHours: number
  usedHours: number
  plannedHours: number
  total: number
}

export type FloatingHolidaySummary = {
  checkpointDay: string
  availableDaysAtCheckpoint: number
  earnedDays: number
  totalToEarn: number
  usedDays: number
  plannedDays: number
  remainingDays: number
}

export type QuotaLimitedAbsenceSummaryMonth = {
  month: number
  absenceHours: number
  cumulativeAbsenceHours: number
}

export type QuotaLimitedAbsenceSummaryYear = {
  checkpointDay: string
  checkpointCountHours: number
  absenceHours: number
  usedHoursPerMonth: QuotaLimitedAbsenceSummaryMonth[]
}

export type QuotaLimitedAbsenceSummary = {
  yearSummaries: QuotaLimitedAbsenceSummaryYear[]
}

export type EmployeeName = {
  username: string
  firstName: string
  lastName: string
  email: string
  slackId: string
  external?: boolean
}

export type ContractCompany = {
  name: string
  country?: CountryID
  shortName?: string
  subContractor?: boolean
  countryDefault?: boolean
}

export type EmploymentSeason = {
  start: string
  hours: string
  country?: CountryID
  fullHoursOfDay?: number
  isFixedTerm: boolean
  isTrainee: boolean
}

export type EmploymentContract = {
  company: ContractCompany
  seasons: EmploymentSeason[]
  end?: string
  holidays?: EmployeeHolidayStatus
  unpaidLeaves?: UnpaidVacationSummary
  floatingHolidays2024?: FloatingHolidaySummary
  limitedAbsenceQuotaCheckpoint?: SpecialLeaveCheckpoint
}

export type EmploymentContracts = {
  employee?: EmployeeName
  contracts: EmploymentContract[]
}

export type SpecialLeaveCheckpointType = 'floating' | 'quota-limited' | 'unpaid'

export type SpecialLeaveCheckpoint = {
  day: string
  count: number
}

export type UnpaidLeaveCheckpoints = {
  currentSeason: SpecialLeaveCheckpoint
  nextSeason: SpecialLeaveCheckpoint
}

export type UsersWithUnlockedHours = {
  month: string
  countryData: {
    [objectKey: string]: EmployeeName[]
  }
}[]

export type HoursContractSeason = {
  isTrainee: boolean
  isFixedTerm: boolean
  start: string
  finish?: string
  type: string
  nonWorkdays: number[]
  expectedWorkingHoursPerDay: (isoDate: string) => number
  company: ContractCompany
}

export type InvoicesAndAbsences = {
  invoices: InvoiceInfo[]
  absences: InvoiceInfo[]
}

export type HourCode = {
  name: string
  description?: string
  start: string
  policyGroup?: string
}

export type HourCodeWithMetadata = {
  hourCode: string
  metadata: WorkEntryPolicyMetadata
}

export interface HourCodePolicy {
  name: string
  policyGroup: PolicyGroupType
  validityStart?: string
  validityEnd?: string
}

export type TravelPolicyCompensation = 'pay50' | 'flex50'

export class TravelPolicy22Metadata implements WorkEntryPolicyMetadata {
  policy = 'travel22'
  isIntercontinental: boolean
  compensation: TravelPolicyCompensation | undefined

  constructor(isIntercontinental: boolean, compensation: TravelPolicyCompensation | undefined) {
    this.isIntercontinental = isIntercontinental
    this.compensation = compensation
  }
}

export class TravelPolicyOldMetadata implements WorkEntryPolicyMetadata {
  policy = 'travel-old'
}

export interface ReportWorkEntrySearch {
  search: string
  startMonth: string
  endMonth: string
  username?: string
  country?: CountryID
}

export interface ReportingResponse<T> {
  results: T[]
  resultsLimit: number
}

export interface WorkDayUpdate {
  entries: WorkEntry[]
  totalHoursDifference: number
  monthVersion: number
}

export interface WorkDayUpdateResponse {
  day: {
    day: string
    entries: WorkEntry[]
    total: number
    difference: number
  }
  totalHoursDifference: number
  monthVersion: number
}

export interface Employee {
  username: string
  firstName: string
  lastName: string
  groups: string
  language: 'FI' | 'EN' | 'US'
  startDay: string
  totalHoursDifference: number
  startEndShown: boolean
}

export type EditContext = {
  day: string
  indexInDay: number
}

export type CopyPreviousDayFunction = (editContext: EditContext, updateDiff: Partial<WorkEntry>) => void

export type CopyPreviousDayFunctionMapType = {
  [x: string]: (modifyEntry: CopyPreviousDayFunction) => Promise<void>
}

export interface AbsenceCode {
  code: string
  description?: string
  start: Date
  end?: Date
}

export const isFIHolidays = (holidays: HolidayStatus): holidays is EmployeeHolidayStatusFI => holidays.country === 'FI'
export const isUSHolidays = (holidays: HolidayStatus): holidays is EmployeeHolidayStatusUS => holidays.country === 'US'
export const isNLHolidays = (holidays: HolidayStatus): holidays is EmployeeHolidayStatusNL => holidays.country === 'NL'
