import _ from 'lodash'
import {
  addDays as _addDays,
  addMonths,
  addYears as _addYears,
  format,
  formatWithOptions,
  startOfYear as _startOfYear,
  endOfDay as _endOfDay,
  endOfYear as _endOfYear,
  startOfMonth as _startOfMonth,
  startOfDay as _startOfDay,
  subDays,
  isWithinInterval as _isWithinInterval,
} from 'date-fns/fp'
import { subMonths as _subMonths } from 'date-fns/esm/fp'
import {
  startOfWeek,
  startOfISOWeek,
  isBefore as _isBefore,
  isAfter as _isAfter,
  isLastDayOfMonth as _isLastDayOfMonth,
  differenceInCalendarDays as _differenceInCalendarDays,
} from 'date-fns'

export const formatISO = format('YYYY-MM-DD')
export const formatISOMonth = format('YYYY-MM')
export const formatYearHyphenShortMonth = format('YYYY-MM')
export const today = () => formatISO(new Date())
export const currentYear = () => new Date().getFullYear()

export const yearOfISODay = (day) => Number(day.split('-')[0])

export const formatDayAndMonth = (locale) => format(locale.datetime.dateDisplayShort)
export const formatDayMonthYear = (locale) => format(locale.datetime.dateDisplayLong)

const formatOptions = (locale) => (locale.datetime.dateFnsLocale ? { locale: locale.datetime.dateFnsLocale } : {})

export const formatDayOfWeek = (locale) => formatWithOptions(formatOptions(locale), locale.datetime.weekdayFormat)
export const formatMonth = (locale) => formatWithOptions(formatOptions(locale), 'MMMM')

export const formatRange =
  (locale) =>
  ({ start, end }) => {
    const startDate = start ? parseISODate(start) : ''
    const endDate = end ? parseISODate(end) : ''
    const { startFormat, endFormat } = locale.datetime.rangeFormat(startDate, endDate)
    return format(startFormat)(startDate || new Date()) + format(endFormat)(endDate || new Date())
  }

export function monthRange(numMonthsBack, numMonthsForward) {
  const thisMonth = startOfMonth(new Date())
  return [_subMonths(numMonthsBack, thisMonth), addMonths(numMonthsForward, thisMonth)]
}

// parses YYYY-MM-DD string
export const parseISODate = (day) => {
  const parts = day.split('-')
  return new Date(parts[0], parts[1] - 1, parts[2])
  // date-fns function `parse(new Date(), "YYYY-MM-DD", d)` is noticeably slower
}

function isSunday(day) {
  return parseISODate(day).getDay() === 0
}

function isMonday(day) {
  return parseISODate(day).getDay() === 1
}

function isFriday(day) {
  return parseISODate(day).getDay() === 5
}

function isSaturday(day) {
  return parseISODate(day).getDay() === 6
}

export function isFirstDayOfWorkWeek(day, country) {
  return country === 'AE' ? isSunday(day) : isMonday(day)
}

export function isLastDayOfWorkWeek(day, country) {
  return country === 'AE' ? isSaturday(day) : isSunday(day)
}

export function isFirstDayOfWeekend(day, country) {
  return country === 'AE' ? isFriday(day) : isSaturday(day)
}

export function isLastDayOfWeekend(day, country) {
  return isLastDayOfWorkWeek(day, country)
}

export function isLastDayOfMonth(day) {
  return _isLastDayOfMonth(parseISODate(day))
}

export function isWeekend(day, country) {
  return isFirstDayOfWeekend(day, country) || isLastDayOfWeekend(day, country)
}

// aka typical working day, mon-fri in most countries
export function isWeekday(day, country) {
  return !isWeekend(day, country)
}

export function otherWeekendDay(day, country) {
  if (isFirstDayOfWeekend(day, country)) return tomorrow(day)
  if (isLastDayOfWeekend(day, country)) return yesterday(day)
  return null
}

export function tomorrow(day) {
  return _addDays(1, parseISODate(day))
}

export function yesterday(day) {
  return subDays(1, parseISODate(day))
}

export function getISODayOfWeek(day) {
  return new Date(day).getUTCDay() || 7 // 1-7, 1 = monday, 7 = sunday
}

export const formatISODayOfWeek = (locale) => (dayOfWeek) => {
  const date = _addDays(parseInt(dayOfWeek) - 1, startOfWeek(today(), { weekStartsOn: 1 }))
  return formatDayOfWeek(locale)(date)
}

export function nextWorkingDay(day, publicHolidays = {}) {
  const nextDay = tomorrow(day)
  const nextDayString = formatISO(nextDay)
  if (publicHolidays[nextDayString] || isWeekend(nextDayString)) {
    return nextWorkingDay(nextDayString, publicHolidays)
  } else return nextDayString
}

export function previousWorkingDay(day, publicHolidays = {}) {
  const previousDay = yesterday(day)
  const previousDayString = formatISO(previousDay)
  if (publicHolidays[previousDayString] || isWeekend(previousDayString)) {
    return previousWorkingDay(previousDayString, publicHolidays)
  } else return previousDayString
}

export const startOfWorkWeek = (day, country) => {
  if (country === 'AE') {
    const SUNDAY = 0
    return startOfWeek(day, { weekStartsOn: SUNDAY })
  }

  return startOfISOWeek(day) // monday
}

export const getWorkWeekISODaysOfWeek = (country) => {
  if (country === 'AE') {
    // work week is sunday - thursday in dubai
    return _.range(0, 6 + 1)
  }

  return _.range(1, 7 + 1)
}

export const startOfDay = (date) => _startOfDay(date)
export const endOfDay = (date) => _endOfDay(date)
export const endOfYear = (date) => _endOfYear(date)
export const startOfYear = (date) => _startOfYear(date)
export const addDays = (date, howMany) => _addDays(howMany, date)
export const addYears = (date, howMany) => _addYears(howMany, date)
export const subMonths = (d1, howMany) => _subMonths(howMany, d1)
export const isBefore = (d1, d2) => _isBefore(d1, d2)
export const isAfter = (d1, d2) => _isAfter(d1, d2)
export const startOfMonth = (d1) => _startOfMonth(d1)
export const isWithinInterval = ({ start, end }, date) => _isWithinInterval({ start, end }, date)
export const differenceInCalendarDays = (laterDate, earlierDate) => _differenceInCalendarDays(laterDate, earlierDate)
