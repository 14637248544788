import React from 'react'
import classnames from 'classnames'
import styles from './css/CalendarGuide.module.css'
import { SettingsState } from './lib/reducers/settings'
import { CountryID } from './lib/domain'

interface GuideBlockProps {
  className: string
  description: string
  descriptionClassName?: string
}

const GuideBlock: React.FC<GuideBlockProps> = ({ className, description, descriptionClassName }) => (
  <div className={styles.guideBlock}>
    <div className={className} />
    <div className={classnames(descriptionClassName)}>{description}</div>
  </div>
)

interface CalendarGuideProps {
  country: CountryID
  locale: SettingsState['locale']
  hasErrors: boolean
}

const CalendarGuide: React.FC<CalendarGuideProps> = ({ country, locale, hasErrors }) => (
  <div className={classnames(styles.calendarGuide)}>
    <div className={styles.guideWrapper}>
      <GuideBlock className={classnames(styles.vacationGuide)} description={locale.texts.vacations.vacations} />
      {country === 'US' && (
        <GuideBlock
          className={classnames(styles.floatingHolidaysGuide)}
          description={locale.texts.vacations.floatingHolidays}
          descriptionClassName={styles.floatingHolidaysGuideDescription}
        />
      )}
      {hasErrors && <GuideBlock className={classnames(styles.errorGuide)} description={locale.texts.errors} />}
    </div>
  </div>
)

export default CalendarGuide
