import React from 'react'
import classnames from 'classnames'

import styles from './css/LanguageButton.module.css'

const LanguageButton = ({ language, currentLanguage, updateLanguage }) => (
  <button
    className={classnames([styles.languageButton, currentLanguage === language ? styles.current : ''])}
    onClick={(e) => updateLanguage(language)}
  >
    {language}
  </button>
)

export default LanguageButton
