import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { formatDayMonthYear } from '../lib/date'
import { LoggedInUser } from '../lib/reducers/user'
import { RootState } from '../lib/store'
import { CountryID, EmployeeHolidayStatus, HolidayUse, isFIHolidays, isNLHolidays, isUSHolidays } from '../lib/domain'
import { SettingsState } from '../lib/reducers/settings'
import EmployeeTable, { EmployeeTableItem } from './EmployeeTable'
import { TableHeading } from './SortableTable'

export const userHasRights = (loggedInUser: LoggedInUser) => loggedInUser.isAdmin || loggedInUser.isCountryManager

const fieldsForEmployee = (employeeData: EmployeeTableItem, locale: SettingsState['locale']): string[] => {
  const { currentSeason, holidays } = employeeData
  const personLogsHours = currentSeason?.hours !== 'not_required'
  const showSaturdays = personLogsHours && holidays.country === 'FI'
  const showAvailableAndRemainingHolidays = personLogsHours

  const formattedDate = formatDayMonthYear(locale)(holidays.baseDay)

  if (isNLHolidays(holidays)) {
    const { base, earned, planned, used, total, availableByEndOfYear, totalToEarnThisYear } = holidays.workdays
    return [
      formattedDate,
      showAvailableAndRemainingHolidays ? availableByEndOfYear : '',
      showAvailableAndRemainingHolidays ? base : '',
      showAvailableAndRemainingHolidays ? `${earned}/${totalToEarnThisYear}` : '',
      used,
      planned,
      showAvailableAndRemainingHolidays ? total : '',
    ].map(_.toString)
  }

  if (isUSHolidays(holidays)) {
    const { base, earned, planned, used, total, totalEarned, totalToEarnThisYear } = holidays.workdays
    return [
      formattedDate,
      showAvailableAndRemainingHolidays ? totalEarned : '',
      showAvailableAndRemainingHolidays ? base : '',
      showAvailableAndRemainingHolidays ? `${earned}/${totalToEarnThisYear}` : '',
      used,
      planned,
      showAvailableAndRemainingHolidays ? total : '',
    ].map(_.toString)
  }

  const toDisplayText = (checkpoint: EmployeeHolidayStatus, field: keyof HolidayUse) => {
    return showSaturdays ? `${checkpoint.workdays[field]} (${checkpoint.withSats[field]})` : checkpoint.workdays[field]
  }

  const defaultHolidayFields = [
    showAvailableAndRemainingHolidays ? toDisplayText(holidays, 'base') : '',
    toDisplayText(holidays, 'used'),
    toDisplayText(holidays, 'planned'),
    showAvailableAndRemainingHolidays ? toDisplayText(holidays, 'total') : '',
  ].map(_.toString)

  if (isFIHolidays(holidays)) {
    return [
      formattedDate,
      showAvailableAndRemainingHolidays && holidays.workdays.agreed !== 0 ? holidays.workdays.agreed : '',
      ...defaultHolidayFields,
    ].map(_.toString)
  }

  return [formattedDate, ...defaultHolidayFields]
}

const tableHeadingsForCountry = (country: CountryID, locale: SettingsState['locale']): TableHeading[] => {
  const dateHeading: TableHeading = { title: locale.texts.admin.vacations.date, sortPath: 'holidays.baseDay' }

  if (country === 'FI') {
    return [
      dateHeading,
      { title: locale.texts.admin.vacations.agreed, sortPath: 'holidays.workdays.agreed' },
      { title: locale.texts.admin.vacations.workdaysWithSats, sortPath: 'holidays.workdays.base' },
      { title: locale.texts.admin.vacations.usedWithSats, sortPath: 'holidays.workdays.used' },
      { title: locale.texts.admin.vacations.plannedWithSats, sortPath: 'holidays.workdays.planned' },
      { title: locale.texts.admin.vacations.totalWithSats, sortPath: 'holidays.workdays.total' },
    ]
  }

  if (country === 'NL') {
    return [
      dateHeading,
      {
        title: locale.texts.admin.vacations.availableInYear(new Date().getFullYear()),
        sortPath: 'holidays.workdays.availableByEndOfYear',
      },
      { title: locale.texts.admin.vacations.transferred, sortPath: 'holidays.workdays.base' },
      { title: locale.texts.admin.vacations.earned, sortPath: 'holidays.workdays.earned' },
      { title: locale.texts.admin.vacations.used, sortPath: 'holidays.workdays.used' },
      { title: locale.texts.admin.vacations.planned, sortPath: 'holidays.workdays.planned' },
      { title: locale.texts.admin.vacations.remaining, sortPath: 'holidays.workdays.total' },
    ]
  }

  if (country === 'US') {
    return [
      dateHeading,
      { title: locale.texts.admin.vacations.available, sortPath: 'holidays.workdays.totalEarned' },
      { title: locale.texts.admin.vacations.transferred, sortPath: 'holidays.workdays.base' },
      { title: locale.texts.admin.vacations.earned, sortPath: 'holidays.workdays.earned' },
      { title: locale.texts.admin.vacations.used, sortPath: 'holidays.workdays.used' },
      { title: locale.texts.admin.vacations.planned, sortPath: 'holidays.workdays.planned' },
      { title: locale.texts.admin.vacations.remaining, sortPath: 'holidays.workdays.total' },
    ]
  }

  return [
    dateHeading,
    { title: locale.texts.admin.vacations.days, sortPath: 'holidays.workdays.base' },
    { title: locale.texts.admin.vacations.used, sortPath: 'holidays.workdays.used' },
    { title: locale.texts.admin.vacations.planned, sortPath: 'holidays.workdays.planned' },
    { title: locale.texts.admin.vacations.total, sortPath: 'holidays.workdays.total' },
  ]
}

type VacationStatusViewProps = {
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}

const VacationStatusView: React.FC<VacationStatusViewProps> = ({ loggedInUser, settings: { locale } }) => {
  const { managedCountries } = loggedInUser

  return (
    <EmployeeTable
      title={locale.texts.admin.vacations.title}
      selectableCountries={managedCountries}
      userHasRights={userHasRights}
      tableHeadingsForCountry={tableHeadingsForCountry}
      toDisplayRow={fieldsForEmployee}
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(VacationStatusView)
