import React from 'react'
import classnames from 'classnames'

import { fetchCurrentEmployeesOfCountry, fetchCurrentlyContractedOrReportedEmployees } from './lib/hoursApi'
import { fuzzy } from './lib/fuzzy'

import styles from './css/ImpersonationInput.module.css'
import Spinner from './Spinner'

const SuggestionsItem = ({
  suggestion: {
    original: { username, firstName, lastName, external },
  },
  onClick,
}) => (
  <div className={styles.suggestionsItem} onClick={onClick}>
    <img
      className={classnames(styles.suggestionsItemPortrait, {
        [styles.external]: external,
      })}
      alt={username}
      src={`https://cdn.rex.reaktor.cloud/people/95x95/${username}.jpg`}
    />
    {firstName} {lastName}
    <br />({username})
  </div>
)

class ImpersonationInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      isOpen: false,
      employees: [],
      suggestions: [],
      showSuggestions: false,
    }

    this.onOpenClicked = this.onOpenClicked.bind(this)
    this.onChangeToSelfClicked = this.onChangeToSelfClicked.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onInputFocus = this.onInputFocus.bind(this)

    this.inputRef = React.createRef()
  }

  refreshSiteWithUid(uid) {
    const url = new URL(window.location.href)
    if (uid === this.props.loggedInUsername) {
      url.searchParams.delete('uid')
    } else {
      url.searchParams.set('uid', uid)
    }

    window.location.href = url.href
  }

  async onOpenClicked(e) {
    e.stopPropagation()
    this.setState({ isOpen: true }, () => this.inputRef.current && this.inputRef.current.focus())

    const { isAdmin, managedCountries } = this.props

    const employees = isAdmin
      ? await fetchCurrentlyContractedOrReportedEmployees()
      : (await Promise.all(managedCountries.map((country) => fetchCurrentEmployeesOfCountry(country.id)))).flat(1)
    this.setState({ employees }, this.updateSuggestions)
  }

  onChangeToSelfClicked(e) {
    e.stopPropagation()
    this.refreshSiteWithUid(this.props.loggedInUsername)
  }

  onInputChange(e) {
    this.setState({ value: e.target.value }, this.updateSuggestions)
  }

  onInputFocus() {
    this.setState({ showSuggestions: true })
  }

  updateSuggestions() {
    const { value } = this.state
    const MAX_SUGGESTIONS_TO_SHOW = 20
    const suggestions = fuzzy(this.state.employees, value, {
      threshold: 0.3,
      keys: ['firstName', 'lastName', 'username'],
    }).slice(0, MAX_SUGGESTIONS_TO_SHOW)

    this.setState({ suggestions })
  }

  render() {
    const { locale, loggedInUsername, dataOwnerUsername } = this.props
    const { suggestions, value, isOpen, showSuggestions } = this.state
    if (!isOpen) {
      return (
        <>
          <span data-test='impersonationInput-openLink' className={styles.openLink} onClick={this.onOpenClicked}>
            {locale.texts.change}
          </span>
          {loggedInUsername !== dataOwnerUsername && (
            <span className={styles.changeToSelfLink} onClick={this.onChangeToSelfClicked}>
              {locale.texts.changeToSelf}
            </span>
          )}
        </>
      )
    }

    return (
      <div data-test='impersonationInput' onClick={(e) => e.stopPropagation()} className={styles.impersonationInput}>
        <input
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          ref={this.inputRef}
          className={styles.input}
          placeholder={locale.texts.searchForPerson}
          value={value}
          onChange={this.onInputChange}
          onClick={this.onInputFocus}
          onFocus={this.onInputFocus}
        />
        {showSuggestions && value.length > 0 && (
          <div className={styles.suggestionsContainer}>
            <div className={styles.suggestions}>
              {this.state.employees.length === 0 ? (
                <Spinner />
              ) : (
                suggestions.length > 0 &&
                suggestions.map((suggestion) => (
                  <SuggestionsItem
                    key={suggestion.original.username}
                    suggestion={suggestion}
                    onClick={() => this.refreshSiteWithUid(suggestion.original.username)}
                  />
                ))
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ImpersonationInput
