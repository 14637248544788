import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import {
  generateDisplayedMonths,
  injectTotalHoursAndFlexToMonths,
  isVacationCode,
  isFloatingHolidayCode,
} from './lib/util'
import { addYears, endOfYear, getDate, getISOWeek, getYear, getDaysInMonth } from 'date-fns'

import { formatISO, formatISOMonth, formatMonth, today, getWorkWeekISODaysOfWeek } from './lib/date'

import styles from './css/Calendar.module.css'

const isHoliday = (publicHolidays, day, dayIndexInWeek) =>
  publicHolidays[day] || dayIndexInWeek === 5 || dayIndexInWeek === 6
const weekYearAndIsoWeek = (week) => `${getYear(week.days[6] || week.days[0])}-${week.isoWeek}`
const formatSumBasedOnSumMode = (sumMode) => (number) =>
  sumMode === 'flex' && number > 0 ? `+${number}` : number.toString()

const Calendar = ({
  publicHolidays,
  locale,
  months,
  vacations,
  floatingHolidays,
  innerRef,
  sumMode,
  onDayClicked,
  daysWithErrors,
}) => {
  const scrollingContainerRef = useRef()
  const now = new Date()
  const thisWeek = getYear(now) + '-' + getISOWeek(now)
  const errors = (daysWithErrors || []).map((d) => formatISO(d))
  useImperativeHandle(innerRef, () => ({
    scrollToDay(isoDay) {
      const month = formatISOMonth(isoDay)
      const scrollPercentage = getDate(isoDay) / getDaysInMonth(isoDay)

      this.scrollToMonth({ month, scrollPercentage })
    },
    scrollToMonth({ month, monthScrollPercentage = 0.5 }) {
      const monthRef = scrollingContainerRef.current.querySelector(`[data-month='${month}']`)
      const firstMonthRef = scrollingContainerRef.current.querySelector(`[data-month]`)

      if (firstMonthRef && monthRef) {
        const staticOffset = -firstMonthRef.offsetTop
        const scrollOffset = monthRef.offsetTop + staticOffset + monthRef.clientHeight * monthScrollPercentage
        const centerOffset = -scrollingContainerRef.current.clientHeight * 0.4

        scrollingContainerRef.current.scrollTop = scrollOffset + centerOffset
      }
    },
    getScrollContainerRef: () => scrollingContainerRef,
  }))

  return (
    <div className={styles.monthsContainer} ref={scrollingContainerRef}>
      {months.map(({ month, weeks, [sumMode]: monthlySum }) => (
        <div key={month} className={styles.monthContainer} data-month={month}>
          <div className={styles.monthRow}>
            <span className={styles.month}>
              {formatMonth(locale)(month)} {getYear(month)}
            </span>
            <span data-test='calendar-monthlyHours' className={styles.monthlyHours}>
              {formatSumBasedOnSumMode(sumMode)(monthlySum)} h
            </span>
          </div>
          {weeks.map((week) => (
            <div
              key={weekYearAndIsoWeek(week)}
              className={classnames(styles.weekRow, { [styles.currentWeek]: weekYearAndIsoWeek(week) === thisWeek })}
              data-iso-week={weekYearAndIsoWeek(week)}
            >
              <span className={styles.weekNumber}>{week.isoWeek}</span>
              {week.days.map((day, dayIndexInWeek) => {
                const isSingleDayVacation =
                  !vacations[week.days[dayIndexInWeek - 1]] &&
                  !vacations[week.days[dayIndexInWeek + 1]] &&
                  vacations[day]
                const isStartOfVacation =
                  !vacations[week.days[dayIndexInWeek - 1]] &&
                  vacations[day] &&
                  vacations[week.days[dayIndexInWeek + 1]]
                const isMidOfVacation =
                  vacations[week.days[dayIndexInWeek - 1]] && vacations[day] && vacations[week.days[dayIndexInWeek + 1]]
                const isEndOfVacation =
                  vacations[week.days[dayIndexInWeek - 1]] &&
                  vacations[day] &&
                  !vacations[week.days[dayIndexInWeek + 1]]
                const isSingleDayFloatingHoliday =
                  !vacations[day] &&
                  !floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                  !floatingHolidays[week.days[dayIndexInWeek + 1]] &&
                  floatingHolidays[day]
                const isStartOfFloatingHoliday =
                  !vacations[day] &&
                  !floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                  floatingHolidays[day] &&
                  floatingHolidays[week.days[dayIndexInWeek + 1]]
                const isMidOfFloatingHoliday =
                  !vacations[day] &&
                  floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                  floatingHolidays[day] &&
                  floatingHolidays[week.days[dayIndexInWeek + 1]]
                const isEndOfFloatingHoliday =
                  !vacations[day] &&
                  floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                  floatingHolidays[day] &&
                  !floatingHolidays[week.days[dayIndexInWeek + 1]]
                const hasErrors = errors.includes(day)

                return (
                  <div
                    data-test='calendar-day'
                    className={classnames(styles.day, {
                      [styles.error]: errors.includes(day),
                      [styles.today]: day === formatISO(now),
                      [styles.holiday]: isHoliday(publicHolidays, day, dayIndexInWeek),
                    })}
                    key={day || dayIndexInWeek}
                    title={publicHolidays[day]}
                    onClick={onDayClicked.bind(null, day)}
                  >
                    <span>{day && getDate(day)}</span>
                    {!hasErrors && day === formatISO(now) && <div className={styles.todayCircle} />}
                    {hasErrors && <div className={styles.errorCircle} data-test='error-circle' />}
                    {isSingleDayVacation && <div className={styles.vacationCircle} data-test='vacation-circle' />}
                    {isStartOfVacation && (
                      <div className={styles.vacationCircleStart} data-test='vacation-circle-start' />
                    )}
                    {isMidOfVacation && <div className={styles.vacationMidSquare} data-test='vacation-circle-mid' />}
                    {isEndOfVacation && <div className={styles.vacationCircleEnd} data-test='vacation-circle-end' />}
                    {isSingleDayFloatingHoliday && (
                      <div className={styles.floatingHolidayCircle} data-test='floating-holiday-circle' />
                    )}
                    {isStartOfFloatingHoliday && (
                      <div className={styles.floatingHolidayCircleStart} data-test='floating-holiday-circle-start' />
                    )}
                    {isMidOfFloatingHoliday && (
                      <div className={styles.floatingHolidayMidSquare} data-test='floating-holiday-circle-mid' />
                    )}
                    {isEndOfFloatingHoliday && (
                      <div className={styles.floatingHolidayCircleEnd} data-test='floating-holiday-circle-end' />
                    )}
                  </div>
                )
              })}
              <span
                className={classnames(styles.weeklyHours, {
                  [styles.positiveFlex]: sumMode === 'flex' && week[sumMode] > 0,
                  [styles.zeroFlex]: sumMode === 'flex' && week[sumMode] === 0,
                  [styles.negativeFlex]: sumMode === 'flex' && week[sumMode] < 0,
                })}
              >
                {formatSumBasedOnSumMode(sumMode)(week[sumMode])} h
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const CalendarWrapper = (
  {
    publicHolidays,
    serverDays,
    daysWithErrors,
    contracts,
    startDate,
    locale,
    onDayClicked,
    userExpectedHours,
    sumMode,
  },
  ref
) => {
  const currentContract = contracts.find((c) => c.start <= today() && (!c.finish || c.finish >= today()))
  const country = (currentContract && currentContract.company && currentContract.company.country) || 'FI'
  const endDate = endOfYear(addYears(today(), 1))
  const months = useMemo(() => generateDisplayedMonths(startDate, endDate, country), [startDate, endDate, country])
  const monthsWithWorkHours = useMemo(
    () => injectTotalHoursAndFlexToMonths(months, serverDays, userExpectedHours),
    [months, serverDays, userExpectedHours]
  )

  const vacations = useMemo(
    () =>
      [
        ...Object.entries(serverDays).filter(
          ([_key, val]) => val.entries.filter((entry) => isVacationCode(entry.hourCode)).length > 0
        ),
      ].reduce((acc, [key, _val]) => ({ ...acc, [key]: true }), {}),
    [serverDays]
  )

  const floatingHolidays = useMemo(
    () =>
      [
        ...Object.entries(serverDays).filter(
          ([_key, val]) => val.entries.filter((entry) => isFloatingHolidayCode(entry.hourCode)).length > 0
        ),
      ].reduce((acc, [key, _val]) => ({ ...acc, [key]: true }), {}),
    [serverDays]
  )

  // scroll the calendar to today() after first render
  useEffect(() => {
    ref.current && ref.current.scrollToDay(today())
  }, [months, ref])

  return (
    !_.isEmpty(months) && (
      <Calendar
        {...{
          publicHolidays,
          workWeekIsoDaysOfWeek: getWorkWeekISODaysOfWeek(country),
          startDate,
          locale,
          months: monthsWithWorkHours,
          vacations,
          floatingHolidays,
          daysWithErrors,
          sumMode,
          onDayClicked,
          innerRef: ref,
        }}
      />
    )
  )
}

export default React.memo(React.forwardRef(CalendarWrapper))
