import React from 'react'
import CopyButton from '../CopyButton'
import { WorkingDayCommonProps } from '../common'
import styles from '../../css/EditModeWorkingDay.module.css'
import { Locale } from '../../lib/reducers/settings'

interface EditModeWorkingDayAncillariesProps {
  children: JSX.Element[]
  copyPreviousDay: WorkingDayCommonProps['copyPreviousDay']
  dayTotal: number
  missingWorkingHours: number
  locale: Locale
}

const EditModeWorkingDayAncillaries = ({
  children,
  copyPreviousDay,
  dayTotal,
  missingWorkingHours,
  locale,
}: EditModeWorkingDayAncillariesProps) => {
  const isCopyable = children.length <= 1 && copyPreviousDay
  const shouldRenderTotal = !isCopyable && children.length > 0 && dayTotal >= 0

  return (
    <>
      {isCopyable && <CopyButton onClick={copyPreviousDay} locale={locale} />}
      {shouldRenderTotal && (
        <>
          <div className='workingDay__sum'>{dayTotal} h</div>
          {missingWorkingHours !== 0 && (
            <div className={styles.expected} data-test='workingDay-expected'>{`${
              missingWorkingHours < 0 ? '+' : ''
            }${-missingWorkingHours}`}</div>
          )}
        </>
      )}
    </>
  )
}

export default EditModeWorkingDayAncillaries
