import { combineReducers } from 'redux'
import { personalHoursClient } from './personal-hours-client'
import { adminClient } from './admin'
import { user } from './user'
import { settings } from './settings'
import { ui } from './ui'

const data = combineReducers({ personalHoursClient, adminClient })
const reducers = combineReducers({ user, data, settings, ui })

export default reducers

export type RootState = ReturnType<typeof reducers>
