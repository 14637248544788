import React from 'react'
import _ from 'lodash'

export const INPUT_DEBOUNCE_DELAY = 100

export default class NonOverwritingInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputHasFocus: false,
      hasBeenAutoSelectedAfterFocus: false,
      value: this.props.value,
    }

    this.inputRef = React.createRef()
    this.onFocus = this.onFocus.bind(this)
    this.focus = this.focus.bind(this)
    this.isBlurrable = this.isBlurrable.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.selectContent = this.selectContent.bind(this)

    // debounce the onChange calls to limit the call amount when user is writing quickly
    this.triggerOnChange = _.debounce(this.triggerOnChange, INPUT_DEBOUNCE_DELAY)
  }

  onFocus(e) {
    this.setState({ inputHasFocus: true })
    this.props.onFocus && this.props.onFocus(e)
  }

  isBlurrable(e) {
    return (
      (e.key === 'ArrowLeft' && this.inputRef.current.selectionStart === 0) ||
      (e.key === 'ArrowRight' && this.inputRef.current.value.length === this.inputRef.current.selectionStart)
    )
  }

  focus() {
    if (this.inputRef.current === document.activeElement) return
    this.inputRef.current.focus()
    this.inputRef.current.selectionStart = this.inputRef.current.value.length
  }

  selectContent() {
    if (this.state.hasBeenAutoSelectedAfterFocus) {
      return
    }

    const input = this.inputRef.current
    input && input.setSelectionRange(0, input.value.length)
    this.setState({ hasBeenAutoSelectedAfterFocus: true })
  }

  onBlur(e) {
    this.setState({ inputHasFocus: false, hasBeenAutoSelectedAfterFocus: false })
    this.triggerOnChange.flush() // call it immediately if the debounce is active
    this.props.onBlur && this.props.onBlur(e)
  }

  onChange(e) {
    const { value } = e.target
    this.setState({ value }, this.triggerOnChange)
  }

  // debounced
  triggerOnChange() {
    this.props.onChange && this.props.onChange({ target: { value: this.state.value } })
  }

  componentDidUpdate() {
    if (!this.state.inputHasFocus && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value })
    }
  }

  get value() {
    return this.state.value
  }

  overwrite(value) {
    this.setState({ value })
  }

  render() {
    return (
      <input
        {...this.props}
        ref={this.inputRef}
        value={this.value}
        onMouseUp={this.selectContent}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      />
    )
  }
}
