import { Action, Reducer } from 'redux'
import { serverLanguageSettingToLocale, supportedLocales } from '../i18n'
import { PayloadAction } from './payload-action'
import { UpdateDataOwnerAction } from './user'

export type Locale = typeof supportedLocales[number]

export type SettingsState = {
  locale: Locale
  startEndShown: boolean
  monthLockFeatureEnabled: boolean
  numMonthsBack: number
  numMonthsForward: number
  billingRatioShown: boolean
  summaryShown: boolean
  vacationsShown: boolean
  vacationsPopupShown: boolean
  billingRatioPopupShown: boolean
  absencesPopupShown: boolean
  summaryPopupShown: boolean
  absencesShown: boolean
  isMenuVisible: boolean
}
type UpdateLocaleAction = PayloadAction<'UPDATE_LOCALE', { language: string }>
type ShowMoreMonthsBackAction = PayloadAction<'SHOW_MORE_MONTHS_BACK', { amount: number }>
type ShowMoreMonthsForwardAction = PayloadAction<'SHOW_MORE_MONTHS_FORWARD', { amount: number }>
type ToggleMenuAction = Action<'TOGGLE_MENU'>
type ChangeBillableRatioShownAction = PayloadAction<
  'CHANGE_BILLABLE_RATIO_SHOWN',
  { billingRatioShown: SettingsState['billingRatioShown'] }
>
type ChangeSummaryShownAction = PayloadAction<'CHANGE_SUMMARY_SHOWN', { summaryShown: SettingsState['summaryShown'] }>
type ChangeStartEndShownAction = PayloadAction<
  'CHANGE_START_END_SHOWN',
  { startEndShown: SettingsState['startEndShown'] }
>
type ChangeMonthLockFeatureEnabledAction = PayloadAction<'CHANGE_MONTH_LOCK_FEATURE_ENABLED', {}>
type ChangeVacationsShownAction = PayloadAction<
  'CHANGE_VACATIONS_SHOWN',
  { vacationsShown: SettingsState['vacationsShown'] }
>
type ChangeVacationsPopupShownAction = PayloadAction<
  'CHANGE_VACATIONS_POPUP_SHOWN',
  { vacationsPopupShown: SettingsState['vacationsPopupShown'] }
>
type ChangeBillingRatioPopupShownAction = PayloadAction<
  'CHANGE_BILLING_RATIO_POPUP_SHOWN',
  { billingRatioPopupShown: SettingsState['billingRatioPopupShown'] }
>
type ChangeAbsencesPopupShownAction = PayloadAction<
  'CHANGE_ABSENCES_POPUP_SHOWN',
  { absencesPopupShown: SettingsState['absencesPopupShown'] }
>
type ChangeSummaryPopupShownAction = PayloadAction<
  'CHANGE_SUMMARY_POPUP_SHOWN',
  { summaryPopupShown: SettingsState['summaryPopupShown'] }
>
type ChangeAbsencesShownAction = PayloadAction<
  'CHANGE_ABSENCES_SHOWN',
  { absencesShown: SettingsState['absencesShown'] }
>
type SettingsAction =
  | UpdateDataOwnerAction
  | UpdateLocaleAction
  | ShowMoreMonthsBackAction
  | ShowMoreMonthsForwardAction
  | ToggleMenuAction
  | ChangeBillableRatioShownAction
  | ChangeSummaryShownAction
  | ChangeStartEndShownAction
  | ChangeVacationsShownAction
  | ChangeVacationsPopupShownAction
  | ChangeBillingRatioPopupShownAction
  | ChangeAbsencesPopupShownAction
  | ChangeSummaryPopupShownAction
  | ChangeAbsencesShownAction
  | ChangeMonthLockFeatureEnabledAction

const DEFAULT_SETTINGS_STATE: SettingsState = {
  numMonthsBack: 3,
  numMonthsForward: 1,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  locale: supportedLocales[0]!,
  startEndShown: true,
  monthLockFeatureEnabled: false,
  billingRatioShown: localStorage.getItem('settings.showBillingRatio') === 'true',
  vacationsShown: localStorage.getItem('settings.showVacations') !== 'false',
  summaryShown: localStorage.getItem('settings.showSummary') !== 'false',
  absencesShown: localStorage.getItem('settings.showAbsences') === 'true',
  vacationsPopupShown: false,
  absencesPopupShown: false,
  isMenuVisible: false,
  billingRatioPopupShown: false,
  summaryPopupShown: false,
}

export const settings: Reducer<SettingsState, SettingsAction> = (state = DEFAULT_SETTINGS_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_LOCALE': {
      const locale = serverLanguageSettingToLocale(action.language)
      return { ...state, locale }
    }
    case 'UPDATE_DATA_OWNER_USER': {
      const { startEndShown, monthLockFeatureEnabled } = action.employee
      return { ...state, startEndShown, monthLockFeatureEnabled }
    }
    case 'SHOW_MORE_MONTHS_BACK':
      return { ...state, numMonthsBack: state.numMonthsBack + action.amount }
    case 'SHOW_MORE_MONTHS_FORWARD':
      return { ...state, numMonthsForward: state.numMonthsForward + action.amount }
    case 'CHANGE_BILLABLE_RATIO_SHOWN':
      return { ...state, billingRatioShown: action.billingRatioShown }
    case 'CHANGE_SUMMARY_SHOWN':
      return { ...state, summaryShown: action.summaryShown }
    case 'CHANGE_START_END_SHOWN':
      return { ...state, startEndShown: action.startEndShown }
    case 'CHANGE_VACATIONS_SHOWN':
      return { ...state, vacationsShown: action.vacationsShown }
    case 'CHANGE_VACATIONS_POPUP_SHOWN':
      return { ...state, vacationsPopupShown: action.vacationsPopupShown }
    case 'CHANGE_BILLING_RATIO_POPUP_SHOWN':
      return {
        ...state,
        billingRatioPopupShown: action.billingRatioPopupShown,
      }
    case 'CHANGE_ABSENCES_POPUP_SHOWN':
      return {
        ...state,
        absencesPopupShown: action.absencesPopupShown,
      }
    case 'CHANGE_SUMMARY_POPUP_SHOWN':
      return {
        ...state,
        summaryPopupShown: action.summaryPopupShown,
      }
    case 'CHANGE_ABSENCES_SHOWN':
      return { ...state, absencesShown: action.absencesShown }
    case 'TOGGLE_MENU':
      return { ...state, isMenuVisible: !state.isMenuVisible }
    case 'CHANGE_MONTH_LOCK_FEATURE_ENABLED':
      return { ...state, monthLockFeatureEnabled: true }
    default:
      return state
  }
}
