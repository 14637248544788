import React from 'react'
import classNames from 'classnames'
import { formatDayMonthYear } from '../lib/date'
import { EmployeeHolidayStatus, EmployeeHolidayStatusUS, FloatingHolidaySummary } from '../lib/domain'
import { HTable, InfoIcon } from './common'
import styles from './HolidayTable.module.css'
import { SettingsState } from '../lib/reducers/settings'

interface FloatingHolidaysTableProps {
  locale: SettingsState['locale']
  floatingHolidaySummary: FloatingHolidaySummary
}

export const FloatingHolidaysTable = ({ locale, floatingHolidaySummary }: FloatingHolidaysTableProps) => {
  const translations = locale.texts.vacations

  const { checkpointDay, usedDays, plannedDays, availableDaysAtCheckpoint, remainingDays, earnedDays, totalToEarn } =
    floatingHolidaySummary
  const header = [
    translations.floatingHolidays,
    <InfoIcon key='floating-holidays-info-button' tooltip={translations.floatingHolidaysTooltip} />,
  ]

  return (
    <HTable data-test='floating-holidays-table' header={header}>
      <tr>
        <td>{translations.balance.replace('$CHECKPOINT_DAY$', formatDayMonthYear(locale)(checkpointDay))}</td>
        <td>{availableDaysAtCheckpoint}</td>
      </tr>
      <tr>
        <td>{translations.earnedThisYear}</td>
        <td>
          {earnedDays} / {totalToEarn}
        </td>
      </tr>
      <tr className={styles.separatorLarge}>
        <td>{translations.used}</td>
        <td>{usedDays}</td>
      </tr>
      <tr>
        <td>{translations.planned}</td>
        <td>{plannedDays}</td>
      </tr>
      <tr className={classNames(styles.separatorSmall)}>
        <td>
          <b>{translations.remainingBalance}</b>
        </td>
        <td>{remainingDays - plannedDays}</td>
      </tr>
    </HTable>
  )
}

export function getHolidaysForDisplay(holidayCheckpoint: EmployeeHolidayStatus): EmployeeHolidayStatusUS {
  const { base: transferred, earned, totalToEarnThisYear } = holidayCheckpoint.workdays
  return {
    ...holidayCheckpoint,
    workdays: {
      ...holidayCheckpoint.workdays,
      totalEarned: transferred + earned,
      availableByEndOfYear: transferred + totalToEarnThisYear,
    },
  }
}

interface HolidayTableHeaderPropsUSA {
  currentHolidays: EmployeeHolidayStatusUS
  showAvailableHolidays: boolean
  locale: SettingsState['locale']
}

export const getHolidayTableHeaderUSA = ({
  currentHolidays,
  showAvailableHolidays,
  locale,
}: HolidayTableHeaderPropsUSA) => {
  const translations = locale.texts.vacations
  const holidays = getHolidaysForDisplay(currentHolidays)

  const headerVacationsText = showAvailableHolidays
    ? translations.vacations
    : `${translations.vacations} ${translations.from.replace('$', formatDayMonthYear(locale)(holidays.baseDay))}`

  return [headerVacationsText, <InfoIcon key='vacations-info-button' tooltip={translations.vacationsTooltipUSA} />]
}

interface HolidayTableContentsUSAProps {
  currentHolidays: EmployeeHolidayStatus
  locale: SettingsState['locale']
  showRemainingHolidays: boolean
  showAvailableHolidays: boolean
}

export const getHolidayTableContentsUSA: React.FC<HolidayTableContentsUSAProps> = ({
  currentHolidays,
  locale,
  showAvailableHolidays,
  showRemainingHolidays,
}) => {
  const translations = locale.texts.vacations
  const holidays = getHolidaysForDisplay(currentHolidays)
  const { planned, used, earned, base, total, totalToEarnThisYear } = holidays.workdays

  return (
    <>
      {showAvailableHolidays && (
        <>
          <tr>
            <td>
              <span>
                {translations.balance.replace('$CHECKPOINT_DAY$', formatDayMonthYear(locale)(holidays.baseDay))}
              </span>
            </td>
            <td data-test='balance'>{base}</td>
          </tr>
          <tr>
            <td>{translations.earnedThisYear}</td>
            <td data-test='earned'>
              {earned} / {totalToEarnThisYear}
            </td>
          </tr>
        </>
      )}
      <tr className={styles.separatorLarge}>
        <td>{translations.used}</td>
        <td data-test='used'>{used}</td>
      </tr>
      <tr>
        <td>{translations.planned}</td>
        <td data-test='planned'>{planned}</td>
      </tr>
      {showRemainingHolidays && (
        <>
          <tr className={styles.separatorSmall}>
            <td>
              <b>{translations.remainingBalance}</b>
            </td>
            <td data-test='remaining'>{total}</td>
          </tr>
        </>
      )}
    </>
  )
}
