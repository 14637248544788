import React from 'react'
import classnames from 'classnames'

import styles from './css/RoundedButton.module.css'

interface RoundedButtonProps {
  children: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  'data-test'?: string
  disabled?: boolean
}

const RoundedButton = ({
  children,
  className,
  onClick,
  'data-test': dataTest,
  disabled = false,
}: RoundedButtonProps) => (
  <button
    data-test={dataTest}
    className={classnames(styles.roundedButton, className)}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export default RoundedButton
