import Fuse from 'fuse.js'
import _ from 'lodash'

const generateHighlightsFromIndices = (value, highlightIndices) => {
  if (highlightIndices.length === 0) return [{ value, isHighlighted: false }]

  const highlights = []
  if (highlightIndices[0][0] !== 0) {
    highlights.push({ value: value.substring(0, highlightIndices[0][0]), isHighlighted: false })
  }

  for (let i = 0; i < highlightIndices.length; i++) {
    const [start, end] = highlightIndices[i]
    highlights.push({ value: value.substring(start, end + 1), isHighlighted: true })

    if (i < highlightIndices.length - 1) {
      highlights.push({ value: value.substring(end + 1, highlightIndices[i + 1][0]), isHighlighted: false })
    }
  }

  const [, lastIndicesEnd] = highlightIndices[highlightIndices.length - 1]
  if (lastIndicesEnd !== value.length) {
    highlights.push({ value: value.substring(lastIndicesEnd + 1), isHighlighted: false })
  }

  return highlights
}

export const fuzzy = (items, value, opts = {}) => {
  if (value.length === 0) {
    return items.map((item) => ({ original: item, highlights: generateHighlightsFromIndices(item.name, []) }))
  }

  const searcher = new Fuse(items, {
    includeMatches: true,
    threshold: 0.4,
    ...opts,
  })

  return searcher.search(value).map((match) => {
    const item = typeof match.item === 'number' ? items[match.item] : match.item

    return {
      original: item,
      highlights: generateHighlightsFromIndices(item[match.matches[0].key], match.matches[0].indices),
    }
  })
}
