import React from 'react'
import _ from 'lodash'
import { formatDayMonthYear } from '../lib/date'
import { HTable } from './common'
import { getHolidayTableContentsUSA, getHolidayTableHeaderUSA } from './usa'
import { getHolidayTableContentsNetherlands } from './netherlands'
import { EmployeeHolidayStatus, HolidayStatus, HoursContractSeason, isFIHolidays, isUSHolidays } from '../lib/domain'
import { Locale } from '../lib/reducers/settings'
import { NL_EMPLOYEE_HANDBOOK_LINK } from '../lib/i18n'
export { FloatingHolidaysTable } from './usa'

type HolidayTableProps = {
  locale: Locale
  currentHolidays: HolidayStatus
  contract: HoursContractSeason
}

export const HolidayTable: React.FC<HolidayTableProps> = ({ locale, currentHolidays, contract }) => {
  if (!currentHolidays || !contract) {
    return null
  }

  const personLogsHours = contract.type !== 'not_required'

  const showAvailableHolidays = personLogsHours
  const showRemainingHolidays = personLogsHours

  const headerVacationsText = showAvailableHolidays
    ? locale.texts.vacations.vacations
    : `${locale.texts.vacations.vacations} ${locale.texts.vacations.from.replace(
        '$',
        formatDayMonthYear(locale)(currentHolidays.baseDay)
      )}`

  const getHeader = () => {
    if (personLogsHours && isFIHolidays(currentHolidays)) {
      return [headerVacationsText, locale.texts.vacations.workdays, locale.texts.vacations.saturdays].map(_.capitalize)
    } else if (personLogsHours && isUSHolidays(currentHolidays)) {
      return getHolidayTableHeaderUSA({
        currentHolidays,
        showAvailableHolidays,
        locale,
      })
    } else {
      return [headerVacationsText, locale.texts.vacations.workdays].map(_.capitalize)
    }
  }

  const footerLink = (() => {
    if (contract.company.country === 'NL') {
      return {
        href: NL_EMPLOYEE_HANDBOOK_LINK,
        text: locale.texts.vacations.readInAMSHandbook,
      }
    }

    return null
  })()

  return (
    <HTable
      data-test='holidayTable'
      header={getHeader()}
      footer={
        footerLink && (
          <a target='_blank' rel='noopener noreferrer' href={footerLink.href}>
            {footerLink.text}
          </a>
        )
      }
    >
      {(() => {
        if (contract.company.country === 'NL') {
          return getHolidayTableContentsNetherlands({
            showAvailableHolidays,
            showRemainingHolidays,
            currentHolidays,
            contract,
            locale,
          })
        }

        if (contract.company.country === 'US') {
          return getHolidayTableContentsUSA({
            showAvailableHolidays,
            showRemainingHolidays,
            currentHolidays,
            locale,
          })
        }

        return getHolidayTableContents({
          showAvailableHolidays,
          showRemainingHolidays,
          isFinnishHolidays: isFIHolidays(currentHolidays),
          locale,
          currentHolidays,
        })
      })()}
    </HTable>
  )
}

function getHolidayTableContents({
  showAvailableHolidays,
  showRemainingHolidays,
  isFinnishHolidays,
  locale,
  currentHolidays,
}: {
  showAvailableHolidays: boolean
  showRemainingHolidays: boolean
  isFinnishHolidays: boolean
  locale: Locale
  currentHolidays: EmployeeHolidayStatus
}) {
  return (
    <>
      {showAvailableHolidays && (
        <tr>
          <td>
            {locale.texts.vacations.available +
              ' ' +
              locale.texts.vacations.availableDateStr.replace('$', formatDayMonthYear(locale)(currentHolidays.baseDay))}
          </td>
          <td data-test='availableHolidays'>{currentHolidays.workdays.base + currentHolidays.workdays.agreed}</td>
          {isFinnishHolidays && (
            <td data-test='availableHolidays-withSats'>
              {currentHolidays.withSats.base + currentHolidays.withSats.agreed}
            </td>
          )}
        </tr>
      )}
      {currentHolidays.workdays.earned > 0 && (
        <tr>
          <td>{locale.texts.vacations.earned}</td>
          <td>+{currentHolidays.workdays.earned}</td>
        </tr>
      )}
      <tr>
        <td>{locale.texts.vacations.used}</td>
        <td data-test='usedHolidays'>{currentHolidays.workdays.used}</td>
        {isFinnishHolidays && <td data-test='usedHolidays-withSats'>{currentHolidays.withSats.used}</td>}
      </tr>
      {currentHolidays.workdays.planned > 0 && (
        <tr>
          <td>{locale.texts.vacations.planned}</td>
          <td data-test='plannedHolidays'>{currentHolidays.workdays.planned}</td>
          {isFinnishHolidays && <td data-test='plannedHolidays-withSats'>{currentHolidays.withSats.planned}</td>}
        </tr>
      )}
      {showRemainingHolidays && (
        <>
          <tr>
            <td>{locale.texts.vacations.remaining}</td>
            <td data-test='remainingHolidays'>{currentHolidays.workdays.total}</td>
            {isFinnishHolidays && <td data-test='remainingHolidays-withSats'>{currentHolidays.withSats.total}</td>}
          </tr>
        </>
      )}
    </>
  )
}
