import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { IS_E2E_TEST, environment } from './lib/config'

const Head = (): JSX.Element => {
  const env = environment()

  useEffect(() => {
    if (IS_E2E_TEST) {
      // @ts-expect-error
      window['__REDUX_DEVTOOLS_EXTENSION__'] = window.parent['__REDUX_DEVTOOLS_EXTENSION__']
      // @ts-expect-error
      window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] = window.parent['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    }
  }, [])

  return (
    <Helmet>
      <link rel='shortcut icon' href={`/hours-logo-${env}.png`} />
      <link rel='shortcut icon' sizes='32x32' href={`/hours-favicon-${env}.png`} />
      <link rel='icon' sizes='192x192' href={`/hours-logo-${env}.png`} />
    </Helmet>
  )
}

export default Head
