import React from 'react'
import { connect } from 'react-redux'
import { formatDayMonthYear } from '../lib/date'
import { CountryID } from '../lib/domain'
import { LoggedInUser } from '../lib/reducers/user'
import { SettingsState } from '../lib/reducers/settings'
import { RootState } from '../lib/store'
import EmployeeTable, { EmployeeTableItem } from './EmployeeTable'
import { TableHeading } from './SortableTable'

export const userHasRights = (loggedInUser: LoggedInUser) =>
  loggedInUser.isAdmin || (loggedInUser.isCountryManager && loggedInUser.managedCountries.some(({ id }) => id === 'US'))

const tableHeadings = (country: CountryID, locale: SettingsState['locale']): TableHeading[] => {
  return [
    {
      title: locale.texts.admin.reaktorIncFloatingHolidays.balance,
      sortPath: 'floatingHolidays.availableDaysAtCheckpoint',
    },
    {
      title: locale.texts.admin.reaktorIncFloatingHolidays.earned,
      sortPath: 'floatingHolidays.earnedDays',
    },
    {
      title: locale.texts.admin.reaktorIncFloatingHolidays.usedDays,
      sortPath: 'floatingHolidays.usedDays',
    },
    {
      title: locale.texts.admin.reaktorIncFloatingHolidays.remainingDays,
      sortPath: 'floatingHolidays.remainingDays',
    },
    {
      title: locale.texts.admin.reaktorIncFloatingHolidays.plannedDays,
      sortPath: 'floatingHolidays.plannedDays',
    },
  ]
}

const fieldsForEmployee = (employeeData: EmployeeTableItem, locale: SettingsState['locale']): string[] => {
  const floatingHolidays = employeeData.floatingHolidays
  const balance = floatingHolidays?.availableDaysAtCheckpoint.toString() ?? ''
  const balanceDate = floatingHolidays?.checkpointDay ? formatDayMonthYear(locale)(floatingHolidays?.checkpointDay) : ''
  const earnedDays = floatingHolidays?.earnedDays.toString() ?? ''
  const totalToEarn = floatingHolidays?.totalToEarn.toString() ?? ''
  return [
    `${balance} (${balanceDate})`,
    `${earnedDays}/${totalToEarn}`,
    floatingHolidays?.usedDays.toString() ?? '',
    floatingHolidays?.remainingDays.toString() ?? '',
    floatingHolidays?.plannedDays.toString() ?? '',
  ]
}

const IncFloatingHolidaysView: React.FC<{
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}> = ({ loggedInUser, settings }) => {
  const { locale } = settings
  const { managedCountries } = loggedInUser
  const selectableCountries = managedCountries.filter(({ id }) => id === 'US')

  return (
    <EmployeeTable
      title={locale.texts.admin.reaktorIncFloatingHolidays.title}
      selectableCountries={selectableCountries}
      userHasRights={userHasRights}
      tableHeadingsForCountry={tableHeadings}
      toDisplayRow={fieldsForEmployee}
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(IncFloatingHolidaysView)
