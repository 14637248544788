import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames/bind'
import styles from '../css/UnlockedHoursTable.module.css'
import { Dispatch } from 'redux'
import { fetchUsersWithUnlockedHoursPerMonth } from '../lib/hoursApi'
import { AppDispatch, RootState } from '../lib/store'
import { formatMonth, parseISODate } from '../lib/date'
import _ from 'lodash'
import { Country, EmployeeName } from '../lib/domain'
import { connect } from 'react-redux'
import adminClientStyles from '../css/AdminClient.module.css'
import SortableTable, { SortableTableHeader, sortItemsByPath, useSortingByPath } from './SortableTable'
import { Locale } from '../lib/reducers/settings'

const css = classnames.bind(styles)

type UsersWithUnlockedHoursTableProps = {
  dispatch: AppDispatch
  usersWithUnlockedHours: RootState['data']['adminClient']['usersWithUnlockedHours']
  settings: RootState['settings']
  countries: Country[]
}

type UsersWithUnlockedHoursMonthTotals = {
  [key: string]: number
}

type UserWithCountry = {
  name: string
  country: string
  username: string
}

type EmployeeOrExternal = 'employee' | 'external'

const fetchUsersWithUnlockedHours = async (dispatch: Dispatch) => {
  try {
    const response = await fetchUsersWithUnlockedHoursPerMonth()
    const usersWithUnlockedHours = Object.keys(response)
      .map((month) => ({
        month,
        countryData: response[month],
      }))
      .sort((a, b) => a.month.localeCompare(b.month))
    dispatch({ type: 'UPDATE_USERS_WITH_UNLOCKED_HOURS', usersWithUnlockedHours })
  } catch (e) {
    console.error('Could not get users with unlocked hours.', e)
  }
}
const UsersWithUnlockedHoursTable: React.FC<UsersWithUnlockedHoursTableProps> = ({
  dispatch,
  usersWithUnlockedHours,
  countries,
  settings: { locale },
}) => {
  const sortedCountries = _.sortBy(countries, 'id')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [employeeOrExternal, setEmployeeOrExternal] = useState<EmployeeOrExternal>('employee')
  const countryHeading = { title: locale.texts.admin.unlockedHoursStats.country }
  const monthHeadings = usersWithUnlockedHours.map(({ month }) => {
    const date = parseISODate(`${month}-01`)
    return { title: _.capitalize(formatMonth(locale)(date)), originalKey: month }
  })
  const headings = [countryHeading, ...monthHeadings]

  useEffect(() => {
    void fetchUsersWithUnlockedHours(dispatch)
  }, [])

  const matchesSelectedOption = (user: EmployeeName) =>
    employeeOrExternal === 'employee' ? !user.external : user.external

  const totalsForMonths = usersWithUnlockedHours.reduce((totals, monthData) => {
    const countryData = monthData.countryData

    if (countryData) {
      const totalHours = Object.keys(countryData).reduce((acc, countryId) => {
        const usersForCountry = countryData[countryId] || []

        if (usersForCountry) {
          return acc + (usersForCountry.filter(matchesSelectedOption).length as number)
        }

        return acc
      }, 0)

      return { ...totals, [monthData.month]: totalHours }
    }
    return totals
  }, {} as UsersWithUnlockedHoursMonthTotals)

  const usersForSelectedCountry = useMemo(() => {
    const selectedUniqueUsers = new Set<string>()

    usersWithUnlockedHours.forEach((monthData) => {
      const countriesData = monthData.countryData

      if (countriesData) {
        Object.keys(countriesData)
          .filter((countryId) => {
            if (!selectedCountry) {
              return true
            } else {
              return countryId === selectedCountry
            }
          })
          .map((countryId) => {
            const usersForCountry = countriesData[countryId]

            if (usersForCountry) {
              usersForCountry
                .filter(matchesSelectedOption)
                .map((user) =>
                  JSON.stringify({
                    name: `${user.lastName} ${user.firstName}`,
                    country: countryId,
                    username: user.username,
                  })
                )
                .forEach(selectedUniqueUsers.add, selectedUniqueUsers)
            }
          })
      }
    })

    return [...selectedUniqueUsers].sort()
  }, [usersWithUnlockedHours, selectedCountry, employeeOrExternal])

  return (
    <div className={adminClientStyles.container}>
      <div className={styles.employeeOrExternalOptionContainer}>
        <div>
          <input
            type='radio'
            name='employeeOrExternal'
            value='employee'
            id='option-employee'
            checked={employeeOrExternal === 'employee'}
            onChange={() => setEmployeeOrExternal('employee')}
          />
          <label className={styles.employeeOrExternalOptionLabel} htmlFor='option-employee'>
            {locale.texts.employees}
          </label>
        </div>
        <div>
          <input
            type='radio'
            name='employeeOrExternal'
            value='external'
            id='option-external'
            checked={employeeOrExternal === 'external'}
            onChange={() => setEmployeeOrExternal('external')}
          />
          <label className={styles.employeeOrExternalOptionLabel} htmlFor='option-external'>
            {locale.texts.subcontractors}
          </label>
        </div>
      </div>
      <table className={css('unlocked-hours-table')} data-test='unlockedHoursTable'>
        <thead>
          <tr>
            {headings.map(({ title }) => (
              <th key={title}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedCountries.map((country) => (
            <tr
              className={css({ 'unlocked-hours-table-row-selected': selectedCountry === country.id })}
              key={country.id}
              onClick={() => setSelectedCountry(country.id)}
            >
              <td className={css('unlocked-hours-table-cell')}>{country.id}</td>
              {usersWithUnlockedHours.map((monthData) => {
                const countryDataForMonth = monthData.countryData
                if (countryDataForMonth) {
                  const usersForCountry = (countryDataForMonth[country.id] ?? []).filter(matchesSelectedOption)

                  return (
                    <td
                      key={monthData.month}
                      className={css(
                        'unlocked-hours-table-cell',
                        usersForCountry.length > 0 && styles.unlockedHoursTableCellWarning
                      )}
                    >
                      {usersForCountry.length}
                    </td>
                  )
                } else {
                  return <td>0</td>
                }
              })}
            </tr>
          ))}
          <tr
            data-test='unlockedHoursTableTotals'
            className={css({ 'unlocked-hours-table-row-selected': selectedCountry === '' })}
          >
            <td className={css('unlocked-hours-table-cell')} onClick={() => setSelectedCountry('')}>
              {locale.texts.admin.unlockedHoursStats.total}
            </td>
            {Object.keys(totalsForMonths).map((month) => (
              <td
                key={month}
                className={css('unlocked-hours-table-cell', {
                  'unlocked-hours-table-cell-warning': totalsForMonths[month],
                })}
              >
                {totalsForMonths[month]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <UserTable users={Array.from(usersForSelectedCountry).map((item) => JSON.parse(item))} locale={locale} />
    </div>
  )
}

const UserTable: React.FC<{ users: UserWithCountry[]; locale: Locale }> = ({ users, locale }) => {
  const [sorting, toggleSortByPath] = useSortingByPath()

  const sortedUsers = sortItemsByPath(sorting, users)

  return (
    <div data-test='usersWithUnlockedHoursTable'>
      <SortableTable className={css('unlocked-hours-table')}>
        <SortableTableHeader
          headings={[
            { title: locale.texts.admin.unlockedHoursStats.name, sortPath: 'name' },
            { title: locale.texts.admin.unlockedHoursStats.country, sortPath: 'country' },
          ]}
          sortingState={sorting}
          toggleSortByPath={toggleSortByPath}
        />
        {users.length === 0 ? (
          <tbody>
            <tr>
              <td>
                <span className={css('users-table-empty')}>
                  {locale.texts.admin.unlockedHoursStats.noUsersForCountry}
                </span>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {sortedUsers.map((user) => (
              <tr key={user.name}>
                <td className={css('unlocked-hours-table-cell')}>
                  <a href={`https://${window.location.host}/?uid=${user.username}`}>{user.name}</a>
                </td>
                <td className={css('unlocked-hours-table-cell')}>{user.country}</td>
              </tr>
            ))}
          </tbody>
        )}
      </SortableTable>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    usersWithUnlockedHours: state.data.adminClient.usersWithUnlockedHours,
    settings: state.settings,
  }
}
export default connect(mapStateToProps)(UsersWithUnlockedHoursTable)
