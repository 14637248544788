import React from 'react'

const HelpIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='6.25' stroke='#151515' strokeWidth='1.5' />
    <path
      d='M12.1796 8.34995C10.9096 8.34995 10.0496 9.22995 9.79961 10.36L11.0296 10.55C11.1096 10.09 11.5096 9.51995 12.1896 9.51995C12.7496 9.51995 13.1696 9.86995 13.1696 10.34C13.1696 10.75 12.8296 11.06 12.3096 11.41C11.7896 11.76 11.2796 12.13 11.2796 12.83V13.37H12.4596V12.99C12.4596 12.49 12.9496 12.23 13.4696 11.83C13.9796 11.44 14.4496 10.92 14.4496 10.25C14.4496 9.04995 13.3996 8.34995 12.1796 8.34995ZM11.0296 14.92C11.0296 14.46 11.3996 14.1 11.8696 14.1C12.3396 14.1 12.7096 14.46 12.7096 14.92C12.7096 15.39 12.3396 15.76 11.8696 15.76C11.3996 15.76 11.0296 15.39 11.0296 14.92Z'
      fill='#151515'
    />
  </svg>
)

export default HelpIcon
