import _ from 'lodash'
import { hourDifference } from './time'
import { resolvePolicyGroup, isSupportedPolicy, requestInitialMetadata } from '../PolicyGroup'

// Refer to fi.reaktor.hours.common.model.AbsenceCodes in Hours backend
export const autofilledCodes = {
  'absent-flexitime': 0,
  'absent-hourlyworker': 0,
  'absent-paternal-leave': 'FULL_HOURS',
  'absent-paternal-leave-payed-3-weeks': 'FULL_HOURS',
  'absent-paternal-leave-unpaid': 'FULL_HOURS',
  'absent-paternal-month': 'FULL_HOURS',
  'absent-vacation': 'FULL_HOURS',
  'poissa-isäkuukausi': 'FULL_HOURS',
  'poissa-isyysvapaa': 'FULL_HOURS',
  'poissa-isyysvapaa-palkallinen-3vkoa': 'FULL_HOURS',
  'poissa-isyysvapaa-palkaton': 'FULL_HOURS',
  'poissa-liukumavapaa': 0,
  'poissa-tuntityöntekijä': 0,
  'poissa-vuosiloma': 'FULL_HOURS',
  timeshift: 0,
}

// some hour codes have a fixed hour amount (eg. absent-flexitime -> 0h)
const addAutofilledHoursBasedOnCode = (contract, dayData) => (updateDiff) => {
  const { hourCode, noHoursAutoFill } = updateDiff
  const { day } = dayData
  const isAutofilled = Object.keys(autofilledCodes).includes(hourCode)
  if (noHoursAutoFill || !isAutofilled) {
    return updateDiff
  }

  let autofill = autofilledCodes[hourCode]
  if (autofill === 'FULL_HOURS') {
    // not sure what to do here if contract doesnt exist :/
    autofill = contract ? contract.expectedWorkingHoursPerDay(day) : 'err'
  }

  return { ...updateDiff, hours: String(autofill), startTime: '', endTime: '' }
}

// autofills hours of an entry to be rest of the days hours if hour code is written but entry doenst have hours
const addAutofilledHoursBasedOnPreviousEntries = (editContext, dayData, contract) => (updateDiff) => {
  const { day } = dayData
  const entry = dayData.entries[editContext.indexInDay] || {}
  const hasEntryHours = !isNaN(parseFloat(updateDiff.hours)) || !isNaN(parseFloat(entry.hours))

  if (!hasEntryHours && updateDiff.hourCode) {
    const otherEntries = [
      ...dayData.entries.slice(0, editContext.indexInDay),
      ...dayData.entries.slice(editContext.indexInDay + 1),
    ]

    const otherEntriesHoursSum = _.sumBy(otherEntries, (e) => parseFloat(e.hours) || 0)
    const contractExpectedHours = contract ? contract.expectedWorkingHoursPerDay(day) : 0
    const autoFillHours = Math.max(0, contractExpectedHours - otherEntriesHoursSum)

    if (autoFillHours > 0) {
      return { ...updateDiff, hours: autoFillHours }
    }
  }

  return updateDiff
}

// calculates hours when start or end time is changed
const addAutofilledHoursBasedOnStartAndEndTimes = (editContext, dayData) => (updateDiff) => {
  const entry = dayData.entries[editContext.indexInDay] || {}
  const updatedEntry = { ...entry, ...updateDiff }

  const { startTime, endTime } = updatedEntry
  if (startTime && endTime && (updateDiff.startTime || updateDiff.endTime)) {
    const hours = hourDifference(startTime, endTime)
    if (!isNaN(hours)) {
      return { ...updateDiff, hours }
    }
  }

  return updateDiff
}

// autofills start time if end time is written but start time is not. the new start time
// will be previous entries' end time
const addAutofilledStartTime = (editContext, dayData) => (updateDiff) => {
  const entry = dayData.entries[editContext.indexInDay] || {}
  const hasStartTime = updateDiff.startTime || entry.startTime
  if (editContext.indexInDay > 0 && updateDiff.endTime && !hasStartTime) {
    const previousEntry = dayData.entries[editContext.indexInDay - 1]
    const hasPreviousEntryEndTime = Boolean(previousEntry.endTime)
    if (hasPreviousEntryEndTime) {
      return { ...updateDiff, startTime: previousEntry.endTime }
    }
  }

  return updateDiff
}

const addAutofilledHourCodePolicy = (editContext, dayData, contract, hourCodes, hourCodePolicies) => (updateDiff) => {
  // intercept modifications to hourCode. Ensures entry.policyMetadata is set correctly.
  const { policyMetadata } = dayData.entries[editContext.indexInDay] || {}
  const newPolicyGroup = resolvePolicyGroup(updateDiff, editContext.day, hourCodes, hourCodePolicies)
  if (!policyMetadata && newPolicyGroup && isSupportedPolicy(contract, newPolicyGroup, editContext.day))
    return { ...updateDiff, policyMetadata: requestInitialMetadata(newPolicyGroup, contract) }
  else return updateDiff
}

export const addAutofillsToUpdateDiff = (editContext, updateDiff, dayData, contract, hourCodes, hourCodePolicies) => {
  const processedUpdateDiff = _.flow([
    addAutofilledHoursBasedOnCode(contract, dayData),
    addAutofilledHoursBasedOnPreviousEntries(editContext, dayData, contract),
    addAutofilledHoursBasedOnStartAndEndTimes(editContext, dayData),
    addAutofilledStartTime(editContext, dayData),
    addAutofilledHourCodePolicy(editContext, dayData, contract, hourCodes, hourCodePolicies),
  ])(updateDiff)

  return processedUpdateDiff
}
