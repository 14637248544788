import React from 'react'
import styles from '../css/AccessForbiddenView.module.css'
import { RootState } from '../lib/store'

const AccessForbiddenView: React.FC<{ locale: RootState['settings']['locale'] }> = ({ locale }) => {
  return (
    <div className={styles.container} data-test='accessForbidden'>
      <h1>{locale.texts.admin.accessForbidden}</h1>
    </div>
  )
}

export default AccessForbiddenView
