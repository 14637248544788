import React from 'react'
import classnames from 'classnames'

import { autofilledCodes } from './lib/autofills'

import NonOverwritingInput from './NonOverwritingInput'

import styles from './css/HoursInput.module.css'

const HoursInput = ({ entry, modifyEntry, className, validationError, inputRef, onFocus }) => {
  const { hours, hourCode } = entry
  // If the selected hourCode has autofill, disable this input.
  const disabled = hourCode in autofilledCodes

  return (
    <NonOverwritingInput
      data-test='hoursInput'
      className={classnames(className, styles.hoursInput, {
        validationError,
      })}
      ref={inputRef}
      value={hours}
      placeholder='h'
      type='text'
      onFocus={(e) => onFocus(e, inputRef)}
      onChange={(e) => {
        const input = e.target.value.trim().replace(/,/g, '.')
        let sanitizedHours = input.endsWith('.') ? input : parseFloat(input)
        if (isNaN(sanitizedHours)) sanitizedHours = ''
        modifyEntry({ hours: sanitizedHours })
      }}
      pattern='[0-9,.]+'
      inputMode='decimal'
      lang='en-150'
      disabled={disabled}
    />
  )
}

export default HoursInput
