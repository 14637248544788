import React from 'react'
import styles from './HolidayTable.module.css'
import { InfoIcon } from './common'
import { EmployeeHolidayStatus, EmployeeHolidayStatusNL, HoursContractSeason } from '../lib/domain'
import { Locale } from '../lib/reducers/settings'

export function getHolidayCheckpointForDisplay(holidayCheckpoint: EmployeeHolidayStatus): EmployeeHolidayStatusNL {
  const { base: transferred, earned, totalToEarnThisYear, total } = holidayCheckpoint.workdays
  return {
    ...holidayCheckpoint,
    workdays: {
      ...holidayCheckpoint.workdays,
      total: total + (totalToEarnThisYear - earned),
      availableByEndOfYear: transferred + totalToEarnThisYear,
    },
  }
}

export const getHolidayTableContentsNetherlands: React.FC<{
  showAvailableHolidays: boolean
  showRemainingHolidays: boolean
  locale: Locale
  contract: HoursContractSeason
  currentHolidays: EmployeeHolidayStatus
}> = ({ currentHolidays, contract, locale, showAvailableHolidays, showRemainingHolidays }) => {
  // All days that will be earned this year are available to be used any time, so
  // we include them in the totals.
  const {
    workdays: { base, earned, planned, used, total, availableByEndOfYear, totalToEarnThisYear },
  } = getHolidayCheckpointForDisplay(currentHolidays)

  const year = new Date().getFullYear()

  const tooltipText = contract.isFixedTerm
    ? locale.texts.vacations.earnedAvailableTooltipNLFixedTerm.replace(
        /\$AVAILABLE\$/g,
        availableByEndOfYear?.toString()
      )
    : locale.texts.vacations.earnedAvailableTooltipNL
        .replace(/\$AVAILABLE\$/g, availableByEndOfYear?.toString())
        .replace(/\$YEAR\$/g, year?.toString())
        .replace(/\$TRANSFERRED\$/g, base?.toString())
        .replace(/\$YEARLY_SUM\$/g, totalToEarnThisYear?.toString())
        .replace(/\$EARNED\$/g, earned?.toString())

  const availableLabel = contract.isFixedTerm
    ? locale.texts.vacations.availableOnBeginningOfContract
    : `${locale.texts.vacations.availableBeginningOf} ${year}`

  return (
    <>
      {showAvailableHolidays && (
        <>
          <tr role='tooltip' className={styles.vacationsTooltip} data-tooltip={tooltipText}>
            <td>
              <span>{availableLabel}</span>
              <InfoIcon size={20} />
            </td>
            <td>{availableByEndOfYear}</td>
          </tr>
        </>
      )}
      <tr>
        <td>{locale.texts.vacations.used}</td>
        <td>{used}</td>
      </tr>
      <tr>
        <td>{locale.texts.vacations.planned}</td>
        <td>{planned}</td>
      </tr>
      {showRemainingHolidays && (
        <tr>
          <td>{locale.texts.vacations.remaining}</td>
          <td>{total}</td>
        </tr>
      )}
    </>
  )
}
