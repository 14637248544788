import React, { useEffect, useState, useCallback } from 'react'

import classnames from 'classnames'
import Calendar from './Calendar'
import calendarStyles from './css/Calendar.module.css' // FIXME: dont depend on another component's styles, or extract common styles elsewhere
import { formatISODayOfWeek, getWorkWeekISODaysOfWeek, today } from './lib/date'
import TaskSummary from './TaskSummary'
import CalendarIcon from './CalendarIcon'
import CalendarGuide from './CalendarGuide'

const SUM_MODE_OPTIONS = {
  TOTAL: 'totalHours',
  FLEX: 'flex',
  SUMMARY: 'taskSummary',
}

const getDefaultSumModeBasedOnContract = (contract) => {
  if (contract) {
    // eslint-disable-next-line
    switch (contract.type) {
      case 'hourly':
      case 'subcontractor':
      case 'not_required':
        return SUM_MODE_OPTIONS.TOTAL
    }
  }

  return SUM_MODE_OPTIONS.FLEX
}

const Summary = ({
  publicHolidays,
  serverDays,
  contracts,
  startDate,
  locale,
  onDayClicked,
  userExpectedHours,
  calendarRef,
  daysWithErrors,
}) => {
  const currentContract = contracts.find((c) => c.start <= today() && (!c.finish || c.finish >= today()))
  const country = (currentContract && currentContract.company && currentContract.company.country) || 'FI'

  const [sumMode, setSumMode] = useState(
    localStorage.getItem('settings.calendarSumMode') || getDefaultSumModeBasedOnContract(currentContract)
  )

  const hasErrors = daysWithErrors.length > 0

  const onSumModeChanged = useCallback(
    (e) => {
      const newSumMode = e.target.value
      setSumMode(newSumMode)
      localStorage.setItem('settings.calendarSumMode', newSumMode)
    },
    [setSumMode]
  )

  useEffect(() => {
    if (!localStorage.getItem('settings.calendarSumMode')) {
      setSumMode(getDefaultSumModeBasedOnContract(currentContract))
    }
  }, [currentContract])

  if (!currentContract) {
    return null
  }

  return (
    <div data-test='sidebar-calendar' className={classnames(calendarStyles.calendar, 'app__sidebar-summary')}>
      <div className={classnames(calendarStyles.headers, calendarStyles.summaryRow)}>
        <CalendarIcon />
        {getWorkWeekISODaysOfWeek(country).map((day) => (
          <span key={day} className={calendarStyles.weekDay}>
            {sumMode === SUM_MODE_OPTIONS.SUMMARY ? '' : formatISODayOfWeek(locale)(day)}
          </span>
        ))}
        <select
          data-test='calendar-sumModeSelect'
          className={calendarStyles.sumModeSelect}
          value={sumMode}
          onChange={onSumModeChanged}
        >
          <option value={SUM_MODE_OPTIONS.TOTAL}>{locale.texts.total.substring(0, 3)}</option>
          <option value={SUM_MODE_OPTIONS.FLEX}>+/-</option>
          <option value={SUM_MODE_OPTIONS.SUMMARY}>{locale.texts.summaryByProject}</option>
        </select>
      </div>
      {sumMode === SUM_MODE_OPTIONS.SUMMARY ? (
        <TaskSummary
          {...{
            serverDays,
            contracts,
            startDate,
            locale,
          }}
        />
      ) : (
        <>
          <Calendar
            ref={calendarRef}
            {...{
              publicHolidays,
              serverDays,
              contracts,
              startDate,
              locale,
              onDayClicked,
              userExpectedHours,
              sumMode,
              daysWithErrors,
            }}
          />
          <CalendarGuide country={country} locale={locale} hasErrors={hasErrors} />
        </>
      )}
    </div>
  )
}

export default Summary
