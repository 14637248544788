import { CountryID, HoursContractSeason } from './domain'

export const IS_E2E_TEST = 'Cypress' in window

export const environment = (): 'development' | 'test' | 'production' | 'test-ci' => {
  const { host } = window.location

  // Force production environment for e2e tests.
  // This is done to hide the "This is the development environment" banner.
  if (IS_E2E_TEST) return 'test-ci'

  switch (host) {
    case 'hours.reaktor.com':
      return 'production'
    case 'test.hours.reaktor.com':
      return 'test'
    default:
      return 'development'
  }
}

// might not be the most comprehensive, but should be enough for our supported platforms
export const IS_TOUCH_DEVICE = 'ontouchstart' in window

export const SERVER_SEND_DEBOUNCE_DELAY = 500

export const DEFAULT_WORKDAY_LENGTH_FI = 7.5

export const DEFAULT_WORKDAY_LENGTH_GLOBAL = 8

export const WORKDAY_LENGTH_OVERRIDES = new Map<CountryID, number>([['FI', DEFAULT_WORKDAY_LENGTH_FI]])

const WORKHOURS_LOW_LIMIT = 0.8 // same as in backend

export const getWorkdayLength = (country: CountryID): number =>
  WORKDAY_LENGTH_OVERRIDES.get(country) ?? DEFAULT_WORKDAY_LENGTH_GLOBAL

export const getWorkhoursLowLimit = (day: string, contract?: HoursContractSeason): number => {
  const workdayLength = contract ? contract.expectedWorkingHoursPerDay(day) : DEFAULT_WORKDAY_LENGTH_GLOBAL
  return WORKHOURS_LOW_LIMIT * workdayLength
}
