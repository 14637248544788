import _ from 'lodash'
import { WorkDay, WorkEntry, WorkEntryValidation } from './domain'

export const SUSPICIOUSLY_FEW_HOURS = 'suspiciouslyFewHours'

// A map of column names and associated error codes that are returned from the server.
export const entryValidationErrors = {
  startTime: ['invalidStartTime'],
  endTime: ['invalidEndTime'],
  hours: [
    SUSPICIOUSLY_FEW_HOURS,
    'suspiciouslyManyHours',
    'moreAbsenceThanWorkDayLength',
    'invalidHours',
    'hoursLongerThanPeriod',
    'wrongLengthFullDayAbsence',
    'invalidFlextime',
    'absenceLongerThanWorkDay',
    'absenceIncreasingFlextime',
  ],
  hourCode: [
    'unknownProject',
    'outsideProjectDuration',
    'unknownTask',
    'outsideTaskDuration',
    'absenceOnNotWorkDay',
    'alertOverlappingStandby',
  ],
  note: ['missingRequiredNote'],
} as const

export function clearEntryValidations(validation: WorkEntryValidation, diff: Partial<WorkEntry>) {
  const errorsToClear = Object.keys(diff).flatMap(
    (key) => entryValidationErrors[key as keyof typeof entryValidationErrors]
  )
  return Object.keys(validation).reduce((p: WorkEntryValidation, key) => {
    const k = key as keyof WorkEntryValidation
    if (!errorsToClear.includes(k)) {
      // eslint-disable-next-line no-param-reassign
      p[k] = validation[k]
    }
    return p
  }, {})
}

export function hasValidationErrors(dayData: WorkDay) {
  const { entries, validation } = dayData
  if (_.keys(validation).length > 0) {
    return true
  }
  return entries.some((entry) => entry.validation && !_.isEmpty(entry.validation))
}
