import _ from 'lodash'
import { startOfMonth, endOfMonth, subDays, addDays } from 'date-fns'
import { formatISO, formatISOMonth } from './date'

export const getElementVerticalScrollPosition = (elem) => {
  if (!elem) {
    return {
      documentTopOffset: 0,
      viewportTopOffset: 0,
    }
  }
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const clientTop = docEl.clientTop || body.clientTop || 0
  const documentOffset = box.top + scrollTop - clientTop

  return { documentTopOffset: Math.round(documentOffset), viewportTopOffset: box.top }
}

const findWorkingDayElement = (isoDay) => document.getElementsByClassName(`workingDay-${isoDay}`)[0]
const getIsoDayFromWorkingDayElement = (workingDay) => {
  const classNameWithDay = [...workingDay.classList].find((c) => c.startsWith('workingDay-'))
  return classNameWithDay && classNameWithDay.split('workingDay-')[1]
}

export const findDayListFocusedMonthAndScrollProgress = (scrollTop) => {
  const days = document.getElementsByClassName('workingDay')
  const day = [...days].find((element) => element.offsetTop + element.offsetHeight > scrollTop)
  if (day) {
    const isoDay = getIsoDayFromWorkingDayElement(day)

    // pretty hacksy way of skipping weekends
    const firstRenderedDayOfMonth =
      findWorkingDayElement(formatISO(startOfMonth(isoDay))) ||
      findWorkingDayElement(formatISO(addDays(startOfMonth(isoDay), 1))) ||
      findWorkingDayElement(formatISO(addDays(startOfMonth(isoDay), 2)))

    const lastRenderedDayOfMonth =
      findWorkingDayElement(formatISO(endOfMonth(isoDay))) ||
      findWorkingDayElement(formatISO(subDays(endOfMonth(isoDay), 1))) ||
      findWorkingDayElement(formatISO(subDays(endOfMonth(isoDay), 2)))

    if (isoDay && firstRenderedDayOfMonth && lastRenderedDayOfMonth) {
      const scrollPercentage =
        (scrollTop - firstRenderedDayOfMonth.offsetTop) /
        (lastRenderedDayOfMonth.offsetTop + lastRenderedDayOfMonth.offsetHeight - firstRenderedDayOfMonth.offsetTop)

      return {
        month: formatISOMonth(isoDay),
        monthScrollPercentage: _.clamp(scrollPercentage, 0, 1),
      }
    }
  }
}
