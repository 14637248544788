import React, { useMemo } from 'react'
import { getYear } from 'date-fns'
import { formatMonth, today } from './lib/date'
import _ from 'lodash'

import styles from './css/TaskSummary.module.css'
import { generateDisplayedMonths } from './lib/util'

const isExcluded = (hourCode) => {
  return ['poissa', 'sairaana', 'absent', 'sick'].some((s) => hourCode.includes(s))
}

const groupByProjectAndHours = (month, serverDays) => {
  let totalHours = 0

  const tasks = (month.weeks || [])
    .flatMap((week) => week.days)
    .map((isoDay) => serverDays[isoDay])
    .flatMap((day) => _.get(day, 'entries', []))
    .reduce((acc, { validation, hourCode, hours }) => {
      if (validation || isExcluded(hourCode)) return acc
      const [projectName, ...taskNameParts] = hourCode.split(/-/)
      const task = taskNameParts.length ? taskNameParts.join('-') : '(no task)'
      const workedHours = parseFloat(hours)
      totalHours += workedHours
      if (_.has(acc, [projectName, task])) {
        return _.update(acc, [projectName, task], (prev) => prev + workedHours)
      } else {
        return _.set(acc, [projectName, task], workedHours)
      }
    }, {})

  return {
    ...month,
    tasks,
    totalHours,
  }
}

const ProjectSummary = ({ name, tasks }) => {
  const taskList = _.toPairs(tasks)
  const shouldRenderTaskList = !(taskList.length === 1 && taskList[0][0] === '(no task)')
  return (
    <div>
      <div className={styles.titleRow}>
        <span>{name}</span>
        <span>{_.sum(taskList.map(_.last))}</span>
      </div>
      {shouldRenderTaskList &&
        taskList.map(([taskName, hours]) => {
          return (
            <div data-test='projectSummary-taskRow' key={taskName} className={styles.taskRow}>
              <span>{taskName}</span>
              <span>{hours}</span>
            </div>
          )
        })}
    </div>
  )
}

const TaskSummary = ({ serverDays, contracts, startDate, locale }) => {
  const currentContract = contracts.find((c) => c.start <= today() && (!c.finish || c.finish >= today()))
  const country = (currentContract && currentContract.company && currentContract.company.country) || 'FI'
  const months = useMemo(() => generateDisplayedMonths(startDate, today(), country).reverse(), [startDate, country])
  const monthsWithWorkHours = useMemo(
    () => months.map((month) => groupByProjectAndHours(month, serverDays)),
    [months, serverDays]
  )

  return (
    <div data-test='projectSummary-monthsContainer' className={styles.monthsContainer}>
      {monthsWithWorkHours.map(({ month, totalHours, tasks }) => {
        return (
          <div key={month} className={styles.monthContainer}>
            <div data-test='projectSummary-monthRow' className={styles.monthRow}>
              <span className={styles.month}>
                {formatMonth(locale)(month)} {getYear(month)}
              </span>
              <span className={styles.monthlyHours}>{totalHours}h</span>
            </div>
            <div>
              {_.toPairs(tasks).map(([project, projectTasks]) => {
                return <ProjectSummary key={project} name={project} tasks={projectTasks} />
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TaskSummary
