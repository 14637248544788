import React, { useEffect, useState } from 'react'
import styles from '../css/LeaveEditor.module.css'
import adminClientStyles from '../css/AdminClient.module.css'
import { EmployeeName, SpecialLeaveCheckpoint } from '../lib/domain'
import classnames from 'classnames'
import { SettingsState } from '../lib/reducers/settings'
import { fetchQuotaLimitedCheckpointOnDay, updateSpecialLeaveCheckpoint } from '../lib/hoursApi'
import { formatDayMonthYear } from '../lib/date'

type FloatingHolidayEditorProps = {
  employee?: EmployeeName
  startDate?: string
  endDate?: string
  colSpan: number
  settings: SettingsState
  closeEditor: (updated: boolean) => void
}

export const IncLeaveEditor: React.FC<FloatingHolidayEditorProps> = ({
  employee,
  colSpan,
  settings: { locale },
  closeEditor,
}) => {
  const [sickLeaveQuotaCheckpoint, setSickLeaveQuotaCheckpoint] = useState<SpecialLeaveCheckpoint | undefined>(
    undefined
  )
  const [newSickLeaveQuotaCheckpointCount, setNewSickLeaveQuotaCheckpointCount] = useState<number | undefined>(
    undefined
  )
  const [saveError, setSaveError] = useState<string | undefined>(undefined)

  const thisYear = new Date().getFullYear()

  useEffect(() => {
    if (!employee) return

    const username = employee.username
    const fetchCheckpoints = async () => {
      try {
        const today = new Date()
        setSickLeaveQuotaCheckpoint(await fetchQuotaLimitedCheckpointOnDay(username, today))
      } catch (e) {
        console.error(`Could not load quota limited checkpoint for employee ${username}`, e)
      }
    }

    void fetchCheckpoints()
  }, [employee, thisYear])

  if (!employee) {
    return null
  }

  const sickLeaveQuotaCheckpointChanged =
    newSickLeaveQuotaCheckpointCount !== undefined &&
    newSickLeaveQuotaCheckpointCount !== sickLeaveQuotaCheckpoint?.count
  const hasChanges = sickLeaveQuotaCheckpointChanged

  const saveChanges = async () => {
    try {
      if (newSickLeaveQuotaCheckpointCount && sickLeaveQuotaCheckpointChanged && sickLeaveQuotaCheckpoint) {
        await updateSpecialLeaveCheckpoint(
          employee.username,
          {
            ...sickLeaveQuotaCheckpoint,
            count: newSickLeaveQuotaCheckpointCount,
          },
          'quota-limited'
        )
      }
      closeEditor(hasChanges)
    } catch (e) {
      setSaveError(`${locale.texts.admin.leaveEditor.saveError}`)
    }
  }

  return (
    <>
      <td className={styles.leaveEditor} colSpan={1} />
      <td className={styles.leaveEditor} colSpan={2}>
        {sickLeaveQuotaCheckpoint ? (
          <>
            <label htmlFor={`${employee?.username}-sick-leave-quota-checkpoint`}>
              {locale.texts.admin.reaktorIncLeaves.sickLeavesAvailable}{' '}
              {formatDayMonthYear(locale)(sickLeaveQuotaCheckpoint.day)}
            </label>
            <input
              id={`${employee?.username}-sick-leave-quota-checkpoint`}
              type='number'
              defaultValue={sickLeaveQuotaCheckpoint.count}
              onChange={(e) => {
                const newCount = parseInt(e.target.value, 10)
                setNewSickLeaveQuotaCheckpointCount(newCount)
              }}
            />
          </>
        ) : null}
      </td>
      <td className={classnames(styles.leaveEditor, styles.controlsColumn)} colSpan={colSpan - 5}>
        {saveError ? (
          <span className={classnames(adminClientStyles.errorText, adminClientStyles.saveError)}>{saveError}</span>
        ) : null}
        <span className={styles.controls}>
          <button
            className={classnames(adminClientStyles.button, adminClientStyles.button_secondary)}
            onClick={() => closeEditor(false)}
          >
            {locale.texts.admin.leaveEditor.cancel}
          </button>
          <button
            className={classnames(adminClientStyles.button, adminClientStyles.button_primary)}
            disabled={!hasChanges}
            onClick={saveChanges}
          >
            {locale.texts.admin.leaveEditor.save}
          </button>
        </span>
      </td>
    </>
  )
}
