import React from 'react'
import classnames from 'classnames/bind'
import { today, currentYear, yearOfISODay } from '../../lib/date'
import PlusIcon from '../../PlusIcon'
import WorkingDateColumn from '../WorkingDateColumn'
import CopyButton from '../CopyButton'
import styles from '../../css/ViewModeWorkingDay.module.css'
import sharedStyles from '../../css/WorkingDay.module.css'
import { WorkingDayCommonProps } from '../common'

const css = classnames.bind(sharedStyles)

interface ViewModeWorkingDayProps extends WorkingDayCommonProps {
  day: string
  dayTotal?: number
  children: JSX.Element[]
  hasValidationErrors: boolean
  locked?: boolean
}

const ViewModeWorkingDay = ({
  day,
  dayTotal,
  holiday,
  isWorkDay,
  locale,
  children,
  hasValidationErrors,
  copyPreviousDay,
  locked,
}: ViewModeWorkingDayProps) => {
  const isToday = day === today()
  const isCurrentYear = yearOfISODay(day) === currentYear()

  const hasChildren = children.length > 0
  const showDayTotal = hasChildren && !isNaN(Number(dayTotal))
  const showPlusIcon = !hasChildren && !locked

  return (
    <div
      className={css('workingDay', `workingDay-${day}`, {
        locked,
        unlocked: !locked,
        'workingDay--nonWorkingDay--today': isToday && !isWorkDay,
        'workingDay--today': isToday,
        'workingDay--nonWorkingDay': !isToday && !isWorkDay,
        'workingDay--validationErrors': hasValidationErrors,
      })}
      data-test={isToday ? 'today-view' : `day-view-${day}`}
    >
      <WorkingDateColumn day={day} isToday={isToday} isCurrentYear={isCurrentYear} locale={locale} holiday={holiday} />
      {copyPreviousDay && !locked && <CopyButton onClick={copyPreviousDay} locale={locale} />}
      {hasChildren && <div className={styles.entries}>{children}</div>}
      {showDayTotal && <div className='workingDay__sum'>{`${dayTotal} h`}</div>}
      {showPlusIcon && (
        <div className={styles.expand}>
          <PlusIcon />
        </div>
      )}
    </div>
  )
}

export default ViewModeWorkingDay
