import { Reducer } from 'redux'
import { PayloadAction } from './payload-action'

export type UIKeyboardNavEntry = { index: number; column: string }
export type UIState = { keyboardNavigation: { entry: UIKeyboardNavEntry } }

type UIStateAction = PayloadAction<'UI_KEYBOARD_NAVIGATION', { keyboardNavigation: UIState['keyboardNavigation'] }>
const DEFAULT_UI_STATE: UIState = {
  keyboardNavigation: {
    entry: { index: 0, column: '' },
  },
}
export const ui: Reducer<UIState, UIStateAction> = (state = DEFAULT_UI_STATE, action) => {
  switch (action.type) {
    case 'UI_KEYBOARD_NAVIGATION':
      return {
        ...state,
        keyboardNavigation: {
          ...state.keyboardNavigation,
          entry: { ...state.keyboardNavigation.entry, ...action.keyboardNavigation.entry },
        },
      }
    default:
      return state
  }
}
