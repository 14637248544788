import React from 'react'
import styles from '../css/LoadMore.module.css'
import { Locale } from '../lib/reducers/settings'

interface LoadMoreProps {
  locale: Locale
  onClick: () => void
}

const LoadMore = ({ locale, onClick }: LoadMoreProps) => (
  <button data-test='loadMore' className={styles.loadMore} onClick={onClick}>
    {locale.texts.showMore}
  </button>
)

export default LoadMore
