import React from 'react'

import styles from './css/PlusIcon.module.css'

const PlusIcon = () => (
  <div>
    <img className={styles.mobile} src='/plus.svg' width='15' height='15' alt='Expand' />
    <img className={styles.desktop} src='/plus-desktop.svg' width='19' height='19' alt='Expand' />
  </div>
)

export default PlusIcon
