import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import _ from 'lodash'
import { startOfMonth, subMonths, lastDayOfMonth, addMonths, eachDayOfInterval, max } from 'date-fns'

import { formatISO, today, yesterday, parseISODate } from './date'
import { isWorkDayForEmployee } from './dateUtil'
import { calculateMostUsedHourCodes } from './hoursApi'
import { createDefaultDay } from './util'
import { RootState } from './reducers'
import { CopyPreviousDayFunction, HoursContractSeason, InvoiceTaskInfo, WorkDay, WorkEntry } from './domain'

const copyEntriesToDay = async (entries: WorkEntry[], day: string, modifyEntry: CopyPreviousDayFunction) => {
  for (const [index, entry] of entries.entries()) {
    const copy: WorkEntry = { ...entry }
    const context = { day, indexInDay: index }
    await modifyEntry(context, copy)
  }
}

export const findCurrentContract = (contracts: HoursContractSeason[], day = today()) =>
  contracts.find((c) => c.start <= day && (!c.finish || c.finish >= day))

const calculateWorkingDays = (
  daysArray: [string, WorkDay][],
  publicHolidays: Record<string, string>,
  contracts: HoursContractSeason[]
) =>
  daysArray.filter(([day]) => {
    const currentContract = findCurrentContract(contracts, day)
    return isWorkDayForEmployee(day, currentContract, publicHolidays)
  })

// create a selector that uses deep comparison instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _.isEqual)

// use deep equal selector for local changes to avoid unnecessary recomputations
const localDaysChangesSelector = createDeepEqualSelector(
  (state: RootState) => ({
    ...state.data.personalHoursClient.sentChangedLocalDays,
    ...state.data.personalHoursClient.unsentChangedLocalDays,
  }),
  (localChanges) => localChanges
)

const daysMapSelector = createSelector(
  (state) => state.data.personalHoursClient.serverDays,
  localDaysChangesSelector,
  (serverDays, localChanges) => ({ ...serverDays, ...localChanges })
)

export const displayedDaysArraySelector = createSelector(
  daysMapSelector,
  (state: RootState) => state.data.personalHoursClient.hasServerDaysBeenLoaded,
  (state: RootState) => state.user.dataOwnerUser.startDate,
  (state: RootState) => state.settings.numMonthsBack,
  (state: RootState) => state.settings.numMonthsForward,
  (daysMap, hasServerDaysBeenLoaded, startDate, numMonthsBack, numMonthsForward) => {
    // we dont want to display empty days
    // before days are received from server
    if (!hasServerDaysBeenLoaded) {
      return []
    }

    const EPOCH = new Date(0) // 1970
    const firstDisplayedDate = max([startOfMonth(subMonths(new Date(), numMonthsBack)), startDate || EPOCH])
    const lastDisplayedDate = lastDayOfMonth(addMonths(max([new Date(), firstDisplayedDate]), numMonthsForward))

    return eachDayOfInterval({
      start: firstDisplayedDate,
      end: lastDisplayedDate,
    })
      .map(formatISO)
      .map((isoDay) => [isoDay, daysMap[isoDay] || createDefaultDay(isoDay)] as [string, WorkDay])
  }
)

const workingDaysSelector = createSelector(
  displayedDaysArraySelector,
  (state) => state.data.personalHoursClient.publicHolidays,
  (state) => state.data.personalHoursClient.contracts,
  calculateWorkingDays
)

const copyPreviousWorkingDayEntriesSelector = createSelector(
  workingDaysSelector,

  // returns all working days that A: have no entries and B: the previous working day has entries
  (workingDays: [string, WorkDay][]) => {
    const [copyPreviousWorkingDayEntries] = workingDays.reduce(
      ([collectedDays, previousWorkingDayHasEntries, previousWorkingDay], currentDay) => {
        const [day, dayData] = currentDay
        const hasEntries = dayData.entries.length > 0
        if (hasEntries) {
          return [collectedDays, true, currentDay]
        } else {
          if (previousWorkingDayHasEntries) {
            if (!previousWorkingDay) throw new Error('Invalid currentDay in previous iteration')

            return [
              { ...collectedDays, [day]: { currentDay: dayData, previousWorkingDay: previousWorkingDay[1] } },
              false,
              currentDay,
            ]
          }

          return [collectedDays, false, currentDay]
        }
      },
      [{}, false, null] as [
        Record<string, { currentDay: WorkDay; previousWorkingDay: WorkDay }>,
        boolean,
        [string, WorkDay] | null
      ]
    )

    return copyPreviousWorkingDayEntries
  }
)

export const copyPreviousDayFunctionsSelector = createSelector(
  copyPreviousWorkingDayEntriesSelector,
  (copyPreviousWorkingDayEntries) =>
    _.mapValues(copyPreviousWorkingDayEntries, (entry) => {
      const { currentDay, previousWorkingDay } = entry
      return (modifyEntry: CopyPreviousDayFunction) =>
        copyEntriesToDay(previousWorkingDay.entries, currentDay.day, modifyEntry)
    })
)

// returns previous entry with hours entered
export const previousEntrySelector = createSelector(
  displayedDaysArraySelector,
  daysMapSelector,
  (state) => state.data.personalHoursClient.currentlyEditingDay,
  (state) => state.ui.keyboardNavigation.entry.index,
  (daysArray, dayMap, currentlyEditingDay, currentlyEditingEntryIndex) => {
    if (!currentlyEditingDay) return null

    if (currentlyEditingEntryIndex > 0) {
      return (
        (dayMap[currentlyEditingDay] && dayMap[currentlyEditingDay]?.entries[currentlyEditingEntryIndex - 1]) || null
      )
    } else {
      const previousDay = yesterday(currentlyEditingDay)
      const [, lastEntry] =
        _.findLast(daysArray, ([day, dayData]) => parseISODate(day) <= previousDay && !_.isEmpty(dayData?.entries)) ||
        []
      return lastEntry && !_.isEmpty(lastEntry.entries) ? lastEntry.entries.slice(-1)[0] : null
    }
  }
)

export const currentlyEditingDayEntrySelector = createSelector(
  daysMapSelector,
  (state) => state.data.personalHoursClient.currentlyEditingDay,
  (state) => state.ui.keyboardNavigation.entry.index,
  (dayMap, currentlyEditingDay, currentlyEditingEntryIndex) =>
    dayMap[currentlyEditingDay] ? dayMap[currentlyEditingDay]?.entries[currentlyEditingEntryIndex] : undefined
)

export const copyPreviousEntrySelector = createSelector(
  previousEntrySelector,
  (state) => state.data.personalHoursClient.currentlyEditingDay,
  (state) => state.ui.keyboardNavigation.entry.index,
  currentlyEditingDayEntrySelector,
  (previousEntry, currentlyEditingDay, currentlyEditingEntryIndex, currentlyEditingDayEntry) => {
    const isEntryUnmodified = _.isEmpty(currentlyEditingDayEntry)
    if (previousEntry && isEntryUnmodified) {
      return {
        previousEntry,
        context: { day: currentlyEditingDay, indexInDay: currentlyEditingEntryIndex },
        diff: { ...previousEntry, note: '' },
      }
    }
  }
)

// only recalculate the most used hour codes when the day range changes
const createSizeComparingSelector = createSelectorCreator(
  defaultMemoize,
  <T>(arr1: T, arr2: T) => _.size(arr1 as unknown as string) === _.size(arr2 as unknown as string)
)
const hourCodeSuggestionsSelector = createSizeComparingSelector(
  (state: RootState) => state.data.personalHoursClient.serverDays,
  (serverDays) => {
    const THREE_MONTHS_AGO = formatISO(subMonths(today(), 3))
    const daysToCalculateSuggestionsFrom = Object.values(serverDays).filter(({ day }) => day > THREE_MONTHS_AGO)
    const entries = _.flatten(daysToCalculateSuggestionsFrom.map((dayData) => dayData.entries)).filter(
      (e) => e.hourCode
    )
    return calculateMostUsedHourCodes(entries)
  }
)

const validHourCodesForCurrentDaySelector = createSelector(
  (state: RootState) => state.data.personalHoursClient.currentlyEditingDay,
  (state: RootState) => state.data.personalHoursClient.hourCodesWithTimeRanges,
  (day, hourCodesWithTimeRanges) =>
    hourCodesWithTimeRanges.filter(({ start, end }) => !end || (start <= day && day <= end))
)

export const hourCodesSelector = createSelector(
  validHourCodesForCurrentDaySelector,
  hourCodeSuggestionsSelector,
  (invoiceTaskInfos: InvoiceTaskInfo[], hourCodeSuggestions) => {
    const picked = hourCodeSuggestions.map((code) => invoiceTaskInfos.find((info) => info.name === code))

    return picked
      .filter((info): info is InvoiceTaskInfo => info !== undefined)
      .concat(invoiceTaskInfos.filter((info) => !hourCodeSuggestions.includes(info.name)))
  }
)

export const currentContractSelector = createSelector(
  (state: RootState) => state.data.personalHoursClient.contracts,
  findCurrentContract
)
