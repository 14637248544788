import { AppDispatch, RootState } from '../lib/store'
import React, { ReactElement, useEffect, useState } from 'react'
import { useCountrySelect } from './useCountrySelect'
import { fetchCurrentEmployeeContractsWithVacations } from '../lib/hoursApi'
import AccessForbiddenView from './AccessForbiddenView'
import { LoggedInUser } from '../lib/reducers/user'
import adminClientStyles from '../css/AdminClient.module.css'
import styles from '../css/EmployeeTable.module.css'
import {
  Country,
  CountryID,
  EmployeeHolidayStatus,
  EmployeeHolidayStatusFI,
  EmployeeName,
  EmploymentContract,
  EmploymentContracts,
  EmploymentSeason,
  FloatingHolidaySummary,
  HolidayStatus,
  SpecialLeaveCheckpoint,
  UnpaidVacationSummary,
} from '../lib/domain'
import _ from 'lodash'
import { getHolidayCheckpointForDisplay as getHolidaysForDisplayNL } from '../HolidayTable/netherlands'
import { getHolidaysForDisplay as getHolidaysForDisplayUS } from '../HolidayTable/usa'
import { isAfter, isBefore, today } from '../lib/date'
import { connect } from 'react-redux'
import { SettingsState } from '../lib/reducers/settings'
import { Dispatch } from 'redux'
import SortableTable, { sortItemsByPath, useSortingByPath, TableHeading, SortableTableHeader } from './SortableTable'
import classNames from 'classnames'

export type EmployeeTableItem = {
  employee?: EmployeeName
  sortName: string
  currentSeason?: EmploymentSeason
  currentContract?: EmploymentContract
  holidays: HolidayStatus
  unpaidLeaves?: UnpaidVacationSummary
  floatingHolidays?: FloatingHolidaySummary
  limitedAbsenceQuotaCheckpoint?: SpecialLeaveCheckpoint
  startDate?: string
  endDate?: string
}

type EmployeeTableProps = {
  loggedInUser: RootState['user']['loggedInUser']
  selectableCountries: Country[]
  employeesByCountry: RootState['data']['adminClient']['employeesByCountry']
  settings: RootState['settings']
  userHasRights: (user: LoggedInUser) => boolean
  tableHeadingsForCountry: (country: CountryID, locale: SettingsState['locale']) => TableHeading[]
  toDisplayRow: (employee: EmployeeTableItem, locale: SettingsState['locale']) => string[]
  title: string
  editable?: boolean
  renderEditor?: (
    employee: EmployeeTableItem,
    closeEditor: (updated: boolean) => void
  ) => ReactElement<unknown, string | never> | null
  dispatch: AppDispatch
}

const updateCountryEmployees = async (selectedCountry: CountryID, dispatch: Dispatch) => {
  try {
    const employees = await fetchCurrentEmployeeContractsWithVacations(selectedCountry)
    dispatch({ type: 'UPDATE_COUNTRY_EMPLOYEES', employeesByCountry: employees })
  } catch (e) {
    console.error('Could not load employee contracts for user', e)
  }
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  loggedInUser,
  selectableCountries,
  employeesByCountry,
  settings: { locale },
  userHasRights,
  tableHeadingsForCountry,
  toDisplayRow,
  title,
  editable,
  renderEditor,
  dispatch,
}) => {
  const [selectedCountry, setSelectedCountry] = useCountrySelect(selectableCountries)
  const [sorting, toggleSortByPath] = useSortingByPath()
  const [editingUuid, setEditingUuid] = useState<string | undefined>(undefined)

  useEffect(() => {
    // Fetch employees for selected country unless fetched earlier.
    if (selectedCountry != null && !employeesByCountry[selectedCountry]) {
      void updateCountryEmployees(selectedCountry, dispatch)
    }
  }, [selectedCountry, employeesByCountry, dispatch])
  if (!userHasRights(loggedInUser)) {
    return <AccessForbiddenView locale={locale} />
  }

  const { isAdmin, isCountryManager, managedCountries } = loggedInUser
  const impersonationAllowed = isAdmin || (isCountryManager && managedCountries.length > 0)
  const employees = tableItem(today(), selectedCountry ? employeesByCountry[selectedCountry] ?? [] : [])
  const sortedEmployees = sortItemsByPath(sorting, employees)
  const nameHeading: TableHeading = { title: locale.texts.admin.vacations.name, sortPath: 'sortName' }
  const userNameHeading: TableHeading = { title: locale.texts.admin.vacations.username, sortPath: 'employee.username' }
  const dataHeadings = selectedCountry ? tableHeadingsForCountry(selectedCountry, locale) : []
  const headings = [nameHeading, userNameHeading, ...dataHeadings]
  const closeEditor = async (updated: boolean) => {
    setEditingUuid(undefined)
    if (updated && selectedCountry) {
      await updateCountryEmployees(selectedCountry, dispatch)
    }
  }

  return (
    <div className={adminClientStyles.container}>
      <h1>{title}</h1>
      {selectableCountries.length > 1 ? (
        <CountrySelect
          selectableCountries={selectableCountries}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          locale={locale}
        />
      ) : null}
      <SortableTable className={classNames(styles.employeeTable)} dataTest='employeeTable'>
        <SortableTableHeader headings={headings} sortingState={sorting} toggleSortByPath={toggleSortByPath} />
        <tbody>
          {sortedEmployees.map((employeeData) => {
            const { employee } = employeeData
            const rowData = toDisplayRow(employeeData, locale)
            const editing = editingUuid === employee?.username
            const className = editing ? styles.editing : editable ? styles.editable : ''
            const edit = () => {
              if (editable) setEditingUuid(employee?.username)
            }
            const employeeName = `${employee?.firstName} ${employee?.lastName}`
            const impersonationLink =
              employee && `${window.location.protocol}//${window.location.host}/?uid=${employee.username}`
            return (
              <React.Fragment key={employee?.username}>
                <tr className={className} onClick={edit}>
                  <td>{impersonationAllowed ? <a href={impersonationLink}>{employeeName}</a> : { employeeName }}</td>
                  <td>
                    <span className={styles.username}>{employee?.username}</span>
                  </td>
                  {rowData.map((text, i) => (
                    <td key={headings[i + 1]?.title ?? text} className={styles.contentColumn}>
                      {text}
                    </td>
                  ))}
                </tr>
                {editing ? (
                  <tr className={styles.editor}>{renderEditor ? renderEditor(employeeData, closeEditor) : null}</tr>
                ) : null}
              </React.Fragment>
            )
          })}
        </tbody>
      </SortableTable>
    </div>
  )
}

const CountrySelect: React.FC<{
  selectableCountries: Country[]
  selectedCountry: CountryID | null
  setSelectedCountry: (country: CountryID) => void
  locale: SettingsState['locale']
}> = ({ selectableCountries, selectedCountry, setSelectedCountry, locale }) => {
  return (
    <div className={adminClientStyles.controls}>
      <div className={adminClientStyles.selectContainer}>
        <label htmlFor='countrySelect'>{locale.texts.admin.vacations.country}</label>
        <select
          id='countrySelect'
          value={selectedCountry || ''}
          onChange={(e) => setSelectedCountry(e.target.value as CountryID)}
        >
          {selectableCountries.map((country) => (
            <option value={country.id} key={country.id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

const tableItem = (day: string, employees: EmploymentContracts[]): EmployeeTableItem[] =>
  employees.map(({ employee, contracts }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentContract = contractOnDay(contracts, day) || _.last(contracts)!
    const currentSeason = seasonOnDay(currentContract, day) || _.last(currentContract.seasons)
    const holidaysForDisplay = (() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const holidayStatus = currentContract.holidays!
      switch (holidayStatus.country) {
        case 'FI':
          return getHolidaysForDisplayFI(holidayStatus)
        case 'NL':
          return getHolidaysForDisplayNL(holidayStatus)
        case 'US':
          return getHolidaysForDisplayUS(holidayStatus)
        default:
          return holidayStatus
      }
    })()
    const sortName = employee ? toSortableName(employee.firstName, employee.lastName) : ''

    return {
      employee,
      startDate: _.first(currentContract.seasons)?.start,
      endDate: currentContract.end,
      sortName,
      currentSeason,
      currentContract,
      holidays: holidaysForDisplay,
      unpaidLeaves: currentContract.unpaidLeaves,
      floatingHolidays: currentContract.floatingHolidays2024,
      limitedAbsenceQuotaCheckpoint: currentContract.limitedAbsenceQuotaCheckpoint,
    }
  })

const lastNamePrefixes = ['af', 'de', 'van', 'von']

function toSortableName(firstName: string, lastName: string): string {
  const lastNameParts = lastName.split(/\s+/)
  if (lastNameParts.length > 1 && lastNamePrefixes.some((prefix) => prefix === lastNameParts[0])) {
    return `${lastNameParts.slice(1).join(' ')}, ${firstName} ${lastNameParts[0]}`
  }
  return `${lastName} ${firstName}`
}

const seasonOnDay = ({ seasons }: EmploymentContract, day: string) =>
  _.reverse([...seasons]).find((season) => isBefore(season.start, day))

const contractOnDay = (contracts: EmploymentContract[], day: string) =>
  contracts.find((c) => isBefore(_.first(c.seasons)?.start, day && (!c.end || isAfter(c.end, today))))

/*
 * The workdays structure in the checkpoint of a Finnish employee contains the
 * agreed vacation days, not the proportion of earned days that are workdays.
 * In the employee's UI, these two values are summed together to display the total
 * number of available vacation days that can be used on workdays, but here we
 * want to show them separately to enable separate inspection of agreed holidays.
 *
 * To this end, this function adds a field containing the agreed holidays to the
 * checkpoint and adjusts workdays.base to contain the actual earned workdays.
 */
const getHolidaysForDisplayFI = (checkpoint: EmployeeHolidayStatus): EmployeeHolidayStatusFI => checkpoint

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    employeesByCountry: state.data.adminClient.employeesByCountry,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(EmployeeTable)
