import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import classnames from 'classnames'

import { hourDifference } from '../lib/time'
import { formatRange } from '../lib/date'

import { currentContractSelector } from '../lib/selectors'

import entryStyles from '../css/Entry.module.css' // FIXME: dont depend on another component's styles, or extract common styles elsewhere
import styles from '../css/ValidationMessages.module.css'

const validationToLocalizedMessage = (code, validationMessage, locale) => {
  const msgParser = validationMessageParsers[code]
  const formatWithValidationMessage = (text) =>
    _.isFunction(text) ? text(msgParser ? msgParser(locale)(validationMessage) : validationMessage) : text
  const text = locale.texts.validationErrors[code] || ''
  return {
    buttonText: formatWithValidationMessage(text.button || ''),
    message: formatWithValidationMessage(text.title || text || ''),
    code,
    validationMessage,
  }
}

const largerAbsenceParser = (locale) => (msg) => {
  const [absences, dayLength] = msg.split(' > ')
  return { absences, dayLength }
}

const rangeParser = (locale) => (msg) => {
  const [start, end] = msg.split('--')
  return { start, end, duration: formatRange(locale)({ start, end }) }
}

const validationMessageParsers = {
  moreAbsenceThanWorkDayLength: largerAbsenceParser,
  absenceLongerThanWorkDay: largerAbsenceParser,
  absenceIncreasingFlextime: (locale) => (msg) => {
    const [hours, validAbsenceHours] = msg.split(' > ')
    return { hours, validAbsenceHours }
  },
  wrongLengthFullDayAbsence: (locale) => (msg) => {
    const [absence, dayLength] = msg.split(' ≠ ')
    return { absence, dayLength }
  },
  hoursLongerThanPeriod: (locale) => (msg) => {
    const [hours, duration] = msg.split(' > ')
    return { hours, duration: duration.replace(/\s*\(.*$/, '') }
  },
  outsideProjectDuration: rangeParser,
  outsideTaskDuration: rangeParser,
}

const validationToMessages = (validation, locale) => {
  return _.entries(validation).map(([code, validationMessage]) =>
    validationToLocalizedMessage(code, validationMessage, locale)
  )
}

const isAbsenceCode = (hourCode) => hourCode.startsWith('poissa-') || hourCode.startsWith('absent-')
const flexiHourCodeBasedOnLocale = (locale) => (locale.language === 'FI' ? 'poissa-liukumavapaa' : 'absent-flexitime')

// Take correct hours from the validation message, as it is calculated on the server and is the most reliable number
const modifyAbsenceEntryHoursToDayLength = (entry, modifyEntry, validationMsg) => {
  if (entry.hourCode && isAbsenceCode(entry.hourCode)) {
    modifyEntry({
      ...entry,
      startTime: '', // remove startTime and endTime when doing correction to prevent autofills.js from retriggering validation
      endTime: '',
      hours: _.last(validationMsg.split(' ')),
      noHoursAutoFill: true,
    })
  }
}

// modifyEntry first parameter is partially applied
// function will be called with props of ValidationMessages
const createValidationActionForEntry = (day, entry, validation, modifyEntry) => ({
  absenceLongerThanWorkDay: () =>
    modifyAbsenceEntryHoursToDayLength(entry, modifyEntry, validation.absenceLongerThanWorkDay),

  wrongLengthFullDayAbsence: () =>
    modifyAbsenceEntryHoursToDayLength(entry, modifyEntry, validation.wrongLengthFullDayAbsence),

  absenceIncreasingFlextime: () =>
    modifyAbsenceEntryHoursToDayLength(entry, modifyEntry, validation.absenceIncreasingFlextime),

  hoursLongerThanPeriod: () => {
    if (!entry.startTime || !entry.endTime) {
      return
    }
    const startEndTimeDifference = hourDifference(entry.startTime, entry.endTime)
    if (startEndTimeDifference < entry.hours) {
      modifyEntry({ ...entry, hours: startEndTimeDifference })
    }
  },

  absenceOnNotWorkDay: () => {
    if (entry.hourCode && isAbsenceCode(entry.hourCode)) {
      modifyEntry({ hours: '', hourCode: '', note: '', startTime: '', endTime: '' })
    }
  },
})

export const createValidationActions = (day, entries, locale, modifyEntry) => ({
  suspiciouslyFewHours: () => {
    modifyEntry({ day, indexInDay: entries.length - 1 }, { hours: 0, hourCode: flexiHourCodeBasedOnLocale(locale) })
  },

  // adding "absent-flexitime" is currently the only way to suppress too-many-hours warning
  suspiciouslyManyHours: () =>
    modifyEntry({ day, indexInDay: entries.length - 1 }, { hours: 0, hourCode: flexiHourCodeBasedOnLocale(locale) }),
})

const ValidationMessages = ({
  className,
  validationMessages,
  validationActions,
  'data-test': dataTest,
  startEndShown,
  ...props
}) => {
  if (!validationMessages || validationMessages.length === 0) return null

  const [validationMessagesWithAction, validationMessagesWithoutAction] = _.partition(
    validationMessages,
    (message) => validationActions[message.code]
  )

  return (
    <div
      data-test={dataTest}
      className={classnames(styles.validationMessages, className, { [styles.startEndShown]: startEndShown })}
    >
      {validationMessagesWithAction.map(({ buttonText, message, code }) => (
        <div key={code}>
          <span>{message}</span>
          <button onClick={() => (validationActions[code] || _.noop)(props)}>{buttonText}</button>
        </div>
      ))}{' '}
      <span>
        {validationMessagesWithoutAction
          .map(({ message }) => message)
          .filter((m) => m !== '')
          .join(' - ')}
      </span>
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  ...props,
  currentContract: currentContractSelector(state),
})

export const ValidationEntry = connect(mapStateToProps)(({ day, entry, locale, validation, modifyEntry, ...props }) => {
  return (
    <ValidationMessages
      data-test='validationEntry'
      className={entryStyles.validationMessage}
      validationActions={createValidationActionForEntry(day, entry, validation, modifyEntry)}
      validationMessages={validationToMessages(validation, locale)}
      {...props}
    />
  )
})

export const ValidationWorkingDay = connect(mapStateToProps)(
  ({ day, children, locale, validation, modifyEntry, ...props }) => {
    if (!validation) {
      return <div className={'workingDay--validationErrors'} />
    }
    return (
      <ValidationMessages
        data-test='validationWorkingday'
        className={'workingDay--validationMessages'}
        validationActions={createValidationActions(day, children, locale, modifyEntry)}
        validationMessages={validationToMessages(validation, locale)}
        {...props}
      />
    )
  }
)
