import React from 'react'
import { RootState } from '../lib/store'
import { connect } from 'react-redux'
import { SettingsState } from '../lib/reducers/settings'
import { LoggedInUser } from '../lib/reducers/user'
import UsersWithUnlockedHoursTable from './UsersWithUnlockedHoursTable'
import AccessForbiddenView from './AccessForbiddenView'

type UsersWithUnlockedHoursTableProps = {
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}

export const userHasRights = (loggedInUser: LoggedInUser) => loggedInUser.isAdmin || loggedInUser.isCountryStatsUser

const UsersWithUnlockedHoursStatusView: React.FC<UsersWithUnlockedHoursTableProps> = ({ loggedInUser, settings }) => {
  const { locale } = settings
  if (!userHasRights(loggedInUser)) {
    return <AccessForbiddenView locale={locale} />
  } else {
    return <UsersWithUnlockedHoursTable countries={loggedInUser.managedCountries} />
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(UsersWithUnlockedHoursStatusView)
