import { LoggedInUser } from '../lib/reducers/user'
import { RootState } from '../lib/store'
import { SettingsState } from '../lib/reducers/settings'
import React from 'react'
import EmployeeTable, { EmployeeTableItem } from './EmployeeTable'
import { connect } from 'react-redux'
import { IncLeaveEditor } from './IncLeaveEditor'
import { CountryID } from '../lib/domain'
import { TableHeading } from './SortableTable'

export const userHasRights = (loggedInUser: LoggedInUser) =>
  loggedInUser.isAdmin || (loggedInUser.isCountryManager && loggedInUser.managedCountries.some(({ id }) => id === 'US'))

type IncLeaveStatusViewProps = {
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}

const tableHeadings = (country: CountryID, locale: SettingsState['locale']): TableHeading[] => {
  return [{ title: locale.texts.admin.reaktorIncLeaves.sickLeave, sortPath: 'limitedAbsenceQuotaCheckpoint.count' }]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fieldsForEmployee = (employeeData: EmployeeTableItem, locale: SettingsState['locale']): string[] => {
  const limitedAbsenceQuotaCheckpoint = employeeData.limitedAbsenceQuotaCheckpoint
  return [limitedAbsenceQuotaCheckpoint?.count.toString() ?? '']
}

const IncLeaveStatusView: React.FC<IncLeaveStatusViewProps> = ({ loggedInUser, settings }) => {
  const { locale } = settings
  const { managedCountries } = loggedInUser
  const selectableCountries = managedCountries.filter(({ id }) => id === 'US')
  const renderEditor = (employee: EmployeeTableItem, closeEditor: (updated: boolean) => void) => {
    return <IncLeaveEditor employee={employee.employee} colSpan={7} settings={settings} closeEditor={closeEditor} />
  }

  return (
    <EmployeeTable
      title={locale.texts.admin.reaktorIncLeaves.title}
      selectableCountries={selectableCountries}
      userHasRights={userHasRights}
      tableHeadingsForCountry={tableHeadings}
      toDisplayRow={fieldsForEmployee}
      renderEditor={renderEditor}
      editable
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(IncLeaveStatusView)
