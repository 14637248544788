import React from 'react'
import classnames from 'classnames'
import styles from './css/NoConnectionIndicator.module.css'

const NoConnectionIndicator = ({ locale, hasConnection }) => {
  return (
    <div className={classnames(styles.noConnectionIndicator, { [styles.visible]: !hasConnection })}>
      {locale.texts.serverConnectivityProblems}
      <button className={styles.refreshButton} onClick={() => window.location.reload()}>
        {locale.texts.refresh}
      </button>
    </div>
  )
}

export default NoConnectionIndicator
