import React from 'react'
import RoundedButton from '../RoundedButton'
import { Locale } from '../lib/reducers/settings'

interface CopyButtonProps {
  onClick: () => Promise<void>
  locale: Locale
}

const CopyButton = ({ onClick, locale }: CopyButtonProps) => {
  return (
    <RoundedButton
      className='workingDay__copyLastDay'
      onClick={async (e) => {
        e.stopPropagation()
        await onClick()
      }}
    >
      {locale.texts.copyLastDay}
    </RoundedButton>
  )
}

export default CopyButton
