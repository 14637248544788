import React, { useState, useEffect, useRef } from 'react'
import { CartesianGrid, BarChart, Tooltip, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { format } from 'date-fns'
import _ from 'lodash'
import useComponentSize from '@rehooks/component-size'
import { fetchMonthSummaries } from './lib/hoursApi'
import { monthRange } from './lib/date'
import styles from './css/BillingRatio.module.css'

const Y_AXIS_WIDTH = 60
const BAR_CATEGORY_GAP = -0.4 // for some reason with value of 0, there can sometimes be tiny gaps between bars

const formatMonthSummaries = (monthSummaries) =>
  _.chain(monthSummaries)
    .entries()
    .map(([month, { unplannedAbsence, billable, internal }]) => {
      // Calculation of total hours is based on Reaktor BI calculation:
      // https://bi.reaktor.com/d/PLvsEetik/reaktor-overview?orgId=1&panelId=6&fullscreen
      const totalHours = billable + internal + unplannedAbsence
      const hasAvailableHours = totalHours !== 0
      return {
        month,
        billable: hasAvailableHours ? billable / totalHours : 1,
      }
    })
    .sortBy('month')
    .value()

const BillingRatio = ({ locale, dataOwnerUsername }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    if (!dataOwnerUsername) return
    const [startDate, endDate] = monthRange(24, 0)
    const start = format(startDate, 'YYYY-MM')
    const end = format(endDate, 'YYYY-MM')
    fetchMonthSummaries(dataOwnerUsername, start, end)
      .then(formatMonthSummaries)
      .then(setData)
      .catch(() => console.error('Error fetching billing ratio data'))
  }, [dataOwnerUsername])

  if (!data.length) return null

  return (
    <div data-test='billingRatio' className={styles.billingRatio}>
      <h2 className={styles.heading}>{locale.texts.billingRatio}</h2>
      <Chart data={data} locale={locale} />
    </div>
  )
}

const Chart = ({ data, locale }) => {
  const containerRef = useRef(null)
  const { width: containerWidth } = useComponentSize(containerRef)

  return (
    <div className={styles.chart} ref={containerRef}>
      {containerWidth && (
        <ResponsiveContainer>
          <BarChart data={data} margin={{ top: 20, right: 10, bottom: 0, left: 0 }} barCategoryGap={BAR_CATEGORY_GAP}>
            <Bar dataKey='billable' fill='url(#pattern)' isAnimationActive={false} />
            <XAxis dataKey='month' tickLine={false} axisLine={false} />
            <YAxis
              domain={[0, 1]}
              tickLine={false}
              axisLine={false}
              interval={1}
              orientation='left'
              tickFormatter={(value) => `${value * 100}%`}
            />
            <Tooltip cursor={false} formatter={(value) => [`${Math.floor(value * 100)}%`, locale.texts.billingRatio]} />
            <CartesianGrid color='var(--color-border-low-contrast)' verticalPoints={gridPoints(data, containerWidth)} />
            <defs>
              <pattern
                id='pattern'
                width='3'
                height='3'
                patternUnits='userSpaceOnUse'
                patternTransform='rotate(45 50 50)'
              >
                <line stroke='var(--evening-blue)' opacity='0.8' strokeWidth='2px' x2='4' />
              </pattern>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

const gridPoints = (data, chartWidth) => {
  const leftEdgePosition = Y_AXIS_WIDTH
  const rightEdgePosition = chartWidth - Y_AXIS_WIDTH / 2

  const yearLinePositions = data
    .map((d, i) => ({ ...d, position: (i / data.length) * (rightEdgePosition - leftEdgePosition) + Y_AXIS_WIDTH }))
    .filter(({ month }) => month.endsWith('01'))
    .map(({ position }) => position)

  return [...yearLinePositions, leftEdgePosition, rightEdgePosition]
}

export default BillingRatio
