import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Head from './Head'
import HoursClient from './HoursClient'
import AdminClient from './AdminClient'

import { fetchEmployee, fetchLoggedInUser, fetchManagedCountries } from './lib/hoursApi'
import qs from 'qs'
import { useAppDispatch } from './lib/hooks'
import { AppDispatch } from './lib/store'

async function fetchLoggedInUserInfo(uid: unknown, dispatch: AppDispatch) {
  try {
    if (typeof uid !== 'string' && typeof uid !== 'undefined') throw new Error('uid param not valid')

    const { username: loggedInUsername } = await fetchLoggedInUser()
    const dataOwnerUsername = uid ?? loggedInUsername

    dispatch({ type: 'LOG_IN', user: { loggedInUsername, dataOwnerUsername } })

    fetchEmployee(loggedInUsername)
      .then((employee) => {
        dispatch({ type: 'UPDATE_LOGGED_IN_USER', employee })
        dispatch({ type: 'UPDATE_LOCALE', language: employee.language })
        return null
      })
      .catch((e) => console.error('Could not load managed countries for user', e))

    fetchManagedCountries(loggedInUsername)
      .then((countries) => dispatch({ type: 'UPDATE_MANAGED_COUNTRIES', managedCountries: countries }))
      .catch((e) => console.error('Could not load managed countries for user', e))
  } catch (e) {
    console.error('Could not load user info', e)
    dispatch({ type: 'UPDATE_CONNECTION_STATUS', isServerConnectionWorking: false })
  }
}

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const [initialFetchDone, setInitialFetchDone] = useState(false)
  const [addEntry, setAddEntry] = useState({})

  useEffect(() => {
    const queryParams = qs.parse(window.location.search.slice(1))

    const fetchUser = async () => {
      await fetchLoggedInUserInfo(queryParams.uid, dispatch)
      setInitialFetchDone(true)
    }

    if (queryParams.addEntryDate) {
      const { addEntryDate, addEntryHours, addEntryCode, addEntryNote, ...rest } = queryParams
      setAddEntry({
        date: addEntryDate,
        hours: addEntryHours,
        code: addEntryCode,
        note: addEntryNote,
      })
      window.history.replaceState({}, '', `${window.location.pathname}?${qs.stringify(rest)}`)
    }

    fetchUser().catch(console.error)
  }, [dispatch])

  return (
    <>
      <Head />
      {initialFetchDone ? (
        <Router>
          <Switch>
            <Route path='/backoffice'>
              <AdminClient />
            </Route>
            <Route path='/'>
              <HoursClient addEntry={addEntry} />
            </Route>
          </Switch>
        </Router>
      ) : null}
    </>
  )
}

export default connect()(App)
