import React, { useState, useEffect } from 'react'
import { today } from '../lib/date'
import styles from '../css/ScrollToToday.module.css'
import { Locale } from '../lib/reducers/settings'

interface ScrollToTodayProps {
  scrollContainerRef: React.RefObject<HTMLDivElement>
  locale: Locale
  scrollToDay: (day: string) => void
  selectCurrentDay: () => void
}

const ScrollToToday = ({ scrollContainerRef, locale, scrollToDay, selectCurrentDay }: ScrollToTodayProps) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementsByClassName('workingDay__today')[0]
      if (element) {
        const { top, bottom } = element.getBoundingClientRect()
        const shouldBeVisible =
          !(top >= 0 && top <= window.innerHeight) &&
          !(bottom >= 0 && bottom <= window.innerHeight) &&
          !(top <= 0 && bottom >= window.innerHeight)

        visible !== shouldBeVisible && setVisible(shouldBeVisible)
      }
    }

    const scrollContainer = scrollContainerRef.current

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  })

  return (
    <button
      className={visible ? styles.visible : styles.hidden}
      id={styles.scrollToToday}
      onClick={() => {
        scrollToDay(today())
        selectCurrentDay()
      }}
    >
      {locale.texts.today}
    </button>
  )
}

export default ScrollToToday
