import {
  EmploymentContracts,
  ReportingResponse,
  UnpaidLeaveCheckpoints,
  UsernameReportWorkEntry,
  UsersWithUnlockedHours,
} from '../domain'
import { PayloadAction } from './payload-action'

type EmployeesByCountry = { [locale: string]: EmploymentContracts[] }

type UpdateCountryEmployeesAction = PayloadAction<
  'UPDATE_COUNTRY_EMPLOYEES',
  { employeesByCountry: EmployeesByCountry }
>

type UpdateUsersWithUnlockedHoursAction = PayloadAction<
  'UPDATE_USERS_WITH_UNLOCKED_HOURS',
  { usersWithUnlockedHours: UsersWithUnlockedHours }
>

type UpdateUnpaidLeaveCheckpointsAction = PayloadAction<
  'UPDATE_UNPAID_LEAVE_CHECKPOINTS',
  { username: string; checkpoints: UnpaidLeaveCheckpoints }
>

type UpdateWorkEntriesAction = PayloadAction<
  'UPDATE_REPORT_WORKENTRIES',
  { reportWorkEntriesResponse: ReportingResponse<UsernameReportWorkEntry> }
>

type AdminState = {
  employeesByCountry: EmployeesByCountry
  unpaidLeaveCheckpointsPerEmployee: { [username: string]: UnpaidLeaveCheckpoints }
  reportWorkEntriesResponse: ReportingResponse<UsernameReportWorkEntry>
  usersWithUnlockedHours: UsersWithUnlockedHours
}

type AdminStateAction =
  | UpdateCountryEmployeesAction
  | UpdateUnpaidLeaveCheckpointsAction
  | UpdateWorkEntriesAction
  | UpdateUsersWithUnlockedHoursAction

export const EMPTY_RESPONSE = { results: [], resultsLimit: -1 }

const DEFAULT_ADMIN_STATE: AdminState = {
  employeesByCountry: {},
  unpaidLeaveCheckpointsPerEmployee: {},
  reportWorkEntriesResponse: EMPTY_RESPONSE,
  usersWithUnlockedHours: [],
}

export const adminClient = (state: AdminState = DEFAULT_ADMIN_STATE, action: AdminStateAction): AdminState => {
  switch (action.type) {
    case 'UPDATE_COUNTRY_EMPLOYEES': {
      return {
        ...state,
        employeesByCountry: {
          ...state.employeesByCountry,
          ...action.employeesByCountry,
        },
      }
    }
    case 'UPDATE_UNPAID_LEAVE_CHECKPOINTS': {
      return {
        ...state,
        unpaidLeaveCheckpointsPerEmployee: {
          ...state.unpaidLeaveCheckpointsPerEmployee,
          [action.username]: action.checkpoints,
        },
      }
    }
    case 'UPDATE_USERS_WITH_UNLOCKED_HOURS': {
      return { ...state, usersWithUnlockedHours: action.usersWithUnlockedHours }
    }
    case 'UPDATE_REPORT_WORKENTRIES': {
      return {
        ...state,
        reportWorkEntriesResponse: action.reportWorkEntriesResponse,
      }
    }
    default:
      return state
  }
}
