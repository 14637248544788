import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { addMonths } from 'date-fns'
import { Link } from 'react-router-dom'

import { logout, updateSetting } from './lib/hoursApi'
import { formatDayMonthYear, today, formatISO, formatISODayOfWeek } from './lib/date'

import LanguageButton from './LanguageButton'
import ImpersonationInput from './ImpersonationInput'
import RoundedButton from './RoundedButton'

import styles from './css/Menu.module.css'
import { isPartialWeek } from './lib/util'
import { RootState } from './lib/store'
import { Locale, SettingsState } from './lib/reducers/settings'
import { UserState } from './lib/reducers/user'
import { PersonalHoursClientState } from './lib/reducers/personal-hours-client'
import { Dispatch } from 'redux'
import { HoursContractSeason } from './lib/domain'

const parsePartTimeText = (locale: Locale, contractType: string) => {
  const [percentage, ...days] = contractType.replace('partTime_', '').split('_')
  const absenceDays =
    days.length > 0
      ? locale.texts.partialWeek.replace('$', days.map((d) => formatISODayOfWeek(locale)(d)).join(', '))
      : ''
  return `${percentage}%${absenceDays}`
}

const mapContractTypeToWorkPercentage = (locale: Locale, contractType: string) => {
  if (contractType === 'normal') return '100%'
  else if (contractType.startsWith('partTime_')) return parsePartTimeText(locale, contractType)
  else if (contractType === 'hourly') return locale.texts.hourly
  else if (contractType === 'away') return locale.texts.away
  else if (contractType === 'not_required') return locale.texts.notReporting
  else if (contractType === 'subcontractor') return locale.texts.subcontractor

  return locale.texts.unknown
}

// show the previous contract if it ended in the last 3 months, the current contract, and the next future contract.
// If no contracts apply to any of those requirements, then display only last contract
const calculateDisplayedContracts = (contracts: HoursContractSeason[]): HoursContractSeason[] => {
  const lastOldContractWithinThreeMonths = contracts
    .reverse()
    .find(({ finish }) => finish && finish < today() && finish > formatISO(addMonths(today(), -3)))
  const currentContract = contracts.find(({ start, finish }) => start <= today() && (!finish || finish >= today()))
  const nextFutureContract = contracts.find(({ start }) => start > today())
  const displayedContracts = [lastOldContractWithinThreeMonths, currentContract, nextFutureContract].filter(
    (contract: HoursContractSeason | undefined): contract is HoursContractSeason => Boolean(contract)
  )

  return displayedContracts.length > 0 ? displayedContracts : contracts.slice(-1)
}

type ToggleProps = {
  className?: string
  toggled: boolean
  label: string
  onToggle: (on: boolean) => void
  'data-test': string
}

const Toggle: React.FC<ToggleProps> = ({ className, toggled, label, onToggle, 'data-test': dataTest }) => (
  <button data-test={dataTest} className={classnames(styles.toggle, className)} onClick={() => onToggle(!toggled)}>
    <img
      className={styles.toggleCheckbox}
      src={`/round-checkbox${toggled ? '-checked' : ''}.svg`}
      width='24'
      height='24'
      alt={toggled ? 'Checked checkbox' : 'Unchecked checkbox'}
    />
    {label}
  </button>
)

type OwnProps = {
  absenceRenderConditionsMet: boolean
  toggleMenu: () => void
  dispatch: Dispatch
}

type MenuProps = OwnProps & UserState & SettingsState & Pick<PersonalHoursClientState, 'contracts'>

const Menu: React.FC<MenuProps> = ({
  loggedInUsername,
  dataOwnerUsername,
  dataOwnerUser: { fullName: userFullName },
  loggedInUser: { isAdmin, isCountryManager, isExternal, managedCountries, isCountryStatsUser },
  locale,
  billingRatioShown,
  summaryShown,
  startEndShown,
  vacationsShown,
  absencesShown,
  isMenuVisible,
  toggleMenu,
  contracts,
  absenceRenderConditionsMet,
  dispatch,
}) => {
  const username = dataOwnerUsername

  const showVacationsPopup = () => {
    dispatch({ type: 'CHANGE_VACATIONS_POPUP_SHOWN', vacationsPopupShown: true })
  }

  const showBillingRatioPopup = () => {
    dispatch({ type: 'CHANGE_BILLING_RATIO_POPUP_SHOWN', billingRatioPopupShown: true })
  }

  const showSummaryPopup = () => {
    dispatch({ type: 'CHANGE_SUMMARY_POPUP_SHOWN', summaryPopupShown: true })
  }

  const showAbsencesPopup = () => {
    dispatch({ type: 'CHANGE_ABSENCES_POPUP_SHOWN', absencesPopupShown: true })
  }

  const updateStartEndShown = (toggled: boolean) => {
    if (!username) return
    dispatch({ type: 'CHANGE_START_END_SHOWN', startEndShown: toggled })
    updateSetting({ username, field: 'startEndShown', value: toggled }).catch((e) =>
      console.error(`Error updating start/end shown ${e}`)
    )
  }

  const updateVacationsShown = (toggled: boolean) => {
    if (!username) return
    localStorage.setItem('settings.showVacations', toggled ? 'true' : 'false')
    dispatch({ type: 'CHANGE_VACATIONS_SHOWN', vacationsShown: toggled })
  }

  const updateBillingRatioShown = (toggled: boolean) => {
    dispatch({ type: 'CHANGE_BILLABLE_RATIO_SHOWN', billingRatioShown: toggled })
    localStorage.setItem('settings.showBillingRatio', toggled ? 'true' : 'false')
  }

  const updateSummaryRatioShown = (toggled: boolean) => {
    dispatch({ type: 'CHANGE_SUMMARY_SHOWN', summaryShown: toggled })
    localStorage.setItem('settings.showSummary', toggled ? 'true' : 'false')
  }

  const updateAbsencesShown = (toggled: boolean) => {
    dispatch({ type: 'CHANGE_ABSENCES_SHOWN', absencesShown: toggled })
    localStorage.setItem('settings.showAbsences', toggled ? 'true' : 'false')
  }

  const updateLanguage = (language: string) => {
    if (!username) return
    dispatch({ type: 'UPDATE_LOCALE', language })
    updateSetting({ username, field: 'language', value: language }).catch((e) =>
      console.error(`Error updating language shown ${e}`)
    )
  }

  if (!isMenuVisible || !username) {
    return null
  }

  const displayedContracts = calculateDisplayedContracts(contracts)
  const showContractCountry = _.uniqBy(displayedContracts, 'company.country').length > 1
  const impersonationAllowed = isAdmin || (isCountryManager && managedCountries.length > 0)
  const canAccessBackOfficeUI = impersonationAllowed || isCountryStatsUser

  return (
    <div key='overlay' className={styles.overlay} onClick={toggleMenu}>
      <nav data-test='navigationMenu' className={styles.menu} key='menu'>
        <div className={styles.user}>
          <div
            className={styles.portrait}
            style={{
              backgroundImage: `url(https://cdn.rex.reaktor.cloud/people/400x500/${encodeURIComponent(username)}.jpg)`,
            }}
          />
          <h3 className={styles.username}>{username}</h3>
          <h2 className={styles.fullName}>{userFullName}</h2>
          {impersonationAllowed ? (
            <ImpersonationInput {...{ locale, loggedInUsername, dataOwnerUsername, isAdmin, managedCountries }} />
          ) : null}
          <div className={styles.contracts}>
            {displayedContracts.map(({ type, start, finish, company }) => (
              <div
                key={`${start}${finish}`}
                className={classnames(styles.contractItem, {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  [styles.twoRows!]: isPartialWeek(type) || type === 'not_required' || type === 'subcontractor',
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  [styles.current!]: start <= today() && (!finish || finish >= today()),
                })}
              >
                <span className={styles.contractType}>{mapContractTypeToWorkPercentage(locale, type)} </span>
                {showContractCountry && <span className={styles.contractCountry}>{company.country}</span>}
                <span className={styles.contractTime}>
                  {formatDayMonthYear(locale)(start)} - {finish ? formatDayMonthYear(locale)(finish) : ''}
                </span>
              </div>
            ))}
          </div>
          <RoundedButton
            data-test='menu-button-showVacations'
            className={styles.showVacationsButton}
            onClick={showVacationsPopup}
          >
            {locale.texts.showVacations}
          </RoundedButton>
          <RoundedButton
            data-test='menu-button-showBillingRatio'
            className={styles.showBillingRatioButton}
            onClick={showBillingRatioPopup}
          >
            {locale.texts.showBillingRatio}
          </RoundedButton>
          <RoundedButton
            data-test='menu-button-showSummary'
            className={styles.showSummaryButton}
            onClick={showSummaryPopup}
          >
            {locale.texts.showMonthlySummary}
          </RoundedButton>
          {absenceRenderConditionsMet && (
            <RoundedButton
              data-test='menu-button-showAbsences'
              className={styles.showAbsencesButton}
              onClick={showAbsencesPopup}
            >
              {locale.texts.showAbsences}
            </RoundedButton>
          )}
        </div>
        <section className={styles.mainActions}>
          <Toggle
            data-test='menu-toggle-startEnd'
            className={styles.startEndToggle}
            toggled={startEndShown}
            onToggle={updateStartEndShown}
            label={locale.texts.showStartEndTimes}
          />
          <Toggle
            data-test='menu-toggle-vacations'
            className={styles.vacationsToggle}
            toggled={vacationsShown}
            onToggle={updateVacationsShown}
            label={locale.texts.showVacations}
          />
          <Toggle
            data-test='menu-toggle-billingRatio'
            className={styles.billingRatioToggle}
            toggled={billingRatioShown}
            onToggle={updateBillingRatioShown}
            label={locale.texts.showBillingRatio}
          />
          <Toggle
            data-test='menu-toggle-summary'
            className={styles.summaryToggle}
            toggled={summaryShown}
            onToggle={updateSummaryRatioShown}
            label={locale.texts.showMonthlySummary}
          />
          {absenceRenderConditionsMet && (
            <Toggle
              data-test='menu-toggle-absences'
              className={styles.absencesToggle}
              toggled={absencesShown}
              onToggle={updateAbsencesShown}
              label={locale.texts.showAbsences}
            />
          )}
          <div className={styles.languages}>
            <LanguageButton language='FI' currentLanguage={locale.language} updateLanguage={updateLanguage} />
            <LanguageButton language='EN' currentLanguage={locale.language} updateLanguage={updateLanguage} />
            <LanguageButton language='US' currentLanguage={locale.language} updateLanguage={updateLanguage} />
          </div>
        </section>
        <div className={styles.spacer} />
        {!isExternal && (
          <a className={styles.link} target='_blank' rel='noopener noreferrer' href='/admin'>
            {locale.texts.hoursAdmin}
          </a>
        )}
        {canAccessBackOfficeUI && (
          <Link className={styles.link} to='/backoffice'>
            {locale.texts.backOffice}
          </Link>
        )}
        <button data-test='menu-logout-button' className={classnames(styles.link, styles.logout)} onClick={logout}>
          {locale.texts.logOut}
        </button>
      </nav>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  contracts: state.data.personalHoursClient.contracts,
  ...state.user,
  ...state.settings,
})
export default connect(mapStateToProps)(Menu)
