import React, { ReactNode, useState } from 'react'
import styles from '../css/SortableTable.module.css'
import _ from 'lodash'
import classNames from 'classnames'

export type SortingState = {
  path: string | null
  direction: 'asc' | 'desc'
}

export type TableHeading = {
  title: string
  sortPath: string
}

export function useSortingByPath(): [SortingState, (path: string) => void] {
  const [sorting, setSorting] = useState<SortingState>({ path: null, direction: 'asc' })

  const toggleSortByPath = (path: string) => {
    if (sorting.path === path) {
      setSorting({ path, direction: sorting.direction === 'asc' ? 'desc' : 'asc' })
    } else {
      setSorting({ path, direction: 'desc' })
    }
  }

  return [sorting, toggleSortByPath]
}

export function sortItemsByPath<Item extends Object>(sorting: SortingState, items: Item[]) {
  return sorting.path !== null ? _.orderBy(items, pathSelector(sorting.path), sorting.direction) : items
}

function pathSelector<Item extends Object>(path: string) {
  return (employeeData: Item) => _.get(employeeData, path)
}

export const TH: React.FC<{
  text: string
  sortPath: string
  toggleSortByPath: (path: string) => void
  sortState: SortingState
}> = ({ text, sortPath, sortState, toggleSortByPath }) => {
  const isSortable = !!sortPath
  return (
    <th className={isSortable ? styles.sortable : ''} onClick={() => toggleSortByPath(sortPath)}>
      <span>{text}</span>
      {isSortable && sortState.path === sortPath ? (
        <span className={styles.sortIndicator}>{sortState.direction === 'asc' ? '▲' : '▼'}</span>
      ) : null}
    </th>
  )
}

export type SortableTableHeaderProps = {
  headings: TableHeading[]
  sortingState: SortingState
  toggleSortByPath: (path: string) => void
}

export const SortableTableHeader: React.FC<SortableTableHeaderProps> = ({
  headings,
  sortingState,
  toggleSortByPath,
}) => {
  return (
    <thead>
      <tr>
        {headings.map(({ title: heading, sortPath }) => (
          <TH
            key={heading}
            text={heading}
            sortPath={sortPath}
            sortState={sortingState}
            toggleSortByPath={toggleSortByPath}
          />
        ))}
      </tr>
    </thead>
  )
}

const SortableTable: React.FC<{ children: ReactNode; className?: string; dataTest?: string }> = ({
  children,
  className,
  dataTest,
}) => {
  return (
    <table className={classNames(styles.table, className)} data-test={dataTest}>
      {children}
    </table>
  )
}

export default SortableTable
